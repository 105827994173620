import React, { useState } from 'react';
import { Font, Box, Align, Gap, Fit, Animate } from 'themeor';
import { ActionButton } from '../action-button';
import { Portal } from '../portal';
import { Link } from '../link';
export var Notice = function (_a) {
    var title = _a.title, text = _a.text, _b = _a.type, type = _b === void 0 ? 'base' : _b, onClose = _a.onClose, _c = _a.moreLabel, moreLabel = _c === void 0 ? 'more' : _c, children = _a.children;
    var _d = useState(true), show = _d[0], setShow = _d[1];
    var _e = useState(false), opened = _e[0], setOpened = _e[1];
    function handleClose() {
        setShow(false);
        setTimeout(function () { return onClose === null || onClose === void 0 ? void 0 : onClose(); }, 3000);
    }
    return (React.createElement(Portal, null,
        React.createElement(Animate.TryTagless, { mounted: show, onMount: "slideInUp", onUnmount: "slideOutDown" },
            React.createElement(Fit.TryTagless, { fixed: true, bottom: "0", right: "0", maxWidth: "100%", zIndex: 1000 },
                React.createElement(Align.TryTagless, { hor: "right" },
                    React.createElement(Gap, null,
                        React.createElement(Fit.TryTagless, { scroll: true },
                            React.createElement(Box, { inverse: true, fill: type, radius: "md", width: "400px", maxWidth: "100%" },
                                React.createElement(Fit.TryTagless, { scroll: true, maxHeight: "400px" },
                                    React.createElement(Gap, { size: "20px" },
                                        title && React.createElement(Font, { fill: "base", weight: "700" }, title),
                                        title && text && React.createElement(Gap, { size: "8px" }),
                                        text && React.createElement(Font, { fill: "faint", size: "xs" }, text),
                                        children && (React.createElement(React.Fragment, null,
                                            React.createElement(Gap, { size: "8px" }),
                                            React.createElement(Font, { fill: "base" }, opened ? children : (React.createElement(Link, { label: moreLabel, onClick: function () { return setOpened(true); } }))))))),
                                React.createElement(Fit, { stick: "top-right" },
                                    React.createElement(Gap, null,
                                        React.createElement(ActionButton, { onClick: handleClose, icon: "cross_circled" })))))))))));
};
