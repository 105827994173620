// Get CSS values from strings and makes some changes with it
export var double = function (value) {
    if (!value) {
        return;
    }
    var splitted = splitValue(value);
    return [splitted[0] * 2, splitted[1]].join('');
};
export var half = function (value) {
    if (!value) {
        return;
    }
    var splitted = splitValue(value) || [0];
    return [splitted[0] / 2, splitted[1]].join('');
};
export var minus = function (value) {
    if (!value) {
        return;
    }
    var splitted = splitValue(value) || [0];
    return [-splitted[0], splitted[1]].join('');
};
export var splitValue = function (value) {
    var number = typeof value === 'number' ? value : parseInt(value);
    var indexOfMeasurement = typeof value === 'number' ? 0 : number.toString().length;
    var measurement = typeof value === 'number' ? '' : value.toString().slice(indexOfMeasurement, value.length);
    return [number, measurement];
};
