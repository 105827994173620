var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect, useRef } from 'react';
import { Box, Fit, Gap } from 'themeor';
import { Portal } from '../portal';
import { useAppLayout } from '../app-layout';
import { placeNode } from '../../utils';
import { TextInput } from "../text-input";
import filter from 'opium-filter';
import { useDropdown } from './context';
import { Hotkey } from '../hotkey';
import { LazyScroller } from '../lazy-scroller';
export var Wrapper = function (_a) {
    var children = _a.children, _b = _a.place, place = _b === void 0 ? 'bottom-stretch' : _b, _c = _a.placeOrder, placeOrder = _c === void 0 ? ['bottom', 'top'] : _c, forwardRef = _a.forwardRef, parentNode = _a.parentNode, rest = __rest(_a, ["children", "place", "placeOrder", "forwardRef", "parentNode"]);
    var _d = useState(), targetNode = _d[0], setTargetNode = _d[1];
    var _e = useState(), isReady = _e[0], setIsReady = _e[1];
    var scrollNode = useRef();
    var contentNode = useAppLayout().contentNode;
    var _f = useDropdown(), opened = _f.opened, search = _f.search, setSearch = _f.setSearch, setOpened = _f.setOpened, withSearch = _f.withSearch;
    var searchNode;
    function alignNodes() {
        if (!parentNode || !targetNode) {
            return;
        }
        placeNode(targetNode, parentNode, placeOrder, place);
    }
    useEffect(function () {
        alignNodes();
    });
    useEffect(function () {
        if (isReady) {
            contentNode === null || contentNode === void 0 ? void 0 : contentNode.addEventListener('scroll', alignNodes);
            window.addEventListener('resize', alignNodes);
            return function () {
                contentNode === null || contentNode === void 0 ? void 0 : contentNode.removeEventListener('scroll', alignNodes);
                window.removeEventListener('resize', alignNodes);
            };
        }
    }, [isReady]);
    function handleRef(node) {
        setTargetNode(node);
        if (typeof forwardRef === 'function') {
            forwardRef(node);
        }
    }
    useEffect(function () {
        alignNodes();
        if (contentNode && targetNode && parentNode) {
            setIsReady(true);
        }
    });
    function handleSourceRef(node) {
        if (!node) {
            return;
        }
        parentNode = parentNode || node.previousElementSibling || node.parentNode;
    }
    useEffect(function () {
        if (opened && searchNode) {
            searchNode.focus();
        }
    }, [opened]);
    var newChildren = children;
    var isMapped = children instanceof Map;
    if (isMapped) {
        newChildren = [];
        for (var _i = 0, _g = Array.from(children); _i < _g.length; _i++) {
            var _h = _g[_i], child = _h[0], tags = _h[1];
            newChildren.push({
                component: child,
                tags: tags,
            });
        }
        if (search) {
            newChildren = filter(newChildren, { tags: search }).map(function (i) { return i.component; });
        }
        else {
            newChildren = newChildren.map(function (i) { return i.component; });
        }
    }
    var showSearch = withSearch && (isMapped && children.size > 10
        || React.Children.count(children) > 10
        || children.length > 10);
    return (React.createElement(Fit, { forwardRef: handleSourceRef, hidden: true },
        React.createElement(Portal, null,
            React.createElement(Hotkey, { scope: "dropdown", trigger: "esc", action: function () { return setOpened(false); } },
                React.createElement(Fit.TryTagless, { absolute: true },
                    React.createElement(Gap, __assign({ vert: "10px", forwardRef: handleRef }, rest),
                        React.createElement(Fit.TryTagless, { forwardRef: function (n) { return scrollNode.current = n; }, scroll: true, maxHeight: "500px", maxWidth: "600px", minWidth: "100px" },
                            React.createElement(Box, { radius: "sm", shadow: "lg", fill: "base" },
                                showSearch && (React.createElement(Fit, { sticky: true, top: "0", zIndex: 1 },
                                    React.createElement(Gap, { hor: "md", vert: "sm" },
                                        React.createElement(TextInput, { forwardRef: function (n) { return searchNode = n; }, type: "search", radius: "max", placeholder: "Search", value: search, onChange: setSearch })))),
                                React.createElement(LazyScroller, { scrollNode: scrollNode.current }, isMapped ? newChildren : children)))))))));
};
