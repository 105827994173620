var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Font, Align, Icon } from 'themeor';
import { MakeButton } from '../make-button';
export var Link = function (_a) {
    var label = _a.label, _b = _a.fill, fill = _b === void 0 ? 'base' : _b, _c = _a.fillHover, fillHover = _c === void 0 ? 'accent' : _c, icon = _a.icon, children = _a.children, rest = __rest(_a, ["label", "fill", "fillHover", "icon", "children"]);
    return (React.createElement(MakeButton, __assign({ inline: true, fillHover: "none", fillActive: "none" }, rest), function (r) { return (React.createElement(Font.TryTagless, __assign({ fill: r.hoverOrFocus ? fillHover : fill }, r.restProps),
        React.createElement(Align, { row: true, gapHor: "4px", vert: "center" },
            label,
            icon && React.createElement(Icon, { size: "sm", fill: r.hoverOrFocus ? fillHover : fill, name: icon }),
            children))); }));
};
Link.displayName = 'Link';
Link.demoProps = {};
