import { Align, Font, Gap, Box, Icon, Fit } from 'themeor'
import { LimitWidth, Page, MakeButton, Button, Stats, useScreenFit, ActionButton, Toggle } from 'opium-ui'
import { usePath } from 'opium-nav'
import { networks } from '../config/networks'


export default function () {
    const { params: { connect } } = usePath()
    const social = networks[connect]
    const { isSmall } = useScreenFit()

    return (
        <Page title="Settings">
            <LimitWidth>
                <Gap size="32px" />
                <Align row={!isSmall} vert="center" hor="left">
                    <Font size="48px" weight="800">Settings</Font>
                </Align>
                <Gap size="40px" />
            </LimitWidth>
        </Page>
    )
}