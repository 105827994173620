var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { setState, getState } from './context';
import { getPathfromHistory } from './utils';
import { config } from './config';
export { getState };
export function isStack(stackName, path) {
    if (path === void 0) { path = getPathfromHistory(getState().history); }
    return path.indexOf(stackName) === 0;
}
export function getStack(path, history) {
    var stackhistory = history === null || history === void 0 ? void 0 : history.filter(function (_a) {
        var item = _a[0];
        return isStack(path.split(config.stackSeparator)[0], item);
    });
    var prevItem = [];
    var result = stackhistory.filter(function (item) {
        if ((item[0] === prevItem[0]) && (JSON.stringify(item[1]) === JSON.stringify(prevItem[1]))) {
            return false;
        }
        prevItem = item;
        return true;
    });
    return result;
}
export function toStack(name, reset) {
    if (reset === void 0) { reset = false; }
    setState(function (state) {
        var match = getStack(name, state.history);
        var first = match.length ? match[0] : [name || config.defaultPath, {}];
        var last = match.length ? match.slice(-1)[0] : [name || config.defaultPath, {}];
        var alreadyInStack = isStack(name, getPathfromHistory(state.history));
        if (alreadyInStack && reset) {
            // Очищаем историю от всех элементов из этого стека
            var newHistory = state.history.filter(function (item) { return !isStack(name, item[0]); });
            newHistory.push(first);
            return __assign(__assign({}, state), { history: newHistory });
        }
        else if (alreadyInStack) {
            go.apply(void 0, first);
        }
        else {
            go.apply(void 0, last);
        }
        return state;
    });
}
export function go(newPath, params) {
    setState(function (state) {
        var path = getPathfromHistory(state.history) || state.defaultPath;
        var newState = __assign({}, state);
        if (newPath !== path) {
            newState.history = __spreadArray(__spreadArray([], state.history, true), [[newPath, params]], false);
            newState.backHistory = [];
        }
        return newState;
    });
}
export function replace(newPath, params) {
    setState(function (state) {
        var _a;
        return newPath !== state.path ? (__assign(__assign({}, state), { history: __spreadArray(__spreadArray([], (_a = state.history) === null || _a === void 0 ? void 0 : _a.slice(0, -1), true), [[newPath, params]], false) })) : state;
    });
}
export function backInStack(stack) {
    setState(function (state) {
        var history = state.history;
        var path = getPathfromHistory(history);
        if (typeof stack !== 'string') {
            var splitPath = path.split(config.stackSeparator);
            stack = splitPath[0] || "/" + splitPath[1];
        }
        var stackHistory = getStack(stack, history);
        var last = stackHistory.slice(-1)[0];
        function findPrev(index) {
            if (index === void 0) { index = stackHistory.length - 1; }
            if (index < 0) {
                return [stack, {}];
            }
            if (stackHistory[index][0] !== last[0]) {
                return stackHistory[index];
            }
            return findPrev(index - 1);
        }
        var prev = findPrev();
        var newHistory = history.length ? __spreadArray([], history.slice(0, -1), true) : [[stack, {}]];
        if (prev[0] !== newHistory.slice(-1)[0][0]) {
            newHistory.push(prev);
        }
        return __assign(__assign({}, state), { history: newHistory, backHistory: __spreadArray(__spreadArray([], state.backHistory, true), state.history.slice(-1), true) });
    });
}
export function back(pop, write) {
    if (pop === void 0) { pop = 1; }
    if (write === void 0) { write = true; }
    if (typeof pop !== 'number') {
        pop = 1;
    }
    setState(function (state) {
        var _a, _b;
        var newState = __assign({}, state);
        if (state.history.length > pop) {
            newState.history = state.history.slice(0, -pop);
            var popped = state.history.slice(-pop);
            if (write) {
                newState.backHistory = __spreadArray(__spreadArray([], state.backHistory, true), popped.reverse(), true);
            }
        }
        else if (config.isBrowser) {
            (_b = (_a = window.history) === null || _a === void 0 ? void 0 : _a.back) === null || _b === void 0 ? void 0 : _b.call(_a);
        }
        return newState;
    });
}
export function forward() {
    setState(function (state) {
        var history = state.history, backHistory = state.backHistory;
        if (state.backHistory.length) {
            return __assign(__assign({}, state), { history: __spreadArray(__spreadArray([], history, true), backHistory.slice(-1), true), backHistory: __spreadArray([], backHistory.slice(0, -1), true) });
        }
        return state;
    });
}
export function reset(initialPath) {
    setState(function (state) { return (__assign(__assign({}, state), { history: [[initialPath || config.defaultPath, {}]] })); });
}
export function reload() {
    var _a, _b;
    setState(function (state) { return state; });
    if (config.isBrowser) {
        (_b = (_a = window.history).go) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
}
