var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTheme } from '../context';
import cn from '../utils/class-names';
import { LINE_NAME } from './types';
import { Common } from '../Common';
import { getConfig } from '../utils/get-config';
import { withTagless } from '../with-tagless';
import { useCss } from './styles';
import { useBox } from '../Box';
var Line = function (_a, ref) {
    var className = _a.className, inverse = _a.inverse, weight = _a.weight, _b = _a.fill, fill = _b === void 0 ? 'default' : _b, top = _a.top, right = _a.right, bottom = _a.bottom, left = _a.left, children = _a.children, fancy = _a.fancy, vert = _a.vert, _c = _a.style, style = _c === void 0 ? {} : _c, restProps = __rest(_a, ["className", "inverse", "weight", "fill", "top", "right", "bottom", "left", "children", "fancy", "vert", "style"]);
    var TRY_TO_INVERSE = useBox().TRY_TO_INVERSE;
    var _d = getConfig(useTheme().normalizedConfig), lineConfig = _d.lineConfig, customLineValue = _d.customLineValue;
    var css = useCss();
    var newStyle = __assign({}, style);
    if (fill && customLineValue({ fill: fill })) {
        if (fancy) {
            newStyle.borderImage = lineConfig({ fillFancy: fill });
        }
        else {
            newStyle.borderColor = fill;
        }
    }
    if (weight && customLineValue({ weight: weight })) {
        newStyle.borderWidth = weight;
    }
    if (top && customLineValue({ weight: top })) {
        newStyle.borderTopWidth = top;
    }
    if (right && customLineValue({ weight: right })) {
        newStyle.borderRightWidth = right;
    }
    if (bottom && customLineValue({ weight: bottom })) {
        newStyle.borderBottomWidth = bottom;
    }
    if (left && customLineValue({ weight: left })) {
        newStyle.borderLeftWidth = left;
    }
    var separator = !children;
    var noSpecificWeight = !right && !left && !top && !bottom;
    if (noSpecificWeight && !weight) {
        weight = 'default';
    }
    var forseInverse = (inverse !== false) && (inverse || TRY_TO_INVERSE);
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { className: cn(css["line"], (separator && !vert) && lineConfig({ weight: weight }) && css["separator-hor-" + weight], (separator && vert) && lineConfig({ weight: weight }) && css["separator-vert-" + weight], !separator && lineConfig({ weight: weight }) && css["weight-" + weight], lineConfig({ weight: top }) && css["weight-top-" + top], lineConfig({ weight: right }) && css["weight-right-" + right], lineConfig({ weight: bottom }) && css["weight-bottom-" + bottom], lineConfig({ weight: left }) && css["weight-left-" + left], lineConfig({ fill: fill }) && css["fill-" + fill], forseInverse && lineConfig({ fillInverse: fill }) && css["fill-inverse-" + fill], fancy && lineConfig({ fillFancy: fill }) && css["fill-fancy-" + fill], className), style: newStyle, children: children });
    return Common(componentProps, LINE_NAME);
};
Line.displayName = LINE_NAME;
export default withTagless(React.forwardRef(Line));
