var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import jss from 'jss';
import preset from 'jss-preset-default';
import { config } from '../config';
var createGenerateId = function () {
    return function (rule, _a) {
        var options = _a.options;
        var unicName = (options.classNamePrefix || '') + "__" + rule.key;
        var hashed = config.hash(unicName);
        if (config.isNative) {
            return "" + rule.key;
        }
        if (config.obfuscate) {
            return "" + hashed;
        }
        return unicName + "__" + hashed;
    };
};
jss.setup(__assign(__assign({}, preset()), { createGenerateId: createGenerateId }));
export var styleSheets = {};
export var initialStyles = {};
export function getClasses(name) {
    var _a;
    return ((_a = styleSheets[name]) === null || _a === void 0 ? void 0 : _a.classes) || {};
}
export function getInitialStyles(name) {
    return initialStyles[name];
}
export function createStyleSheet(name, styles) {
    var styleSheet = jss.createStyleSheet(styles, { classNamePrefix: name, });
    if (styleSheets[name]) {
        styleSheets[name].detach();
    }
    styleSheet.attach();
    styleSheets[name] = styleSheet;
    initialStyles[name] = styles;
    return styleSheets[name];
}
export function createStyleNode(id) {
    var styleNode = document.createElement('style');
    styleNode.id = id;
    document.head.appendChild(styleNode);
    return styleNode;
}
export function unsetStyles(id) {
    var styleNode = document.getElementById(id);
    if (styleNode) {
        styleNode.innerHTML = '';
    }
}
export function setStyles(id, textNode) {
    var styleNode = document.getElementById(id) || createStyleNode(id);
    styleNode.innerHTML = textNode;
}
export function addStyles(id, textNode) {
    var styleNode = document.getElementById(id) || createStyleNode(id);
    styleNode.appendChild(document.createTextNode(textNode));
}
