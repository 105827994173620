var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import cn from '../utils/class-names';
import { REACTION_NAME } from './types';
import { ReactionContext } from './context';
import { useCss } from './styles';
import { getConfig } from '../utils/get-config';
import { useTheme } from '../context';
Reaction.displayName = REACTION_NAME;
export function Reaction(_a) {
    var children = _a.children, _b = _a.track, track = _b === void 0 ? ['hover', 'focus'] : _b, _c = _a.duration, duration = _c === void 0 ? 'default' : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.cursor, cursor = _e === void 0 ? disabled ? 'default' : 'pointer' : _e, smooth = _a.smooth, _f = _a.property, property = _f === void 0 ? smooth ? 'all' : undefined : _f, _g = _a.timingFunction, timingFunction = _g === void 0 ? 'ease' : _g, className = _a.className, onFocus = _a.onFocus, onBlur = _a.onBlur, onMouseMove = _a.onMouseMove, onMouseLeave = _a.onMouseLeave, onMouseDown = _a.onMouseDown, onMouseUp = _a.onMouseUp, button = _a.button, _h = _a.style, style = _h === void 0 ? {} : _h, restProps = __rest(_a, ["children", "track", "duration", "disabled", "cursor", "smooth", "property", "timingFunction", "className", "onFocus", "onBlur", "onMouseMove", "onMouseLeave", "onMouseDown", "onMouseUp", "button", "style"]);
    var _j = React.useState({
        hover: false,
        active: false,
        focus: false,
        hoverOrFocus: false,
        restProps: restProps,
    }), state = _j[0], setState = _j[1];
    var _k = getConfig(useTheme().normalizedConfig), reactionConfig = _k.reactionConfig, customReactionValue = _k.customReactionValue;
    var css = useCss();
    var newStyle = style;
    if (property) {
        newStyle.transitionProperty = property;
    }
    if (timingFunction) {
        newStyle.transitionTimingFunction = timingFunction;
    }
    if (customReactionValue({ duration: duration })) {
        newStyle.transitionDuration = duration;
    }
    useEffect(function () {
        if (disabled) {
            setState(function (state) { return (__assign(__assign({}, state), { hover: false, hoverOrFocus: false, focus: false, active: false })); });
        }
    }, [disabled]);
    function handleMouseMove(event) {
        onMouseMove && onMouseMove(event);
        if (!state.hover || !state.hoverOrFocus) {
            setState(__assign(__assign({}, state), { hover: true, hoverOrFocus: true }));
        }
    }
    function handleMouseLeave(event) {
        onMouseLeave && onMouseLeave(event);
        if (state.hover || state.hoverOrFocus) {
            setState(__assign(__assign({}, state), { hover: false, active: false, hoverOrFocus: false }));
        }
    }
    function handleMouseDown(event) {
        onMouseDown && onMouseDown(event);
        if (!state.active) {
            setState(__assign(__assign({}, state), { active: true }));
        }
    }
    function handleMouseUp(event) {
        onMouseUp && onMouseUp(event);
        if (state.active) {
            setState(__assign(__assign({}, state), { active: false }));
        }
    }
    function handleFocus(event) {
        onFocus && onFocus(event);
        if (!state.focus || !state.hoverOrFocus) {
            setState(__assign(__assign({}, state), { focus: true, hoverOrFocus: true }));
        }
    }
    function handleBlur(event) {
        onBlur && onBlur(event);
        if (state.focus || state.hoverOrFocus) {
            setState(__assign(__assign({}, state), { focus: false, hoverOrFocus: state.hover }));
        }
    }
    var passState = __assign({ cursor: cursor, classNames: {
            ignoreEvents: css["ignore"],
            cursor: reactionConfig({ cursor: cursor }) && css["cursor-" + cursor],
        } }, state);
    var passProps = __assign({ className: cn(css["reaction"], reactionConfig({ cursor: cursor }) && css["cursor-" + cursor], reactionConfig({ duration: duration }) && css["duration-" + duration], className), style: newStyle }, restProps);
    if (track && track.includes('hover')) {
        passProps.onMouseMove = handleMouseMove;
        passProps.onMouseLeave = handleMouseLeave;
    }
    if (track && track.includes('active')) {
        passProps.onMouseDown = handleMouseDown;
        passProps.onMouseUp = handleMouseUp;
    }
    if (track && track.includes('focus')) {
        passProps.onFocus = handleFocus;
        passProps.onBlur = handleBlur;
    }
    return (React.createElement(ReactionContext.Provider, { value: __assign(__assign({}, passState), { passProps: disabled ? {} : passProps, disabled: disabled }) }, (typeof children === 'function') ? (children(disabled ? {} : passProps, passState)) : (children)));
}
