import { createStyleSheet, getClasses } from '../utils/styles';
import { ICON_NAME } from './types';
export var useCss = function () { return getClasses(ICON_NAME); };
export default function (normalizedConfig) {
    var _a, _b, _c;
    var _d = normalizedConfig.icon, fill = _d.fill, fillFancy = _d.fillFancy, fillInverse = _d.fillInverse, size = _d.size;
    var styles = {};
    styles["icon"] = {
        boxSizing: 'content-box',
        '& svg': { width: '100%', height: '100%' },
    };
    for (var key in size) {
        styles["size-" + key] = {
            width: size[key],
            height: size[key],
            minWidth: size[key],
            minHeight: size[key],
        };
    }
    styles["fill-inverse-" + fill] = {};
    // Fills
    for (var key in fill) {
        styles["fill-" + key] = {
            '& *[fill]': { fill: fill[key] },
            '& *[stroke]': { stroke: fill[key] },
            '& *[fill="none"]': { fill: 'none' },
            '& *[stroke="none"]': { stroke: 'none' },
        };
    }
    for (var key in fillFancy) {
        styles["fill-fancy-" + key] = {
            '& *[fill]': { fill: fillFancy[key] },
            '& *[stroke]': { stroke: fillFancy[key] },
            '& *[fill="none"]': { fill: 'none' },
            '& *[stroke="none"]': { stroke: 'none' },
        };
    }
    for (var key in fillInverse) {
        styles["fill-inverse-" + key] = {
            '& *[fill]': { fill: fillInverse[key] },
            '& *[stroke]': { stroke: fillInverse[key] },
            '& *[fill="none"]': { fill: 'none' },
            '& *[stroke="none"]': { stroke: 'none' },
        };
    }
    // Forced fills
    for (var _i = 0, _e = ['stroke', 'fill']; _i < _e.length; _i++) {
        var force = _e[_i];
        for (var key in fill) {
            styles["force-" + force + "-" + key] = {
                '& *': (_a = {}, _a[force] = fill[key], _a),
                // '& *[class]': { [key]: fill[key] },
                // '& *[stroke]': { [key]: fill[key] },
                // '& *[fill]': { [key]: fill[key] },
            };
        }
        for (var key in fillFancy) {
            styles["force-" + force + "-" + key + "-fancy"] = {
                '& *': (_b = {}, _b[force] = fillFancy[key], _b),
                // '& *[class]': { [key]: fillFancy[key] },
                // '& *[stroke]': { [key]: fillFancy[key] },
                // '& *[fill]': { [key]: fillFancy[key] },
            };
        }
        for (var key in fillInverse) {
            styles["force-" + force + "-" + key + "-inverse"] = {
                '& *': (_c = {}, _c[force] = fillInverse[key], _c),
                // '& *[class]': { [key]: fillInverse[key] },
                // '& *[stroke]': { [key]: fillInverse[key] },
                // '& *[fill]': { [key]: fillInverse[key] },
            };
        }
    }
    return createStyleSheet(ICON_NAME, styles);
}
