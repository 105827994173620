import { Align, Font, Gap, Box, Animate, Line } from 'themeor'
import { Checkbox, ActionButton, useScreenFit, MarkMatch } from 'opium-ui'
import { nav } from 'opium-nav'


export default function ({ text, note, match, isLast = false, link = '', onDelete = () => { } }) {
  const { isSmall } = useScreenFit()

  return (
    <Animate onMount="fadeIn">
      <Gap top="lg">
        <Align vert="center" row={!isSmall} width="100%">
          <Checkbox />
          <Gap size="lg" />
          <Align stretch>
            <Font fill="baseDown">
              <pre>
                <MarkMatch target={match}>
                  {text}
                </MarkMatch>
              </pre>
            </Font>
            <Gap size="8px" />
            <Font fill="faintDown" size="xs">
              {note}
            </Font>
          </Align>
          <Gap size="lg" />
          <Align gapVert="md" stack={isSmall} gapHor="md">
            <ActionButton icon="arrow_right_circle" label='Go to the post' href={link} blank />
            <ActionButton icon="basket" label='Delete' onClick={onDelete} />
          </Align>
        </Align>
        <Gap size="lg" />
        {!isLast && <Line fill="faint" />}
      </Gap>
    </Animate>
  )
}