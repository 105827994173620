import { facebook } from '../../api'

export const storage = 'facebook'

export const initialState = {
  loggedIn: false,
  posts: undefined as Awaited<ReturnType<typeof facebook.posts>>,
  accessToken: undefined as string,
  userID: undefined as string,
  lastScan: undefined as Date,
  avatar: undefined as string,
  name: undefined as string,
}

export const cache: {[key in keyof typeof initialState]?: number} = {
  accessToken: 0,
  userID: 0,
  loggedIn: 0,
  posts: 0,
  lastScan: 0,
  avatar: 0,
  name: 0,
}