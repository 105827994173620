var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeDefaultActions } from './default-actions';
import { actionCreator } from './action-creator';
import { createReducer } from './create-reducer';
import { mediators } from '../collector';
export function getMediator(name, initialState, cache) {
    if (mediators[name]) {
        return mediators[name];
    }
    var keys = Object.keys(initialState);
    var set = keys.reduce(function (result, key) {
        var _a;
        var actionName = name.toUpperCase() + "_" + key.toUpperCase();
        return __assign(__assign({}, result), (_a = {}, _a[key] = actionCreator(actionName, function (value) { return ({ value: value }); }), _a));
    }, {});
    var get = function (state) {
        if (!state[name]) {
            throw new Error("MasterHook. State '" + name + "' not found.");
        }
        return state[name];
    };
    keys.forEach(function (key) {
        return Object.defineProperty(get, key, {
            enumerable: true,
            value: function (state) {
                var storeData = get(state);
                return storeData ? storeData[key] : undefined;
            }
        });
    });
    var defaultActionCreators = {};
    var defaultActionHandlers = {};
    var defaultActions = makeDefaultActions(name, initialState, cache);
    Object.keys(defaultActions).forEach(function (key) {
        var createdAction = actionCreator(name.toUpperCase() + "_" + key.toUpperCase());
        defaultActionCreators[key] = createdAction;
        defaultActionHandlers[createdAction] = defaultActions[key];
    });
    var handlers = keys.reduce(function (result, key) {
        var _a;
        return (__assign(__assign({}, result), (_a = {}, _a[set[key]] = function (state, _a) {
            var _b;
            var value = _a.payload.value;
            return (__assign(__assign({}, state), (_b = {}, _b[key] = value, _b)));
        }, _a)));
    }, defaultActionHandlers);
    // const cached = await getCached(name, false, cache)
    return {
        set: set,
        get: get,
        defaultActions: defaultActionCreators,
        reducer: createReducer(handlers, initialState),
        initialState: initialState,
        cache: cache,
        name: name,
        handlers: handlers,
    };
}
