var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from 'react';
import { useTheme } from '../context';
import cn from '../utils/class-names';
import * as console from '../utils/console';
import { ICON_NAME } from "./types";
import { getConfig } from '../utils/get-config';
import { useCss } from './styles';
import { setStyles } from '../utils/styles';
import { useBox } from '../Box';
var id = 0;
var Icon = function (_a, ref) {
    var className = _a.className, _b = _a.fill, fill = _b === void 0 ? "default" : _b, inverse = _a.inverse, _c = _a.size, size = _c === void 0 ? "default" : _c, _d = _a.style, style = _d === void 0 ? {} : _d, children = _a.children, name = _a.name, forceLine = _a.forceLine, forceFill = _a.forceFill, forwardRef = _a.forwardRef, fancy = _a.fancy, restProps = __rest(_a, ["className", "fill", "inverse", "size", "style", "children", "name", "forceLine", "forceFill", "forwardRef", "fancy"]);
    var thisId = useRef(++id);
    var newStyle = __assign({}, style);
    var TRY_TO_INVERSE = useBox().TRY_TO_INVERSE;
    var _e = useTheme(), icons = _e.icons, normalizedConfig = _e.normalizedConfig;
    var css = useCss();
    var _f = getConfig(normalizedConfig), iconConfig = _f.iconConfig, customIconValue = _f.customIconValue;
    function handleRef(node) {
        if (!node) {
            return;
        }
        typeof forwardRef === 'function' && forwardRef(node);
        typeof ref === 'function' && ref(node);
        var pathList = node.querySelectorAll('path');
        if (!iconConfig({ fill: fill })) {
            for (var _i = 0, pathList_1 = pathList; _i < pathList_1.length; _i++) {
                var path = pathList_1[_i];
                path.style.fill = fill;
            }
        }
    }
    if (customIconValue({ size: size })) {
        newStyle.width = size || undefined;
        newStyle.height = size || undefined;
        newStyle.minWidth = size || undefined;
        newStyle.minHeight = size || undefined;
        size = 'default';
    }
    var additionalClassName;
    if (customIconValue({ fill: fill })) {
        additionalClassName = "t-icon-fill-" + thisId.current;
        setStyles(additionalClassName, "\n      ." + additionalClassName + " *[fill]:not([fill=\"none\"]) {fill: " + fill + "}\n      ." + additionalClassName + " *[stroke]:not([stroke=\"none\"]) {stroke: " + fill + "}\n    ");
    }
    if (children) {
        console.warn('Prop "children" is prohibited, it will be ignored', Icon);
    }
    if (!icons) {
        return null;
    }
    // @ts-ignore
    var FinalIcon = name && (icons === null || icons === void 0 ? void 0 : icons[name]);
    if (!FinalIcon) {
        console.warn("There is no such Icon like \"" + name + "\" with size \"" + size + "\"\nCheck if you imported icons correctrly.\nMore info http://themoir.opium.pro/icons", Icon);
        return null;
    }
    var forseInverse = (inverse !== false) && (inverse || TRY_TO_INVERSE);
    var componentProps = __assign(__assign({}, restProps), { width: undefined, height: undefined, fill: undefined, style: newStyle, className: cn(css["icon"], forceLine && css["force-stroke-" + fill + (forseInverse ? '-inverse' : fancy ? '-fancy' : '')], forceFill && css["force-fill-" + fill + (forseInverse ? '-inverse' : fancy ? '-fancy' : '')], iconConfig({ fill: fill }) && css["fill-" + fill], iconConfig({ size: size }) && css["size-" + size], forseInverse && iconConfig({ fillInverse: fill }) && css["fill-inverse-" + fill], fancy && iconConfig({ fillFancy: fill }) && css["fill-fancy-" + fill], additionalClassName, className) });
    return (React.createElement(FinalIcon, __assign({}, componentProps)));
};
Icon.displayName = ICON_NAME;
export default React.forwardRef(Icon);
