import { createStyleSheet, getClasses } from '../utils/styles';
import { REACTION_NAME } from './types';
export var useCss = function () { return getClasses(REACTION_NAME); };
export default function (normalizedConfig) {
    var _a = normalizedConfig.reaction, duration = _a.duration, cursor = _a.cursor;
    var styles = {};
    styles["reaction"] = {
        outline: 'none',
    };
    for (var key in cursor) {
        styles["cursor-" + key] = {
            cursor: cursor[key],
        };
    }
    styles["ignore"] = {
        pointerEvents: 'none',
    };
    for (var key in duration) {
        styles["duration-" + key] = {
            transitionDuration: duration[key],
        };
    }
    return createStyleSheet(REACTION_NAME, styles);
}
