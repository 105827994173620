var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ActionButton } from '../action-button';
export var BackButton = function (props) {
    return (React.createElement(ActionButton, __assign({ fill: "faint", icon: "arrow-left-circle", size: "xs" }, props)));
};
BackButton.displayName = 'BackButton';
BackButton.description = 'Кнопка назад';
BackButton.demoProps = {
    Props: {
        _extends: ['ActionButton'],
        label: ['string', 'Back'],
    }
};
BackButton.usage = "<BackButton {Props}/>";
