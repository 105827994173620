import { usePortals } from './use-portals';
import { createPortal } from 'react-dom';
export var Portal = function (_a) {
    var children = _a.children;
    var portalsNode = usePortals().portalsNode;
    // useEffect(() => {
    //   const index = openPortal(children)
    //   return () => closePortal(index)
    // }, [])
    if (!portalsNode) {
        return null;
    }
    return createPortal(children, portalsNode);
};
