var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
// import * as console from '../utils/console'
import { Theme, Box, Font, Line, Icon, Fit, Align, Gap, Animate } from '../index';
import cn from '../utils/class-names';
import { config } from '../config';
export var withTagless = function (Component) {
    var _a, _b;
    function Tagless(props) {
        var children = props.children, FORCE_TAGLESS = props.FORCE_TAGLESS, forwardRef = props.forwardRef, parentId = props.id, parentProps = __rest(props, ["children", "FORCE_TAGLESS", "forwardRef", "id"]);
        function refuse(message) {
            message && console.warn(message, withTagless);
            var FORCE_TAGLESS = props.FORCE_TAGLESS, originalProps = __rest(props, ["FORCE_TAGLESS"]);
            if (Component.render) {
                return Component.render(originalProps);
            }
            if (typeof Component === 'function') {
                return Component(originalProps);
            }
        }
        // Remove invalid children
        var nonEmptyChildren = [];
        React.Children.forEach(children, function (child) {
            if (child) {
                nonEmptyChildren.push(child);
            }
        });
        // Check that at least one child is left after previous check
        if (nonEmptyChildren.length !== 1) {
            return refuse();
        }
        var onlyChild = nonEmptyChildren[0];
        // Check if after previous checks we stiil have something to render
        if (!onlyChild) {
            return refuse();
        }
        // Check if it's not just plain text
        if (typeof onlyChild === 'string') {
            return refuse();
        }
        // We can TRY_TAGLESS only with regular tags, functions and objects
        if (!['string', 'function', 'object'].includes(typeof onlyChild.type)) {
            return refuse();
        }
        var childProps = onlyChild.props;
        // Check that "id" attributes don't cover each other
        if (!FORCE_TAGLESS && parentId && childProps.id) {
            return refuse("OnlyChild hasn't merged his child because it met another \"id\" attribute\nParent id \"" + parentId + "\", child's id \"" + childProps.id + "\"");
        }
        var OnlyChildComponent = onlyChild.type;
        var mergingComponents = [Theme, Box, Font, Line, Icon, Fit, Align, Gap, Animate, Align.Span];
        var child_is_themeor_component = mergingComponents.includes(OnlyChildComponent)
            || !!mergingComponents.find(function (mergingComponent) { return mergingComponent.TryTagless === OnlyChildComponent; });
        var mergedProps = __assign({}, childProps);
        if (parentId) {
            mergedProps.id = parentId;
        }
        // TryTagless only with our components and regular tags
        if (!FORCE_TAGLESS && !child_is_themeor_component && typeof OnlyChildComponent !== 'string') {
            return refuse();
        }
        if (child_is_themeor_component) {
            forwardRef && (mergedProps.forwardRef = forwardRef);
        }
        else {
            forwardRef && (mergedProps.ref = forwardRef);
        }
        var passProps = __assign(__assign({}, parentProps), { children: function (_a) {
                var className = _a.className, children = _a.children, style = _a.style, rest = __rest(_a, ["className", "children", "style"]);
                return React.cloneElement(onlyChild, __assign(__assign(__assign({}, rest), mergedProps), { className: cn(className, mergedProps.className), style: __assign(__assign({}, style), mergedProps.style) }));
            } });
        if (Component.render) {
            return Component.render(passProps);
        }
        if (typeof Component === 'function') {
            return Component(passProps);
        }
    }
    Tagless.displayName = config.obfuscate
        ? config.hash('Tagles', true)
        : (Component.displayName || Component.name || ((_a = Component.render) === null || _a === void 0 ? void 0 : _a.displayName) || ((_b = Component.render) === null || _b === void 0 ? void 0 : _b.name)) + ".TryTagless";
    var TaglessComponent = Component;
    TaglessComponent.TryTagless = Tagless;
    return TaglessComponent;
};
