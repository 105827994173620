import React from 'react';
import { Gap, Align, Font, Fit, useReaction } from 'themeor';
import { useTextInput } from './context';
export var Placeholder = function () {
    var focus = useReaction().focus;
    var _a = useTextInput(), placeholder = _a.placeholder, value = _a.value, label = _a.label;
    var show = placeholder && !value && (!label || focus);
    if (!show) {
        return null;
    }
    return (React.createElement(Fit.TryTagless, { absolute: true, left: "0", right: "0", top: label ? '26px' : '11px' },
        React.createElement(Align.TryTagless, { vert: "center" },
            React.createElement(Font.TryTagless, { size: "sm", fill: "faintDown", weight: "400", family: "regular", lineHeight: "md", align: "left", nowrap: true },
                React.createElement(Gap.TryTagless, { hor: "md" }, placeholder)))));
};
