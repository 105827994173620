import { parseFieldName } from './parse-field-name';
export function getDeepFieldByPath(name, object) {
    if (object === void 0) { object = {}; }
    if (!name.includes('.') && !name.includes('[')) {
        return object[name];
    }
    var path = parseFieldName(name);
    return dig(object);
    function dig(initial, depth, isArray) {
        if (depth === void 0) { depth = 0; }
        if (isArray === void 0) { isArray = false; }
        var _a = path[depth], type = _a[0], key = _a[1];
        var value = isArray ? initial[parseInt(key)] : initial[key];
        if (depth >= path.length - 1) {
            return value;
        }
        if (value) {
            return dig(value, depth + 1, type === 'array');
        }
        return '';
    }
}
