var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { config } from '../config';
var CommonTag = config.CommonTag;
export var Common = function (_a, name) {
    var stretch = _a.stretch, width = _a.width, height = _a.height, maxWidth = _a.maxWidth, maxHeight = _a.maxHeight, minWidth = _a.minWidth, minHeight = _a.minHeight, _b = _a.style, style = _b === void 0 ? {} : _b, children = _a.children, forwardRef = _a.forwardRef, transition = _a.transition, opacity = _a.opacity, zoom = _a.zoom, rotate = _a.rotate, transform = _a.transform, cursor = _a.cursor, pointerEvents = _a.pointerEvents, getNode = _a.getNode, delay = _a.delay, hidden = _a.hidden, restProps = __rest(_a, ["stretch", "width", "height", "maxWidth", "maxHeight", "minWidth", "minHeight", "style", "children", "forwardRef", "transition", "opacity", "zoom", "rotate", "transform", "cursor", "pointerEvents", "getNode", "delay", "hidden"]);
    var newStyle = __assign({}, style);
    if (stretch) {
        newStyle.flexGrow = 1;
    }
    if (maxWidth || width) {
        newStyle.maxWidth = maxWidth || width || undefined;
    }
    if (minWidth || width) {
        newStyle.minWidth = minWidth || (maxWidth ? undefined : width) || undefined;
    }
    if (width) {
        newStyle.width = width;
    }
    if (height) {
        newStyle.height = height;
    }
    if (maxHeight || height) {
        newStyle.maxHeight = maxHeight || height || undefined;
    }
    if (minHeight || height) {
        newStyle.minHeight = minHeight || (maxHeight ? undefined : height) || undefined;
    }
    if (transition === true) {
        newStyle.transitionDuration = '300ms';
        newStyle.transitionProperty = 'all';
        newStyle.transitionTimingFunction = 'ease';
    }
    else if (transition) {
        newStyle.transition = transition;
    }
    if (opacity) {
        newStyle.opacity = opacity;
    }
    if (cursor) {
        newStyle.cursor = cursor;
    }
    if (delay) {
        newStyle.transitionDelay = delay;
    }
    if (pointerEvents) {
        newStyle.pointerEvents = pointerEvents;
    }
    if (hidden) {
        newStyle.display = 'none';
    }
    if (zoom || rotate || transform) {
        newStyle.transform = '';
    }
    if (zoom) {
        newStyle.transform += "scale(" + zoom + ") ";
    }
    if (rotate) {
        newStyle.transform += "rotate(" + rotate + ") ";
    }
    if (transform) {
        newStyle.transform += transform;
    }
    var componentProps = __assign(__assign({}, restProps), { style: newStyle, children: children });
    var dontPassName = typeof CommonTag === 'string';
    return typeof children === 'function'
        ? children(componentProps)
        : React.createElement(CommonTag, __assign({ "data-themeor": dontPassName ? undefined : name }, componentProps, { ref: forwardRef || getNode }));
};
