var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { localStorage } from './local-storage';
import { mediators } from '../collector';
export function getCached(name, withMeta, cache) {
    var _a;
    if (withMeta === void 0) { withMeta = false; }
    if (cache === void 0) { cache = ((_a = mediators[name]) === null || _a === void 0 ? void 0 : _a.cache) || {}; }
    return __awaiter(this, void 0, void 0, function () {
        var cachedState, _b, _c, _i, key, valueName, value, now, then;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    cachedState = {};
                    _b = [];
                    for (_c in cache)
                        _b.push(_c);
                    _i = 0;
                    _d.label = 1;
                case 1:
                    if (!(_i < _b.length)) return [3 /*break*/, 4];
                    key = _b[_i];
                    valueName = name + "__" + key;
                    return [4 /*yield*/, localStorage.getWithHeaders(valueName)];
                case 2:
                    value = _d.sent();
                    now = new Date().getTime();
                    then = new Date(value === null || value === void 0 ? void 0 : value.timestamp).getTime();
                    if (cache[key] === 0 || (then + cache[key] > now)) {
                        if (value) {
                            cachedState[key] = withMeta ? value : value.body;
                        }
                    }
                    else {
                        localStorage.removeItem(valueName);
                    }
                    _d.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/, cachedState];
            }
        });
    });
}
export function clearCache(name, value) {
    return __awaiter(this, void 0, void 0, function () {
        function clear(storageName) {
            var _a;
            var cache = ((_a = mediators[storageName]) === null || _a === void 0 ? void 0 : _a.cache) || {};
            if (typeof value === 'string') {
                var valueName = storageName + "__" + value;
                localStorage.removeItem(valueName);
            }
            else
                for (var key in cache) {
                    var valueName = storageName + "__" + key;
                    localStorage.removeItem(valueName);
                }
        }
        var storageName;
        return __generator(this, function (_a) {
            if (!name) {
                for (storageName in mediators) {
                    clear(storageName);
                }
            }
            else {
                clear(name);
            }
            return [2 /*return*/];
        });
    });
}
export function setCachedIfAllowed(name, keyOrValues, value) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        function set(key, newValue) {
            var _a;
            if (newValue === void 0) { newValue = value; }
            if (typeof cache[key] === 'number') {
                (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem) === null || _a === void 0 ? void 0 : _a.call(localStorage, name + "__" + key, newValue);
            }
        }
        var cache, key;
        return __generator(this, function (_b) {
            cache = (_a = mediators[name]) === null || _a === void 0 ? void 0 : _a.cache;
            if (!cache) {
                return [2 /*return*/];
            }
            if (typeof keyOrValues === 'string') {
                set(keyOrValues);
            }
            else {
                for (key in keyOrValues) {
                    set(key, keyOrValues[key]);
                }
            }
            return [2 /*return*/];
        });
    });
}
