import React, { useEffect, useState } from 'react';
import newId from '../utils/new-id';
import { ThemeContext, ConfigContext } from '../context';
import isDarkMode from '../utils/is-dark-mode';
import { THEME_NAME } from './types';
import setBoxStyle from '../Box/styles';
import setFontStyle from '../Font/styles';
import setGapStyle from '../Gap/styles';
import setAlignStyle from '../Align/styles';
import setLineStyle from '../Line/styles';
import setIconStyle from '../Icon/styles';
import setReactionStyle from '../Reaction/styles';
import setFitStyle from '../Fit/styles';
import { normalizeConfig } from '../utils/normalize-config';
import './reset';
export var Theme = function (_a) {
    var children = _a.children, icons = _a.icons, darkConfig = _a.darkConfig, _b = _a.config, config = _b === void 0 ? {} : _b;
    var id = useState(newId())[0];
    var _c = useState(config), currentConfig = _c[0], setCurrentConfig = _c[1];
    var _d = useState(false), isReady = _d[0], setIsReady = _d[1];
    function changeColorMode() {
        if (darkConfig && isDarkMode() && currentConfig != darkConfig) {
            setCurrentConfig(darkConfig);
        }
        else if (currentConfig != config) {
            setCurrentConfig(config);
        }
    }
    // Track dark mode
    useEffect(function () {
        var colorScheme = window === null || window === void 0 ? void 0 : window.matchMedia('(prefers-color-scheme: dark)');
        colorScheme.addEventListener && colorScheme.addEventListener('change', changeColorMode);
        return function () {
            var colorScheme = window === null || window === void 0 ? void 0 : window.matchMedia('(prefers-color-scheme: dark)');
            colorScheme.removeEventListener && colorScheme.removeEventListener('change', changeColorMode);
        };
    }, [currentConfig]);
    useEffect(function () {
        changeColorMode();
    }, [config, darkConfig]);
    // useEffect(() => {
    //   reset && import('./reset')
    // }, [reset])
    // Update
    useEffect(function () {
        var normalizedConfig = normalizeConfig(currentConfig);
        setFitStyle(normalizedConfig);
        setBoxStyle(normalizedConfig);
        setFontStyle(normalizedConfig);
        setGapStyle(normalizedConfig);
        setLineStyle(normalizedConfig);
        setReactionStyle(normalizedConfig);
        setIconStyle(normalizedConfig);
        setAlignStyle(normalizedConfig);
        setIsReady(true);
    }, [currentConfig]);
    useEffect(function () {
        if (!(icons === null || icons === void 0 ? void 0 : icons.default) && (icons === null || icons === void 0 ? void 0 : icons.md)) {
            icons.default = icons.md;
        }
    }, [icons]);
    var context = {
        icons: icons,
        themeId: id,
        darkMode: isDarkMode(),
        normalizedConfig: normalizeConfig(currentConfig),
    };
    if (!isReady) {
        return null;
    }
    return (React.createElement(ConfigContext.Provider, { value: { config: config, darkConfig: darkConfig, icons: icons, currentConfig: currentConfig, setCurrentConfig: setCurrentConfig } },
        React.createElement(ThemeContext.Provider, { value: context }, children)));
};
Theme.displayName = THEME_NAME;
