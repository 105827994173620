var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { PathContext, setSetState } from './context';
import { localStorage } from './local-storage';
import { config } from './config';
import { getPathfromHistory } from './utils';
import * as nav from './actions';
import { useUpdate, getPathFromUrl } from './utils';
export var matched;
export function setMatched(arg) {
    if (arg === false) {
        matched = undefined;
    }
    else if (!Array.isArray(matched)) {
        matched = [arg];
    }
    else {
        matched.push(arg);
    }
}
export var Router = function (_a) {
    var _b;
    var _c = _a.history, defaultHistory = _c === void 0 ? [[config.defaultPath, {}]] : _c, _d = _a.backHistory, defaultBackHistory = _d === void 0 ? [] : _d, _e = _a.saveState, saveState = _e === void 0 ? false : _e, rest = __rest(_a, ["history", "backHistory", "saveState"]);
    var _f = useState(false), isReady = _f[0], setIsReady = _f[1];
    var _g = useUpdate(), update = _g[0], updated = _g[1];
    var _h = useState({
        history: defaultHistory,
        backHistory: defaultBackHistory,
    }), state = _h[0], setState = _h[1];
    setSetState(setState, state);
    var history = state.history, backHistory = state.backHistory;
    var path = getPathfromHistory(history) || config.defaultPath;
    var cleanPath = (_b = path.split('?')[0]) === null || _b === void 0 ? void 0 : _b.replace(/^\//, '').replace(/\/$/, '');
    useEffect(function () {
        if (!history.length) {
            setState(function (state) { return (__assign(__assign({}, state), { history: defaultHistory })); });
        }
    }, [history]);
    // Handle localstorge
    // Before render
    useEffect(function () {
        saveState ? localStorage.getHistory().then(function (local) {
            var _a, _b, _c;
            if (!local) {
                return;
            }
            var newHistory = local.history || defaultHistory;
            if (config.isBrowser) {
                var browserPath = getPathFromUrl();
                if (browserPath !== ((_c = (_b = (_a = newHistory.slice) === null || _a === void 0 ? void 0 : _a.call(newHistory, -1)) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c[0])) {
                    newHistory.push([browserPath, {}]);
                }
            }
            var newBackHistory = local.backHistory || [];
            setState(function (state) { return (__assign(__assign({}, state), { history: newHistory, backHistory: newBackHistory })); });
        }) : localStorage.removeHistory();
        setTimeout(function () { return setIsReady(true); });
    }, []);
    // Track browser back and forward
    useEffect(function () {
        var _a;
        if (config.isBrowser) {
            (_a = window.addEventListener) === null || _a === void 0 ? void 0 : _a.call(window, 'popstate', update);
            return function () { var _a; return (_a = window.removeEventListener) === null || _a === void 0 ? void 0 : _a.call(window, 'popstate', update); };
        }
        return;
    }, []);
    // Update browser path
    useEffect(function () {
        if (isReady && config.isBrowser) {
            var browserPath = getPathFromUrl();
            if (browserPath !== path) {
                window.history.pushState(null, '', path);
            }
        }
    }, [path, isReady]);
    // Update localstorage
    useEffect(function () {
        isReady && saveState && localStorage.setHistory({ history: history, backHistory: backHistory });
    }, [history, backHistory, isReady]);
    // Back and Forward click
    useEffect(function () {
        if (updated && config.isBrowser) {
            var newPath = getPathFromUrl();
            if (newPath !== path) {
                nav.go(newPath);
            }
        }
    }, [updated]);
    // Track changes
    useEffect(function () {
        var _a;
        (_a = config.onSwitch) === null || _a === void 0 ? void 0 : _a.call(config, path, history, backHistory);
    }, [history, backHistory]);
    useEffect(function () {
        setMatched(false);
    }, [path]);
    var stackHistory = nav.getStack(path, history);
    if (!isReady) {
        return null;
    }
    var splitPath = path.split(config.stackSeparator);
    return (_jsx(PathContext.Provider, __assign({}, rest, { value: {
            history: history,
            stackHistory: stackHistory,
            backHistory: backHistory,
            path: path,
            splitPath: splitPath,
            cleanPath: cleanPath,
        } }), void 0));
};
