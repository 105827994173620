var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { Font, Box, Gap, Fit } from 'themeor';
import { Portal } from '../portal';
import { useAppLayout } from '../app-layout';
import { placeNode } from '../../utils';
import hotkeys from 'hotkeys-js';
import { tooltipConfig } from './config';
export var Tooltip = function (_a) {
    var children = _a.children, _b = _a.placeOrder, placeOrder = _b === void 0 ? tooltipConfig.placeOrder : _b, _c = _a.delay, delay = _c === void 0 ? tooltipConfig.delay : _c, _d = _a.duration, duration = _d === void 0 ? tooltipConfig.duration : _d, place = _a.place, _e = _a.delayToHide, delayToHide = _e === void 0 ? tooltipConfig.delayToHide : _e, parentNode = _a.parentNode, windowNode = _a.windowNode, rest = __rest(_a, ["children", "placeOrder", "delay", "duration", "place", "delayToHide", "parentNode", "windowNode"]);
    var scrollNode = useAppLayout().scrollNode;
    var targetNode;
    var hovered;
    var timeout;
    var opened;
    if (!windowNode) {
        windowNode = scrollNode || window;
    }
    useEffect(function () {
        if (!parentNode) {
            handleClose();
        }
    });
    function setInPlace() {
        placeNode(targetNode, parentNode, placeOrder, place);
    }
    function handleScroll() {
        handleClose();
        setInPlace();
    }
    function startToOpen() {
        handleOpen();
        setInPlace();
        windowNode === null || windowNode === void 0 ? void 0 : windowNode.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', trackMove);
    }
    function removeMovementTrack() {
        windowNode === null || windowNode === void 0 ? void 0 : windowNode.removeEventListener('scroll', handleScroll);
        window.removeEventListener('mousemove', trackMove);
    }
    function trackMove(event) {
        if ((parentNode === null || parentNode === void 0 ? void 0 : parentNode.contains(event.target)) || (targetNode === null || targetNode === void 0 ? void 0 : targetNode.contains(event.target))) {
            !opened && handleOpen();
        }
        else {
            opened && handleClose();
        }
    }
    function trackMouseHold() {
        clearTimeout(timeout);
        timeout = setTimeout(startToOpen, delay);
    }
    function handleParentClick() {
        trackMouseHold();
        if (opened) {
            handleClose();
            removeTrackers();
        }
    }
    function startWaiting() {
        setInPlace();
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.addEventListener('mousemove', trackMouseHold);
        parentNode.addEventListener('click', handleParentClick);
    }
    function stopWaiting() {
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.removeEventListener('mousemove', trackMouseHold);
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.removeEventListener('click', handleParentClick);
    }
    function handleOpen(event) {
        if (!targetNode) {
            return;
        }
        hovered = true;
        if (opened) {
            return;
        }
        targetNode.style.display = 'block';
        setInPlace();
        setTimeout(function () {
            if (!document.contains(parentNode)) {
                handleClose();
                return;
            }
            hotkeys('esc', 'tooltips', handleClose);
            hotkeys.setScope('tooltips');
            targetNode.style.opacity = '1';
            opened = true;
        }, 100);
    }
    function handleClose(event) {
        if (!targetNode) {
            return;
        }
        hovered = false;
        if (!opened) {
            return;
        }
        setTimeout(function () {
            if (!hovered) {
                targetNode.style.opacity = '0';
                setTimeout(function () {
                    if (!hovered) {
                        targetNode.style.display = 'none';
                        removeTrackers();
                        hotkeys.deleteScope('tooltips');
                        opened = false;
                    }
                }, duration);
            }
        }, delayToHide);
    }
    function removeTrackers() {
        removeMovementTrack();
        stopWaiting();
        clearMouseHold();
    }
    function clearMouseHold() {
        clearTimeout(timeout);
    }
    function handleSourceRef(node) {
        if (!node) {
            return;
        }
        parentNode = parentNode || node.previousElementSibling || node.parentNode;
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.addEventListener('mouseenter', startWaiting);
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.addEventListener('mouseleave', clearMouseHold);
    }
    useEffect(function () { return function () {
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.removeEventListener('mouseenter', startWaiting);
        parentNode === null || parentNode === void 0 ? void 0 : parentNode.removeEventListener('mouseleave', clearMouseHold);
        removeTrackers();
    }; }, []);
    function handleTargetRef(node) {
        if (!node) {
            return;
        }
        targetNode = node;
    }
    if (!children) {
        return null;
    }
    return (React.createElement(Fit, { forwardRef: handleSourceRef, hidden: true },
        React.createElement(Portal, null,
            React.createElement(Fit.TryTagless, { transition: "opacity" },
                React.createElement(Gap, { forwardRef: handleTargetRef, hidden: true, transition: "opacity " + duration + "ms", opacity: "0", size: "10px" },
                    React.createElement(Box.TryTagless, __assign({ blur: "md", fill: "baseDown", inverse: true, radius: "4px" }, rest),
                        React.createElement(Font.TryTagless, { fill: "base" },
                            React.createElement(Gap, { vert: "8px", hor: "12px" }, children))))))));
};
Tooltip.displayName = 'Tooltip';
Tooltip.demoProps = {};
