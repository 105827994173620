import { Align, Font, Gap, Box, Icon, Fit } from 'themeor'
import { LimitWidth, Page } from 'opium-ui'
import Prices from '../blocks/prices'


export default function () {
    return (
        <Page title="Premium Plans">
            <LimitWidth>
                <Gap size="64px" />
                <Prices />
            </LimitWidth>
        </Page>
    )
}