var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Fit, Box, Font, Gap, Icon, Reaction } from 'themeor';
import { withTooltip } from '../tooltip';
import { icons } from '../../theme/iconList';
export var Button = withTooltip(function (_a) {
    var label = _a.label, primary = _a.primary, _b = _a.type, type = _b === void 0 ? "button" : _b, disabled = _a.disabled, mini = _a.mini, critic = _a.critic, hint = _a.hint, stretch = _a.stretch, glow = _a.glow, light = _a.light, children = _a.children, forwardRef = _a.forwardRef, inverse = _a.inverse, icon = _a.icon, _c = _a.fill, fill = _c === void 0 ? disabled ? 'faintUp' : (critic && primary) ? 'critic' : primary ? 'accent' : 'base' : _c, _d = _a.fillHover, fillHover = _d === void 0 ? (critic && primary) ? 'criticUp' : primary ? 'accentUp' : 'hovereffect' : _d, height = _a.height, rest = __rest(_a, ["label", "primary", "type", "disabled", "mini", "critic", "hint", "stretch", "glow", "light", "children", "forwardRef", "inverse", "icon", "fill", "fillHover", "height"]);
    var fillActive = (critic && primary) ? 'criticDown' : primary ? 'accentDown' : 'hovereffect';
    return (React.createElement(Reaction, { smooth: true, track: !disabled && ['hover', 'focus', 'active'], cursor: disabled ? 'default' : 'pointer' }, function (rProps, r) { return (React.createElement(Fit.TryTagless, { inline: true, stretch: stretch },
        React.createElement(Box.TryTagless, __assign({ glow: glow && 'md', fill: disabled ? 'faintUp' : r.active ? fillActive : r.hoverOrFocus ? fillHover : fill, borderFill: (primary || disabled || light) ? "none" : "faintUp", radius: "xs" }, rest),
            React.createElement(Font.TryTagless, { nowrap: true, fill: (disabled && "faintDown") || (critic && "critic") || "base", weight: primary ? "600" : "500", size: "sm", family: "regular", inverse: disabled ? false : inverse, align: icon ? "left" : "center" },
                React.createElement(Gap.TryTagless, { hor: "xl" },
                    React.createElement("button", __assign({ disabled: disabled, ref: forwardRef, type: type }, rProps),
                        React.createElement(Align, { row: true, hor: "center", vert: "center", height: height || (mini ? "32px" : "48px"), gapHor: "8px" },
                            icon && (React.createElement(Icon, { name: icon, inverse: disabled ? false : inverse, fill: disabled ? "faintDown" : "base" })),
                            React.createElement(Align, null,
                                label,
                                hint && (React.createElement(React.Fragment, null,
                                    React.createElement(Gap, { size: "2px" }),
                                    React.createElement(Font, { inverse: disabled ? false : inverse, lineHeight: "xs", size: "x2s", fill: "faintDown", align: icon ? "left" : "center", weight: "400" }, hint)))),
                            children))))))); }));
});
Button.displayName = 'Button';
Button.description = 'Основная кнопка';
Button.demoProps = {
    Props: {
        _extends: ['withTooltip'],
        label: ['string', "Ooooh, click me, I' a button"],
        icon: ['select', 'airdrop', icons],
        hint: ['string', undefined],
        fillHover: ['string', undefined],
        primary: ['boolean', true],
        mini: ['boolean', false],
        critic: ['boolean', false],
        light: ['boolean', false],
        disabled: ['boolean', false],
    }
};
Button.usage = "<Button {Props}/>";
