function selectConfig(normalizedConfig, component, compare, boolean) {
    var _a, _b;
    var result = [];
    for (var key in compare) {
        var value = (_b = (_a = normalizedConfig[component]) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b[compare[key]];
        var isDefined = value !== undefined;
        if (!boolean) {
            result.push(value);
        }
        else {
            var wrongType = !['string', 'number'].includes(typeof compare[key]);
            result.push(wrongType ? false : !isDefined);
        }
    }
    if (length <= 1) {
        return result[0];
    }
    return result;
}
export var getConfig = function (normalizedConfig) {
    if (normalizedConfig === void 0) { normalizedConfig = {}; }
    return ({
        boxConfig: function (compare) {
            return selectConfig(normalizedConfig, 'box', compare);
        },
        customBoxValue: function (compare) {
            return selectConfig(normalizedConfig, 'box', compare, true);
        },
        fontConfig: function (compare) {
            return selectConfig(normalizedConfig, 'font', compare);
        },
        customFontValue: function (compare) {
            return selectConfig(normalizedConfig, 'font', compare, true);
        },
        gapConfig: function (compare) {
            return selectConfig(normalizedConfig, 'gap', compare);
        },
        customGapValue: function (compare) {
            return selectConfig(normalizedConfig, 'gap', compare, true);
        },
        lineConfig: function (compare) {
            return selectConfig(normalizedConfig, 'line', compare);
        },
        customLineValue: function (compare) {
            return selectConfig(normalizedConfig, 'line', compare, true);
        },
        iconConfig: function (compare) {
            return selectConfig(normalizedConfig, 'icon', compare);
        },
        customIconValue: function (compare) {
            return selectConfig(normalizedConfig, 'icon', compare, true);
        },
        reactionConfig: function (compare) {
            return selectConfig(normalizedConfig, 'reaction', compare);
        },
        customReactionValue: function (compare) {
            return selectConfig(normalizedConfig, 'reaction', compare, true);
        },
        fitConfig: function (compare) {
            return selectConfig(normalizedConfig, 'fit', compare);
        },
        customFitValue: function (compare) {
            return selectConfig(normalizedConfig, 'fit', compare, true);
        },
    });
};
