import { useEffect, useMemo, useRef } from 'react'
import { useConfig, Gap, Font, Align } from 'themeor'
import { AppLayout, AppMenu, Notice } from 'opium-ui'
import Footer from '../blocks/footer'
import { usePath, nav } from 'opium-nav'
import { networks, urls } from '../config'
import { useApp, useFacebook } from '../store'


export default function ({ children }) {
  const { splitPath, path } = usePath()
  const connect = splitPath[2]
  const { errors, dismissError } = useApp(['errors'])
  const isIntro = path === urls.intro
  const { name, avatar } = useFacebook(['name', 'avatar'])

  return (
    <AppLayout footer={!isIntro && <Footer />} menu={!isIntro && (
      <AppMenu width={100}>
        <Gap size="md" top="xl">
          <Align hor="center">
            <Font size="lg" weight="800" lineHeight="1" letterSpacing="0.2em" fill={connect ? "accent" : "base"}>
              CRA<br />
              M*<br />
              LA
            </Font>
          </Align>
        </Gap>
        <AppMenu.Item
          label="Facebook"
          icon="facebook"
          onClick={() => nav.go(networks.facebook.path)}
          active={connect === 'facebook'}
        />
        <AppMenu.Item
          label="Twitter"
          icon="twitter"
          onClick={() => nav.go(networks.twitter.path)}
          // active={connect === 'twitter'}
          tooltip="Cooming soon"
          tooltipDelay={100}
          disabled
        />
        <AppMenu.Item
          label="Instagram"
          icon="instagram"
          onClick={() => nav.go(networks.instagram.path)}
          // active={connect === 'instagram'}
          tooltip="Cooming soon"
          tooltipDelay={100}
          disabled
        />
        <AppMenu.Item
          label="Linkedin"
          icon="linkedin"
          onClick={() => nav.go(networks.linkedin.path)}
          // active={connect === 'linkedin'}
          tooltip="Cooming soon"
          tooltipDelay={100}
          disabled
        />

        <Gap stretch />

        <AppMenu.Item
          label="Premium plans"
          icon="dollar_coin"
          active={path === urls.premium}
          onClick={() => nav.go(urls.premium)}
        />
        <AppMenu.Item
          label={name}
          img={avatar}
          active={path === urls.settings}
          onClick={() => nav.go(urls.settings)}
        />
      </AppMenu>
    )}
    >
      {/* <UpdateTheme /> */}
      {children}
      {errors?.map(({ title, text, more }, index) => (
        <Notice key={index} text={text} title={title} onClose={dismissError}>
          {more}
        </Notice>
      ))}
    </AppLayout>
  )
}


function UpdateTheme() {
  const { splitPath, path } = usePath()
  const network = networks[splitPath[2]]
  const { config, setCurrentConfig, currentConfig } = useConfig()
  const initialConfig = useRef({})

  useEffect(() => {
    initialConfig.current = currentConfig
  }, [])

  useEffect(() => {
    if (!config) { return }
    if (network) {
      const newConfig = { ...config, fill: { ...config.fill } }
      newConfig.fill.accent = network.fill
      newConfig.fill.accentDown = network.hover
      setCurrentConfig(newConfig)
    } else {
      setCurrentConfig(initialConfig.current)
    }
  }, [path])

  return null
}