import { createAction, useStorage } from 'master-hook'
import { nav } from 'opium-nav'



export const setError = createAction(({ title, text, more }) => {
  const { errors = [], setErrors } = useStorage('app')
  const newErrors = [{ title, text, more }, ...errors]
  setErrors(newErrors)
})

export const dismissError = createAction(({ title, text }) => {
  const { errors = [], setErrors } = useStorage('app')
  const newErrors = errors.slice(1)
  setErrors(newErrors)
})

export const logOut = createAction(() => {
  const { reset: resetApp } = useStorage('app')
  const { reset: resetFacebook } = useStorage('facebook')
  nav.go('/')
  resetApp()
  resetFacebook()
})