import { createContext, useContext as useReactContext } from 'react';
export var state;
export var setState;
export function getState() {
    return state;
}
export function setSetState(func, value) {
    setState = func;
    state = value;
}
export var PathContext = createContext({});
export var usePath = function () { return useReactContext(PathContext); };
