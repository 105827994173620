export const urls = {
  intro: '/',
  app: '/#/',
  premium: '/#/premium',
  settings: '/#/settings',
  facebook: '/#/facebook',
  instagram: '/#/instagram',
  twitter: '/#/twitter',
  linkedin: '/#/linkedin',
} as const


export type Urls = typeof urls[keyof typeof urls]