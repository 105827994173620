import { Align, Font, Gap, Box } from 'themeor'
import { Button } from 'opium-ui'


export default function ({
    name,
    description = "",
    priceNote,
    hint,
    active = false,
    benefits = [],
    price,
    children,
}: any) {
    return (
        <Box.TryTagless
            borderFill={active ? "accent" : "faint"}
            radius="xl"
            borderWeight={active && "6px"}
        >
            <Gap.TryTagless size="40px">
                <Align>
                    <Font size="x2l" weight="900">{name}</Font>
                    <Gap size="16px" />
                    {description && (<>
                        <Font>
                            {description}
                        </Font>
                        <Gap size="32px" stretch />
                    </>)}

                    <Gap size="32px" stretch />

                    {price !== undefined && (<>
                        <Font size="40px" weight="900">
                            {price}
                            <Gap size="2px" inline />
                            <Font weight="500" inline size="20px">{priceNote}</Font>
                        </Font>
                    </>)}
                    {hint && (<>
                        <Font size="xs" fill="faint">
                            {hint}
                        </Font>
                    </>)}
                    {children}
                </Align>
            </Gap.TryTagless>
        </Box.TryTagless>
    )
}