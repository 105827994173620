var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { useForm } from './context';
import { getDeepFieldByPath } from '../../utils';
export var withForm = function (Component) { return function (_a) {
    var _b = _a.onChange, onChange = _b === void 0 ? function (a) { return a; } : _b, value = _a.value, initialValue = _a.initialValue, name = _a.name, match = _a.match, error = _a.error, onBlur = _a.onBlur, mask = _a.mask, disabled = _a.disabled, required = _a.required, label = _a.label, rest = __rest(_a, ["onChange", "value", "initialValue", "name", "match", "error", "onBlur", "mask", "disabled", "required", "label"]);
    var _c = useState(false), changed = _c[0], setChanged = _c[1];
    var _d = useState(error), hasError = _d[0], setError = _d[1];
    var _e = useForm(), setField = _e.setField, formChanged = _e.changed, setFormChanged = _e.setChanged, fields = _e.fields, setInitialValue = _e.setInitialValue;
    var fieldValue = (changed && formChanged && name) ? getDeepFieldByPath(name, fields) : (value || '');
    useEffect(function () {
        name && (setInitialValue === null || setInitialValue === void 0 ? void 0 : setInitialValue(name, initialValue || value));
    }, []);
    useEffect(function () {
        name && (setField === null || setField === void 0 ? void 0 : setField(name, value));
    }, [value]);
    function handleBlur(value) {
        if (disabled) {
            return;
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(value);
        if (match && changed) {
            var errorText = '';
            for (var _i = 0, match_1 = match; _i < match_1.length; _i++) {
                var matchItem = match_1[_i];
                if (!(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.match(matchItem[0]))) {
                    errorText = errorText + ' ' + matchItem[1];
                }
            }
            setError(errorText || false);
        }
        if (changed && required && !fieldValue) {
            setError('This field is required');
        }
    }
    function handleChange(value) {
        !formChanged && (setFormChanged === null || setFormChanged === void 0 ? void 0 : setFormChanged(true));
        hasError && setError(false);
        !changed && setChanged(true);
        var newValue = onChange(value);
        name && (setField === null || setField === void 0 ? void 0 : setField(name, newValue));
    }
    if (required && label) {
        label += ' *';
    }
    return (React.createElement(Component, __assign({}, rest, { label: label, value: fieldValue, initialValue: value, name: name, onChange: !disabled && handleChange, onBlur: !disabled && handleBlur, error: hasError, disabled: disabled })));
}; };
