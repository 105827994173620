import { Wrapper } from './wrapper';
import { Item } from './item';
import { icons } from '../../theme/iconList';
export var AppMenu = Wrapper;
AppMenu.Item = Item;
AppMenu.Item.displayName = "AppMenu.Item";
AppMenu.displayName = "AppMenu";
AppMenu.description = 'Боковое вертикальное меню';
AppMenu.demoProps = {
    Props: {
        row: ['boolean'],
        gapHor: ['string'],
        gapVert: ['string'],
    },
    'Item Props': {
        _indent: '  ',
        _extends: ['MakeButton', 'withTooltip'],
        label: ['string', 'Demo Item'],
        icon: ['select', '3d-rotate', icons],
        hint: ['string'],
        img: ['string'],
        fill: ['string'],
        href: ['string'],
        fontSize: ['string'],
        active: ['boolean'],
        row: ['boolean'],
        disabled: ['boolean'],
        blank: ['boolean'],
    },
};
AppMenu.usage = "<AppMenu{Props}>\n  <AppMenu.Item{Item Props}/>\n  <AppMenu.Item{Item Props}/>\n  <AppMenu.Item{Item Props}/>\n  <AppMenu.Item{Item Props}/>\n</AppMenu>";
