import React, { useState } from 'react';
import { PortalsContext } from './use-portals';
import { Portals } from './portals';
import { Fit } from 'themeor';
import { useForceUpdate } from '../../utils';
export var portals = new Map();
var id = 0;
export function PortalsProvider(_a) {
    var children = _a.children;
    var update = useForceUpdate()[0];
    var _b = useState(), portalsNode = _b[0], setPortalsNode = _b[1];
    function closePortal(index) {
        portals.delete(index);
        update();
    }
    function openPortal(value) {
        var index = id++;
        portals.set(index, value);
        update();
        return index;
    }
    return (React.createElement(PortalsContext.Provider, { value: {
            portals: portals,
            openPortal: openPortal,
            closePortal: closePortal,
            update: update,
            portalsNode: portalsNode,
        } },
        children,
        React.createElement(Fit, { zIndex: 400, fixed: true, left: "0", top: "0", forwardRef: setPortalsNode }),
        React.createElement(Portals, null)));
}
