var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Fit, Box, Font, Gap } from 'themeor';
var SIZES = {
    default: {
        size: '20px',
        font: 'x3s',
    },
    lg: {
        size: '30px',
        font: 'md',
    }
};
export var Counter = function (_a) {
    var value = _a.value, attention = _a.attention, base = _a.base, _b = _a.size, size = _b === void 0 ? 'default' : _b, rest = __rest(_a, ["value", "attention", "base", "size"]);
    return (React.createElement(Fit, { inline: true },
        React.createElement(Fit, { inline: true },
            React.createElement(Gap.TryTagless, { hor: "6px", minWidth: SIZES[size].size, height: SIZES[size].size },
                React.createElement(Box.TryTagless, __assign({ radius: "max", fill: (base && 'base') || (attention ? "base" : "faint"), inverse: attention, shadow: !!base ? 'sm' : 'none' }, rest),
                    React.createElement(Align.TryTagless, { vert: "center", hor: "center" },
                        React.createElement(Font, { size: SIZES[size].font, weight: "700", fill: (attention || base) ? "base" : "faint" }, value)))))));
};
