var hashCounter = 0;
var hashed = {};
export var config = {
    obfuscate: false,
    isNative: false,
    CommonTag: 'div',
    hash: function (value, update) {
        if (!hashed[value] || update) {
            hashed[value] = (hashCounter++).toString(16).toUpperCase();
        }
        return hashed[value];
    },
};
