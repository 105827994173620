var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Dropdown } from '../dropdown';
import { Font, Align, Gap, Fit } from 'themeor';
import { useSelect } from './context';
import { useTextInput } from "../text-input";
import { Checkbox } from '../checkbox';
import { MarkMatch } from '../mark-match';
export var Option = function (_a) {
    var _b = _a.value, oneValue = _b === void 0 ? undefined : _b, _c = _a.label, label = _c === void 0 ? oneValue || undefined : _c, _d = _a.hint, hint = _d === void 0 ? undefined : _d, _e = _a.children, children = _e === void 0 ? undefined : _e, _f = _a.displayValue, displayValue = _f === void 0 ? undefined : _f, _g = _a.onClick, onClick = _g === void 0 ? undefined : _g, _h = _a.onCheckboxClick, onCheckboxClick = _h === void 0 ? undefined : _h, _j = _a.active, active = _j === void 0 ? false : _j, rest = __rest(_a, ["value", "label", "hint", "children", "displayValue", "onClick", "onCheckboxClick", "active"]);
    var _k = useTextInput(), value = _k.value, onChange = _k.onChange;
    var _l = useSelect(), multi = _l.multi, onCompare = _l.onCompare;
    var _m = Dropdown.use(), setOpened = _m.setOpened, search = _m.search;
    var checkboxRef;
    var selected = active
        || (typeof onCompare === 'function' && onCompare(value, oneValue, multi))
        || (multi ? value === null || value === void 0 ? void 0 : value.includes(oneValue) : (value === oneValue));
    function handleClick(event) {
        if (multi) {
            var newValue = new Set(Array.isArray(value) ? value : [value]);
            if (newValue.has(oneValue)) {
                newValue.delete(oneValue);
            }
            else {
                newValue.add(oneValue);
            }
            newValue = Array.from(newValue);
            onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
        }
        else {
            setOpened(false);
            if (oneValue !== value) {
                onChange === null || onChange === void 0 ? void 0 : onChange(oneValue);
            }
        }
        if (checkboxRef && checkboxRef.contains(event.tagget)) {
            onCheckboxClick ? onCheckboxClick(event) : (onClick && onClick(event));
            return;
        }
        onClick && onClick(event);
    }
    return (React.createElement(Dropdown.Item, __assign({}, rest, { onClick: handleClick }),
        React.createElement(Align, { row: true, vert: "center" },
            multi && (React.createElement(React.Fragment, null,
                React.createElement(Checkbox, { value: selected, forwardRef: function (n) { return checkboxRef = n; } }),
                React.createElement(Gap, null))),
            React.createElement(Fit, { stretch: true },
                React.createElement(Font, { fill: selected ? "accent" : "base", weight: selected ? '600' : '500' },
                    React.createElement(MarkMatch, { target: search }, label)),
                !!hint && (React.createElement(Font, { size: "sm", fill: "faintDown" },
                    React.createElement(MarkMatch, { target: search }, hint))),
                children))));
};
