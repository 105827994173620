var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { Align, Fit, Box, Animate, Icon } from 'themeor';
import { MakeButton } from '../make-button';
import { AppLayoutContext, useAppLayout } from './context';
import { useScreenFit, ScreenFit } from '../screen-fit';
import { Cover } from '../cover';
export var Layout = function (_a) {
    var menu = _a.menu, header = _a.header, children = _a.children, modals = _a.modals, getContentNode = _a.getContentNode, footer = _a.footer, fill = _a.fill, rest = __rest(_a, ["menu", "header", "children", "modals", "getContentNode", "footer", "fill"]);
    var _b = useAppLayout(), contentNode = _b.contentNode, menuNode = _b.menuNode, setContentNode = _b.setContentNode, setMenuNode = _b.setMenuNode;
    var _c = useState(false), openMenu = _c[0], setOpenMenu = _c[1];
    var isSmall = useScreenFit().isSmall;
    function contentNodeRef(node) {
        getContentNode && getContentNode(node);
        setContentNode(node);
    }
    var context = { contentNode: contentNode, menuNode: menuNode };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fit.TryTagless, { zIndex: 100, fixed: true, clip: true, left: "0", top: "0", right: "0", bottom: "0" },
            React.createElement(Align, __assign({ pattern: (menu && !isSmall) ? "auto 1fr" : "1fr", vert: "stretch" }, rest),
                isSmall && openMenu && (React.createElement(Fit, { fixed: true, zIndex: 190 },
                    React.createElement(Animate, { onMount: "fadeIn" },
                        React.createElement(Cover, null)))),
                menu && (!isSmall || openMenu) && (React.createElement(AppLayoutContext.Provider, { value: __assign(__assign({}, context), { scrollNode: menuNode }) },
                    React.createElement(Animate.TryTagless, { onMount: isSmall && "fadeInLeft" },
                        React.createElement(Box.TryTagless, { shadow: "sm" },
                            React.createElement(Fit.TryTagless, { zIndex: 200, forwardRef: setMenuNode, absolute: isSmall, top: "0", bottom: "0", left: "0", scroll: true },
                                React.createElement(Align, { hor: "stretch", vert: "stretch" }, menu)))))),
                isSmall && (React.createElement(Animate.TryTagless, { onMount: "fadeInDown" },
                    React.createElement(Fit, { fixed: true, top: "12px", right: "12px", zIndex: 300 },
                        React.createElement(MakeButton, { radius: "max", offset: "0", onClick: function () { return setOpenMenu(function (value) { return !value; }); } },
                            React.createElement(Box.TryTagless, { shadow: "lg", radius: "max", width: "60px", height: "60px", fill: "base" },
                                React.createElement(Align, { vert: "center", hor: "center" },
                                    React.createElement(Icon, { size: "xl", name: openMenu ? "cross" : "menu_burger" }))))))),
                React.createElement(AppLayoutContext.Provider, { value: __assign(__assign({}, context), { scrollNode: contentNode }) },
                    React.createElement(Fit.TryTagless, { stretch: true, zIndex: 100, height: "100vh", scroll: !(isSmall && openMenu), clip: isSmall && openMenu, forwardRef: contentNodeRef },
                        React.createElement(Box, { fill: fill },
                            React.createElement(ScreenFit, null,
                                React.createElement(Align, { minHeight: "100vh" },
                                    header && (React.createElement(Fit.TryTagless, { style: {
                                            position: 'sticky',
                                            top: '0',
                                            zIndex: 100,
                                        } }, header)),
                                    React.createElement(Fit, { stretch: true }, children),
                                    footer))))))),
        React.createElement(Fit, { zIndex: 300, cover: "screen", stick: "top-left" }, modals)));
};
