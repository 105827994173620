// !!! Don't change import order
import { constructor } from './constructor';
import * as collector from './collector';
import * as selectors from './selectors';
import * as storage from './storage';
import * as store from './store';
import * as localStorage from './local-storage';
import * as mediators from './mediators';
import * as actions from './actions';
export var MasterHook = constructor;
Object.assign(MasterHook, selectors, storage, store, actions, localStorage, mediators, collector);
export default MasterHook;
export * from './collector';
export * from './selectors';
export * from './storage';
export * from './store';
export * from './local-storage';
export * from './mediators';
export * from './actions';
