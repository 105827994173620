import { fitNode } from './fit-node';
export function placeNode(targetNode, parentNode, placeOrder, place) {
    if (placeOrder === void 0) { placeOrder = ['bottom', 'top', 'right', 'left']; }
    if (place === void 0) { place = undefined; }
    if (!targetNode || !parentNode) {
        return;
    }
    var fits = fitNode(targetNode, parentNode);
    if (!fits) {
        return;
    }
    var canPlace = fits[0], freeSpace = fits[1], targetRect = fits[2], parentRect = fits[3];
    var finalPlace;
    if (place) {
        finalPlace = place;
    }
    else {
        for (var _i = 0, placeOrder_1 = placeOrder; _i < placeOrder_1.length; _i++) {
            var newPlace = placeOrder_1[_i];
            if (canPlace[newPlace.split('-')[0]]) {
                finalPlace = newPlace;
                break;
            }
        }
    }
    if (!finalPlace) {
        var maxFreeSpace = 0;
        var placeWIthMoreSpace = placeOrder[0];
        for (var key in freeSpace) {
            if (freeSpace[key] > maxFreeSpace) {
                maxFreeSpace = freeSpace[key];
                placeWIthMoreSpace = key;
            }
        }
        finalPlace = placeWIthMoreSpace;
    }
    var firstPlace = finalPlace === null || finalPlace === void 0 ? void 0 : finalPlace.split('-')[0];
    var secondPlace = finalPlace === null || finalPlace === void 0 ? void 0 : finalPlace.split('-')[1];
    var topValue = {
        top: freeSpace.top - targetRect.height,
        left: freeSpace.top,
        right: freeSpace.top,
        bottom: parentRect.top + parentRect.height,
    };
    var leftValue = {
        top: freeSpace.left,
        left: freeSpace.left - targetRect.width,
        right: parentRect.left + parentRect.width,
        bottom: freeSpace.left,
    };
    targetNode.style.position = 'fixed';
    if (['top', 'bottom'].includes(firstPlace)) {
        targetNode.style.top = topValue[firstPlace] + 'px';
        // if (targetNode.offsetHeight > freeSpace[firstPlace]) {
        //   targetNode.style.height = freeSpace[firstPlace] + 'px'
        // }
    }
    if (['left', 'right'].includes(firstPlace)) {
        targetNode.style.left = leftValue[firstPlace] + 'px';
        // if (targetNode.offsetWidth > freeSpace[firstPlace]) {
        //   targetNode.style.width = freeSpace[firstPlace] + 'px'
        // }
    }
    // Handling second walue in position
    if (!secondPlace) {
        ['top', 'bottom'].includes(firstPlace) && (secondPlace = 'horCenter');
        ['right', 'left'].includes(firstPlace) && (secondPlace = 'vertCenter');
    }
    var topSecondValue = {
        top: freeSpace.top,
        vertCenter: parentRect.top + (parentRect.height / 2) - (targetRect.height / 2),
        bottom: parentRect.top + parentRect.height - targetRect.height,
    };
    var leftSecondValue = {
        left: freeSpace.left,
        horCenter: parentRect.left + (parentRect.width / 2) - (targetRect.width / 2),
        right: freeSpace.left + parentRect.width - targetRect.width,
    };
    if (['top', 'bottom', 'vertCenter'].includes(secondPlace)) {
        targetNode.style.top = topSecondValue[secondPlace] + 'px';
    }
    if (['right', 'left', 'horCenter'].includes(secondPlace)) {
        targetNode.style.left = leftSecondValue[secondPlace] + 'px';
    }
    if (['top', 'bottom'].includes(firstPlace) && (secondPlace === 'stretch')) {
        targetNode.style.left = freeSpace.left + 'px';
        targetNode.style.right = freeSpace.right + 'px';
    }
    if (['right', 'left'].includes(firstPlace) && (secondPlace === 'stretch')) {
        targetNode.style.top = freeSpace.top + 'px';
        targetNode.style.bottom = freeSpace.bottom + 'px';
    }
}
