import React from 'react';
import { Theme, Fit, Box, Font } from 'themeor';
import themeConfig from '../../theme/config';
import themeIcons from '../../theme/iconList';
import { ScreenFit } from '../screen-fit';
export function AppTheme(_a) {
    var config = _a.config, icons = _a.icons, children = _a.children;
    return (React.createElement(ScreenFit, null,
        React.createElement(Theme, { config: config || themeConfig, icons: icons || themeIcons },
            React.createElement(Fit.TryTagless, { minHeight: "100vh" },
                React.createElement(Box.TryTagless, { fill: "base" },
                    React.createElement(Font.TryTagless, { family: "regular", fill: "base", size: "sm", weight: "500", lineHeight: "md" }, children))))));
}
