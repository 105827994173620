import { useState } from 'react';
export function getPathfromHistory(history) {
    return !!(history === null || history === void 0 ? void 0 : history.length) && history.slice(-1)[0][0];
}
export function useUpdate() {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    return [
        function () { setValue(function (value) { return value + 1; }); },
        value,
    ];
}
export function parseQuery(query) {
    var result = {};
    var varList = query.split('&');
    for (var _i = 0, varList_1 = varList; _i < varList_1.length; _i++) {
        var varLine = varList_1[_i];
        var _a = varLine.split('='), varName = _a[0], varValue = _a[1];
        result[varName] = varValue;
    }
    return result;
}
export function getPathFromUrl() {
    var _a;
    var url = window === null || window === void 0 ? void 0 : window.location.toString();
    var splitPath = (_a = url.split('://')[1]) === null || _a === void 0 ? void 0 : _a.split('/');
    splitPath.shift();
    return "/" + splitPath.join('/');
}
