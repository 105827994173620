import React from 'react';
import { Gap, Font } from 'themeor';
import { useTextInput } from './context';
import { Tooltip } from '../tooltip';
export var Caption = function () {
    var error = useTextInput().error;
    if (typeof error !== 'string') {
        return null;
    }
    return React.createElement(Tooltip, { delay: 0 }, error);
    return (React.createElement(React.Fragment, null,
        React.createElement(Gap, { size: "xs" }),
        React.createElement(Font, { fill: "critic", size: "sm" }, error)));
};
