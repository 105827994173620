import { Font, Gap, Box, Align } from 'themeor'
import { Button, useScreenFit } from 'opium-ui'
import PricePlan from '../components/price-plan'


export default function () {
  const { isSmall } = useScreenFit()

  return (
    <Align
      pattern={isSmall ? "1fr" : "1fr 1fr 1fr"}
      gapHor="x3l"
      gapVert="x3l"
      vert="stretch"
    >
      <PricePlan
        active
        name="Fucking Free!"
        price={0}
        priceNote="$ per doesn't matter"
        description="Use basic functions for free"
        hint="yea, yea, fuck us"
      />

      <PricePlan
        name="Just One Fucking Time!"
        price={1}
        priceNote="$ per one full scan"
        description="Pay only when you check your posts"
        hint="we'll ask you every time before the payment"
      >
        <Gap size="24px" />
        <Button primary label="Choose this plan" />
      </PricePlan>

      <PricePlan
        name="Fucking Subscription!"
        price={3}
        priceNote="$ per month"
        description="Get our full service as a king!"
        hint="cancel any time"
      >
        <Gap size="24px" />
        <Button primary label="Choose this plan" />
      </PricePlan>
    </Align>
  )
}