var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import { Fit, Box } from 'themeor';
import { Spot } from './spot';
export var Background = function (_a) {
    var children = _a.children, _b = _a.fill, fill = _b === void 0 ? 'faintDown' : _b, rest = __rest(_a, ["children", "fill"]);
    var spots = useMemo(function () { return (React.createElement(Fit, { clip: true, cover: "parent" },
        React.createElement(Spot, { reset: true }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }),
        React.createElement(Spot, { reset: false }))); }, []);
    return (React.createElement(Fit.TryTagless, __assign({}, rest),
        React.createElement(Box, { fill: fill },
            spots,
            React.createElement(Fit, null, children))));
};
