var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from '../utils/class-names';
// import * as console from '../utils/console'
import { GAP_NAME } from './types';
import { getConfig } from '../utils/get-config';
import { useTheme } from '../context';
import { Common } from '../Common';
import { withTagless } from '../with-tagless';
import { useCss } from './styles';
var Gap = function (_a, ref) {
    var className = _a.className, size = _a.size, top = _a.top, vert = _a.vert, hor = _a.hor, right = _a.right, bottom = _a.bottom, left = _a.left, children = _a.children, inline = _a.inline, _b = _a.style, style = _b === void 0 ? {} : _b, restProps = __rest(_a, ["className", "size", "top", "vert", "hor", "right", "bottom", "left", "children", "inline", "style"]);
    var _c = getConfig(useTheme().normalizedConfig), gapConfig = _c.gapConfig, customGapValue = _c.customGapValue;
    var css = useCss();
    var newStyle = __assign({}, style);
    var defaultSize = 'default';
    var isDefined = !!(vert || hor || right || left || top || bottom);
    if (size && customGapValue({ size: size })) {
        newStyle.padding = children ? size : size + " 0 0 " + size;
    }
    if (vert && customGapValue({ size: vert })) {
        newStyle.paddingTop = vert;
        newStyle.paddingBottom = vert;
    }
    if (hor && customGapValue({ size: hor })) {
        newStyle.paddingRight = hor;
        newStyle.paddingLeft = hor;
    }
    if (top && customGapValue({ size: top })) {
        newStyle.paddingTop = top;
    }
    if (right && customGapValue({ size: right })) {
        newStyle.paddingRight = right;
    }
    if (bottom && customGapValue({ size: bottom })) {
        newStyle.paddingBottom = bottom;
    }
    if (left && customGapValue({ size: left })) {
        newStyle.paddingLeft = left;
    }
    if (!children && inline === true && !isDefined) {
        newStyle.paddingTop = 0;
    }
    if (!children && inline === false && !isDefined) {
        newStyle.paddingLeft = 0;
    }
    var componentProps = __assign(__assign({}, restProps), { className: cn(css["gap"], inline && css["inline"], !children && css["empty"], (gapConfig({ size: (size) }) || !isDefined)
            && (children
                ? css["size-" + (size || defaultSize)]
                : css["top-" + (size || defaultSize)] + " " + css["left-" + (size || defaultSize)]), gapConfig({ size: vert }) && css["vert-" + vert], gapConfig({ size: hor }) && css["hor-" + hor], gapConfig({ size: top }) && css["top-" + top], gapConfig({ size: right }) && css["right-" + right], gapConfig({ size: bottom }) && css["bottom-" + bottom], gapConfig({ size: left }) && css["left-" + left], className), style: newStyle, children: children });
    return Common(componentProps, GAP_NAME);
};
Gap.displayName = GAP_NAME;
export default withTagless(React.forwardRef(Gap));
