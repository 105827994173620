export function parseFieldName(name) {
    var result = [];
    name.split('.').forEach(function (object) {
        if (object.includes('[')) {
            var arrays_1 = object.split('[').map(function (array) { return array.replace(']', ''); });
            arrays_1.forEach(function (array, index) {
                if (index === 0) {
                    result.push(['array', array]);
                    return;
                }
                if (index === arrays_1.length - 1) {
                    result.push(['object', array]);
                    return;
                }
                result.push(['array', array]);
            });
        }
        else {
            result.push(['object', object]);
        }
    });
    return result;
}
