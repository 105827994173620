var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PathContext, usePath } from './context';
import { setMatched, matched } from './router';
import { parseQuery } from './utils';
import { config } from './config';
export var Path = function (props) {
    var _a, _b, _c;
    var name = props.name, component = props.component, forceRender = props.forceRender, parent = props.parent, propsParams = __rest(props, ["name", "component", "forceRender", "parent"]);
    var context = usePath();
    var calledParams = history.length ? ((context === null || context === void 0 ? void 0 : context.history.slice(-1)[0][1]) || {}) : {};
    var queryParams = (context === null || context === void 0 ? void 0 : context.path.includes('?'))
        ? parseQuery(context === null || context === void 0 ? void 0 : context.path.split('?')[1]) || {}
        : {};
    var nameParams = {};
    var normilizedName = [];
    var splitName = ((_a = name === null || name === void 0 ? void 0 : name.replace(/^\//, '').replace(/\/$/, '')) === null || _a === void 0 ? void 0 : _a.split(config.stackSeparator)) || [];
    var splitPath = ((_c = (_b = context === null || context === void 0 ? void 0 : context.path) === null || _b === void 0 ? void 0 : _b.replace(/^\//, '').replace(/\/$/, '')) === null || _c === void 0 ? void 0 : _c.split(config.stackSeparator)) || [];
    // Raplace variables in name
    for (var index in splitName) {
        var namePart = splitName[index];
        var pathPart = splitPath[index];
        if ((namePart === null || namePart === void 0 ? void 0 : namePart[0]) === ':') {
            var varName = namePart.slice(1);
            nameParams[varName] = pathPart;
            normilizedName.push(pathPart || namePart);
        }
        else {
            normilizedName.push(namePart);
        }
    }
    var fullName = normilizedName.join(config.stackSeparator);
    var hasToRender = parent
        ? fullName === (context === null || context === void 0 ? void 0 : context.cleanPath) || (context === null || context === void 0 ? void 0 : context.cleanPath.indexOf(fullName + config.stackSeparator)) === 0
        : fullName === (context === null || context === void 0 ? void 0 : context.cleanPath);
    var allParams = __assign(__assign(__assign(__assign({}, calledParams), queryParams), nameParams), propsParams);
    var Component = component;
    var render = (_jsx(PathContext.Provider, __assign({ value: __assign(__assign({}, context), { params: allParams, queryParams: queryParams, calledParams: calledParams, nameParams: nameParams, hasToRender: hasToRender, propsParams: propsParams }) }, { children: _jsx(Component, __assign({}, allParams), void 0) }), void 0));
    if (hasToRender) {
        setMatched();
        return render;
    }
    // 404 page
    if (!matched && !name) {
        return render;
    }
    if (forceRender) {
        return render;
    }
    return null;
};
