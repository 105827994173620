var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import hotkey from 'hotkeys-js';
import { Tag } from '../tag';
export var Hotkey = function (_a) {
    var scope = _a.scope, trigger = _a.trigger, action = _a.action, _b = _a.active, active = _b === void 0 ? true : _b, children = _a.children, _c = _a.preventDefault, preventDefault = _c === void 0 ? false : _c, props = __rest(_a, ["scope", "trigger", "action", "active", "children", "preventDefault"]);
    var options = trigger.split(',');
    var mainKeys = options[0].split('+');
    useEffect(function () {
        hotkey.filter = function (event) {
            return true;
        };
    }, []);
    useEffect(function () {
        bind();
    }, [active, scope]);
    function bind() {
        if (action instanceof Function) {
            if (scope) {
                hotkey.setScope(scope);
                hotkey(trigger, scope, finalAction);
            }
            else {
                hotkey.setScope('all');
                hotkey(trigger, finalAction);
            }
        }
    }
    function finalAction(event) {
        preventDefault && event.preventDefault();
        action(event);
    }
    function unbind() {
        hotkey.unbind(trigger, scope);
        hotkey.setScope('all');
    }
    useEffect(function () { return function () { return unbind(); }; }, []);
    if (children) {
        return children;
    }
    var value = mainKeys.join(' + ');
    return React.createElement(Tag, __assign({ label: value }, props));
};
export { hotkey };
