function getMessage(text, source) {
    var component = '';
    if (typeof source === 'function') {
        component = "Component \u2014 <" + source.name + " />";
    }
    else if (typeof source === 'object' && source.constructor) {
        component = "Component \u2014 <" + source.constructor.name + " />";
    }
    else if (typeof source === 'string') {
        component = "Component \u2014 " + source;
    }
    var message = "From 'Themeor' (http://themeor.opium.pro)\n\n" + component + "\n\n" + text + "\n";
    return message;
}
export function error(text, source) {
    var message = getMessage(text, source);
    console.error(message);
}
export function warn(text, source) {
    var message = getMessage(text, source);
    console.warn(message);
}
export function info(text, source) {
    var message = getMessage(text, source);
    console.info(message);
}
