var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var externalReducers = {};
export var externalMiddleware = [];
export var withDevTools = true;
export function useDevTools(value) {
    withDevTools = value;
}
export function addReducers(reducers) {
    externalReducers = __assign(__assign({}, externalReducers), { reducers: reducers });
}
export function addMiddleware(middleware) {
    if (!Array.isArray(middleware)) {
        middleware = [middleware];
    }
    externalMiddleware = middleware;
}
