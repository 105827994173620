import { createAction, useStorage } from 'master-hook'
import { connections } from '../../config'
import * as api from '../../api'



export const connect = createAction(() => {
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) { return }
    js = d.createElement(s)
    js.id = id
    js.src = connections.facebook.src
    fjs.parentNode.insertBefore(js, fjs)
  }(document, 'script', connections.facebook.tagId))
  // @ts-ignore
  window.fbAsyncInit = function () {
    // @ts-ignore
    FB.init(connections.facebook.init)
    // @ts-ignore
    FB.AppEvents.logPageView()
  }
}, { setIsPendingTo: ['app'] })


export const loadPosts = createAction(async () => {
  const { setPosts, setLastScan } = useStorage('facebook')
  await api.facebook.start_checking()

  return new Promise(async (res) => {
    const interval = setInterval(async () => {
      const posts = await api.facebook.check_status()
      if (posts.status !== 'PROCESSED') {
        clearInterval(interval)
        setPosts(posts.data)
        setLastScan(posts.created_at)
        res(posts.data)
      }
    }, 3000)
  })
}, { setIsPendingTo: ['facebook'] })


export const login = createAction(async () => {
  // @ts-ignore
  await FB.login(function (response) {
    if (response.status === 'connected') {
      const { setAccessToken: setFbAccessToken, setUserID, setName, setAvatar } = useStorage('facebook')
      const { setAccessToken, setRefreshToken, setLoggedIn } = useStorage('app')
      const userID = response.authResponse?.userID
      setFbAccessToken(response.authResponse?.accessToken)
      setUserID(userID)
      setTimeout(() => {
        setFbAccessToken()
      }, response.authResponse?.expiresIn)

      api.facebook.login().then(({ access_token, refresh_token }) => {
        setAccessToken(access_token)
        setRefreshToken(refresh_token)
        setLoggedIn(true)

        // @ts-ignore
        FB.api( `/${userID}`, 'GET', {}, (response) => {
            setName(response.name)
          }
        )

        // @ts-ignore
        FB.api( `/${userID}/picture?redirect=0`, 'GET', {}, (response) => {
          setAvatar(response.data?.url)
        }
      )
      })
    } else {
      // The person is not logged into your webpage or we are unable to tell. 
    }
  }, { scope: 'email,user_link,user_posts' })

}, { setIsPendingTo: ['app'] })


export const checkLogin = createAction(() => {
  // @ts-ignore
  FB.getLoginStatus(function (response) {
    console.log(response)
  })
})