var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Fit, Gap, Align } from 'themeor';
import { useScreenFit } from '../screen-fit';
export function LimitWidth(_a) {
    var children = _a.children, gutter = _a.gutter, _b = _a.align, align = _b === void 0 ? "center" : _b, scroll = _a.scroll, rest = __rest(_a, ["children", "gutter", "align", "scroll"]);
    var screen = useScreenFit();
    var limit = typeof (screen === null || screen === void 0 ? void 0 : screen.limit) === 'number' ? (screen === null || screen === void 0 ? void 0 : screen.limit) + 'px' : screen === null || screen === void 0 ? void 0 : screen.limit;
    if (!gutter) {
        gutter = screen === null || screen === void 0 ? void 0 : screen.gutter;
    }
    return (React.createElement(Align.TryTagless, __assign({ hor: align, minWidth: limit, maxWidth: scroll ? '100%' : limit, style: align === 'center' && { margin: "0 auto" } }, rest),
        React.createElement(Fit, { scroll: scroll },
            React.createElement(Gap, { width: "100%", hor: gutter }, children))));
}
