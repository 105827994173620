var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Fit, Box, Reaction } from 'themeor';
import { withTooltip } from '../tooltip';
export var MakeButton = withTooltip(function (_a) {
    var children = _a.children, _b = _a.offset, offset = _b === void 0 ? "10px" : _b, disabled = _a.disabled, _c = _a.radius, radius = _c === void 0 ? "sm" : _c, _d = _a.type, type = _d === void 0 ? "button" : _d, fade = _a.fade, forwardRef = _a.forwardRef, _e = _a.fillHover, fillHover = _e === void 0 ? "hovereffect" : _e, _f = _a.fillActive, fillActive = _f === void 0 ? "faint" : _f, href = _a.href, blank = _a.blank, _g = _a.tabIndex, tabIndex = _g === void 0 ? 0 : _g, onClick = _a.onClick, rest = __rest(_a, ["children", "offset", "disabled", "radius", "type", "fade", "forwardRef", "fillHover", "fillActive", "href", "blank", "tabIndex", "onClick"]);
    var Tag = href ? 'a' : 'button';
    function handleClick(e) {
        if (onClick && href) {
            e.preventDefault();
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    }
    return (React.createElement(Reaction, __assign({}, rest, { onClick: handleClick, disabled: disabled, button: true }), function (rProps, r) { return (React.createElement(Fit.TryTagless, __assign({}, rProps, { style: {
            margin: "-" + offset.split(' ').join(' -'),
            padding: offset,
        }, forwardRef: forwardRef }),
        React.createElement(Tag, { tabIndex: tabIndex, href: href, rel: href && blank && "nofollow", target: href && blank && "_blank", type: (!href ? type : undefined) },
            !disabled && (React.createElement(Fit.TryTagless, { width: r.hoverOrFocus ? "100%" : (!fade ? "0" : undefined), height: r.hoverOrFocus ? "100%" : (!fade ? "0" : undefined), stick: "top-left", top: r.hoverOrFocus ? "0" : (!fade ? "50%" : undefined), left: r.hoverOrFocus ? "0" : (!fade ? "50%" : undefined) },
                React.createElement(Box, { radius: r.hoverOrFocus ? radius : 'max', fill: r.active ? fillActive : fillHover, style: {
                        transition: "all 0.2s ease",
                        opacity: fade ? (r.hoverOrFocus ? "1" : "0") : undefined,
                    } }))),
            typeof children === 'function' ? (children(r)) : (children)))); }));
});
MakeButton.displayName = 'MakeButton';
MakeButton.description = 'Converts anything into a button or a link with animated hover and expanded clickable aria';
MakeButton.demoProps = {
    Props: {
        _extends: ['withTooltip'],
        offset: ['string', undefined, '10px'],
        radius: ['string', undefined, 'sm'],
        fillHover: ['string', undefined],
        fillActive: ['string', undefined],
        type: ['string', undefined, 'button'],
        href: ['string', undefined, "If filled, then <a> tag will be used instead of <button>"],
        blank: ['boolean', false],
        disabled: ['boolean', false],
        fade: ['boolean', false],
    }
};
MakeButton.usage = "<MakeButton{Props}>\n  Clickable thing\n</MakeButton>";
