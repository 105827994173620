import React, { useState } from 'react';
import { AppLayoutContext } from './context';
import { ScreenFit } from '../screen-fit';
import { PortalsProvider } from '../portal';
import { AppTheme } from '../app-theme';
export var Provider = function (_a) {
    var children = _a.children;
    var _b = useState(), contentNode = _b[0], setContentNode = _b[1];
    var _c = useState(), menuNode = _c[0], setMenuNode = _c[1];
    var context = { contentNode: contentNode, setContentNode: setContentNode, menuNode: menuNode, setMenuNode: setMenuNode };
    return (React.createElement(ScreenFit, null,
        React.createElement(AppTheme, null,
            React.createElement(PortalsProvider, null,
                React.createElement(AppLayoutContext.Provider, { value: context }, children)))));
};
