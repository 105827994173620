var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Box, Font, Gap, Icon } from 'themeor';
import { MakeButton } from '../make-button';
import { withTooltip } from '../tooltip';
export var Item = withTooltip(function (_a) {
    var label = _a.label, disabled = _a.disabled, _b = _a.fontSize, fontSize = _b === void 0 ? 'x2s' : _b, fill = _a.fill, forwardRef = _a.forwardRef, img = _a.img, active = _a.active, icon = _a.icon, hint = _a.hint, row = _a.row, children = _a.children, rest = __rest(_a, ["label", "disabled", "fontSize", "fill", "forwardRef", "img", "active", "icon", "hint", "row", "children"]);
    return (React.createElement(MakeButton, __assign({ forwardRef: forwardRef, offset: "0", radius: "none", disabled: disabled }, rest),
        React.createElement(Gap.TryTagless, { hor: row ? "lg" : "sm", vert: "md", right: row && "x2l" },
            React.createElement(Align, { row: row, vert: "center" },
                (icon || img) && (React.createElement(React.Fragment, null,
                    React.createElement(Align, { hor: "center" },
                        icon &&
                            React.createElement(Icon, { name: icon, fill: fill || (active ? "accent" : disabled ? "faintDown" : "baseDown") }),
                        img && React.createElement(React.Fragment, null,
                            React.createElement(Box, { width: "64px", height: "64px", img: img }),
                            React.createElement(Gap, { size: "xs" }))),
                    React.createElement(Gap, { size: row ? "md" : "x2s" }))),
                React.createElement(Font, { align: row ? "left" : "center", size: fontSize, transition: true, noselect: true, weight: active ? '600' : '500', fill: fill || (active ? "accent" : disabled ? "faintDown" : "baseDown") },
                    label,
                    hint && (React.createElement(React.Fragment, null,
                        React.createElement(Gap, { size: "x2s" }),
                        React.createElement(Font, { align: row ? "left" : "center", size: "x3s", noselect: true, weight: "400", fill: "faintDown" }, hint)))),
                children))));
}, { place: 'right' });
