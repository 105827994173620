var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useBox, BoxContext } from './context';
import { useTheme } from '../context';
import cn from '../utils/class-names';
import { Line } from '../Line';
import { BOX_NAME } from './types';
import { getConfig } from '../utils/get-config';
import { Common } from '../Common';
import { withTagless } from '../with-tagless';
import { useCss } from './styles';
var Box = function (props, ref) {
    var _a;
    var className = props.className, _b = props.fill, fill = _b === void 0 ? "default" : _b, borderFill = props.borderFill, borderWeight = props.borderWeight, borderRight = props.borderRight, borderLeft = props.borderLeft, borderTop = props.borderTop, borderBottom = props.borderBottom, inverse = props.inverse, radius = props.radius, radiusTop = props.radiusTop, radiusRight = props.radiusRight, radiusLeft = props.radiusLeft, radiusBottom = props.radiusBottom, radiusTopLeft = props.radiusTopLeft, radiusTopRight = props.radiusTopRight, radiusBottomRight = props.radiusBottomRight, radiusBottomLeft = props.radiusBottomLeft, fancy = props.fancy, shadow = props.shadow, shadowInner = props.shadowInner, blur = props.blur, glow = props.glow, img = props.img, noContext = props.noContext, _c = props.style, style = _c === void 0 ? {} : _c, restProps = __rest(props, ["className", "fill", "borderFill", "borderWeight", "borderRight", "borderLeft", "borderTop", "borderBottom", "inverse", "radius", "radiusTop", "radiusRight", "radiusLeft", "radiusBottom", "radiusTopLeft", "radiusTopRight", "radiusBottomRight", "radiusBottomLeft", "fancy", "shadow", "shadowInner", "blur", "glow", "img", "noContext", "style"]);
    var context = useBox();
    var normalizedConfig = useTheme().normalizedConfig;
    var _d = getConfig(normalizedConfig), boxConfig = _d.boxConfig, customBoxValue = _d.customBoxValue;
    var newStyle = __assign({}, style);
    var css = useCss();
    // Setting inline styles
    if (img) {
        newStyle.backgroundImage = "url('" + img + "')";
    }
    if (fill && !boxConfig({ fill: fill })) {
        if (fancy) {
            newStyle.backgroundImage = fill;
        }
        else {
            newStyle.backgroundColor = fill;
        }
    }
    if (customBoxValue({ radius: radius })) {
        newStyle.borderRadius = radius || undefined;
    }
    if (customBoxValue({ radiusTop: radiusTop })) {
        newStyle.borderTopLeftRadius = radiusTop || undefined;
        newStyle.borderTopRightRadius = radiusTop || undefined;
    }
    if (customBoxValue({ radius: radiusRight })) {
        newStyle.borderTopRightRadius = radiusRight || undefined;
        newStyle.borderBottomRightRadius = radiusRight || undefined;
    }
    if (customBoxValue({ radius: radiusLeft })) {
        newStyle.borderTopLeftRadius = radiusLeft || undefined;
        newStyle.borderBottomLeftRadius = radiusLeft || undefined;
    }
    if (customBoxValue({ radius: radiusBottom })) {
        newStyle.borderBottomLeftRadius = radiusBottom || undefined;
        newStyle.borderBottomRightRadius = radiusBottom || undefined;
    }
    if (customBoxValue({ radius: radiusTopLeft })) {
        newStyle.borderTopLeftRadius = radiusTopLeft || undefined;
    }
    if (customBoxValue({ radius: radiusTopRight })) {
        newStyle.borderTopRightRadius = radiusTopRight || undefined;
    }
    if (customBoxValue({ radius: radiusBottomLeft })) {
        newStyle.borderBottomLeftRadius = radiusBottomLeft || undefined;
    }
    if (customBoxValue({ radius: radiusBottomRight })) {
        newStyle.borderBottomRightRadius = radiusBottomRight || undefined;
    }
    if (customBoxValue({ shadow: shadow })) {
        newStyle.boxShadow = shadow || undefined;
    }
    if (customBoxValue({ shadowInner: shadowInner })) {
        newStyle.boxShadow = 'inset ' + shadowInner;
    }
    if (customBoxValue({ blur: blur })) {
        newStyle.backdropFilter = "blur(" + blur + ")";
    }
    if (customBoxValue({ glow: glow })) {
        newStyle.boxShadow = glow || undefined;
    }
    // Setting classNames
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { className: cn(css['box'], img && css['img'], boxConfig({ fill: fill }) && css["fill-" + fill], inverse && boxConfig({ fillInverse: fill }) && css["fill-inverse-" + fill], fancy && boxConfig({ fillFancy: fill }) && css["fill-fancy-" + fill], boxConfig({ shadow: shadow }) && css["shadow-" + shadow], boxConfig({ blur: blur }) && css["blur-" + blur], boxConfig({ shadowInner: shadowInner }) && css["shadow-inner-" + shadowInner], boxConfig({ glow: glow }) && css["glow-" + glow], boxConfig({ radius: radius }) && css["radius-" + radius], boxConfig({ radius: radiusTop }) && css["radius-top-" + radiusTop], boxConfig({ radius: radiusRight }) && css["radius-right-" + radiusRight], boxConfig({ radius: radiusLeft }) && css["radius-left-" + radiusLeft], boxConfig({ radius: radiusBottom }) && css["radius-bottom-" + radiusBottom], boxConfig({ radius: radiusTopLeft }) && css["radius-tl-" + radiusTopLeft], boxConfig({ radius: radiusTopRight }) && css["radius-tr-" + radiusTopRight], boxConfig({ radius: radiusBottomLeft }) && css["radius-bl-" + radiusBottomLeft], boxConfig({ radius: radiusBottomRight }) && css["radius-br-" + radiusBottomRight], className), style: newStyle });
    var renderBoxComponent = Common(componentProps, BOX_NAME);
    var hasBorder = borderFill || borderWeight;
    if (hasBorder) {
        var borderFill_1 = props.borderFill, borderWeight_1 = props.borderWeight;
        renderBoxComponent = (React.createElement(Line.TryTagless, { fill: borderFill_1, weight: borderWeight_1, left: borderLeft, right: borderRight, top: borderTop, bottom: borderBottom }, Common(componentProps, BOX_NAME)));
    }
    if (noContext || !(normalizedConfig === null || normalizedConfig === void 0 ? void 0 : normalizedConfig.shallInverseOn)) {
        return renderBoxComponent;
    }
    // Automatically inverse text and other stuff on this background
    var inverseStatus;
    inverseStatus = inverse || (!!fill && ((_a = normalizedConfig === null || normalizedConfig === void 0 ? void 0 : normalizedConfig.shallInverseOn) === null || _a === void 0 ? void 0 : _a.includes(fill)));
    if (!fill || fill === 'none') {
        inverseStatus = context.TRY_TO_INVERSE;
    }
    var newContext = __assign(__assign({}, context), { TRY_TO_INVERSE: inverseStatus });
    return (React.createElement(BoxContext.Provider, { value: newContext }, renderBoxComponent));
};
Box.displayName = BOX_NAME;
export default withTagless(React.forwardRef(Box));
