import { Wrapper } from './wrapper';
import { Item } from './item';
import { icons } from '../../theme/iconList';
export var TopMenu = Wrapper;
TopMenu.Item = Item;
TopMenu.Item.displayName = "TopMenu.Item";
TopMenu.displayName = "TopMenu";
TopMenu.description = "Better place this menu at the very top of the page";
TopMenu.demoProps = {
    'Item Props': {
        _extends: ['MakeButton'],
        _indent: '  ',
        label: ['string', 'Demo Item'],
        icon: ['select', undefined, icons],
        fill: ['string'],
        active: ['boolean'],
    }
};
TopMenu.usage = "<TopMenu>\n  <TopMenu.Item{Item Props}/>\n  <TopMenu.Item label=\"Second Item\" />\n  <TopMenu.Item label=\"Third Item\" />\n</TopMenu>\n";
