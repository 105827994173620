import { Align, Font, Gap, Box, Fit } from 'themeor'
import { Background, LimitWidth, Button, useScreenFit, Page } from 'opium-ui'
import ImgBox from '../components/img-box'
import { nav } from 'opium-nav'
import Footer from '../blocks/footer'
import { networks, urls } from '../config'
import { useFacebook } from '../store'
import { useEffect } from 'react'



export default function () {
    const { loggedIn, login } = useFacebook(['loggedIn'])
    const { isSmall } = useScreenFit()

    useEffect(() => {
        if (loggedIn) {
            nav.replace(urls.facebook)
        }
    }, [])

    return (
        <Page title="Cram*la">
            <Background>
                <Align width="100%" minHeight="100vh" vert="center" hor={isSmall ? undefined : "center"}>
                    <LimitWidth align={isSmall ? "stretch" : undefined}>
                        <Gap size="60px" />
                        <Align width="100%">
                            <Font size={isSmall ? "50px" : "80px"} weight="900" align="center" letterSpacing="0.2em">
                                cram*la
                            </Font>
                            <Font size="lg" align="center">
                                Clean your social networks from the posts<br />
                                that may tint your reputation
                            </Font>
                        </Align>
                        <Gap size="60px" />

                        <Align
                            vert="stretch"
                            pattern={isSmall ? "1fr" : "1fr 1fr 1fr"}
                            gapHor="32px"
                            gapVert="40px"
                            width="100%"
                        >
                            <ImgBox
                                counter={1}
                                img="/img/smile-love.png"
                                title="Connect your account"
                            />
                            <ImgBox
                                counter={2}
                                img="/img/smile-wink.png"
                                title="Find all harmful posts"
                            />
                            <ImgBox
                                counter={3}
                                img="/img/smile-angry.png"
                                title="Delete those m*ther f*ckers!"
                            />
                        </Align>

                        <Gap size="80px" />

                        <Align
                            width="100%"
                            vert="stretch"
                            hor="stretch"
                            pattern={isSmall ? "100%" : "1fr 1fr 1fr 1fr"}
                            gapHor="20px"
                            gapVert="20px"
                        >
                            <Button
                                height="80px"
                                primary
                                fill={networks['facebook'].fill}
                                fillHover={networks['facebook'].hover}
                                icon={networks['facebook'].icon}
                                label="Connect Facebook"
                                onClick={login}
                                inverse
                            />
                            <Button
                                height="80px"
                                primary
                                // fill={networks['twitter'].fill}
                                fillHover={networks['twitter'].hover}
                                icon={networks['twitter'].icon}
                                label="Connect Twitter"
                                hint="Coming soon"
                                disabled
                            // inverse
                            />
                            <Button
                                height="80px"
                                primary
                                // fill={networks['instagram'].fill}
                                fillHover={networks['instagram'].hover}
                                icon={networks['instagram'].icon}
                                label="Connect Instagram"
                                hint="Coming soon"
                                disabled
                            // inverse
                            />
                            <Button
                                height="80px"
                                primary
                                // fill={networks['linkedin'].fill}
                                fillHover={networks['linkedin'].hover}
                                icon={networks['linkedin'].icon}
                                label="Connect Linkedin"
                                hint="Coming soon"
                                disabled
                                inverse
                            />
                        </Align>
                    </LimitWidth>

                    <Footer />
                </Align>
            </Background>
        </Page>
    )
}