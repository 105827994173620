import { fetch, Responce, Headers } from './fetch'
import { Networks } from '../config/networks'



export const login = (body: { provider: Networks }):
  Responce<{ access_token: string, refresh_token: string }> =>
  fetch('/auth/login/', 'POST', body)


export const refresh = (body: { refresh }):
  Responce<{ refresh: string, access: string }> =>
  fetch('/auth/refresh/', 'POST', body)