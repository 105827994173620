var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { TextInput, useTextInput } from "../text-input";
import { Icon, Gap, Align, Fit, useReaction } from 'themeor';
import { Dropdown } from '../dropdown';
import { SelectContext } from './context';
import { Tag } from '../tag';
import { isDefined } from '../../utils';
export var Select = function (_a) {
    var children = _a.children, onChange = _a.onChange, disabled = _a.disabled, _b = _a.multi, multi = _b === void 0 ? false : _b, onDisplayValue = _a.onDisplayValue, onCompare = _a.onCompare, rest = __rest(_a, ["children", "onChange", "disabled", "multi", "onDisplayValue", "onCompare"]);
    var _c = useState(new Map()), displayValues = _c[0], setDisplayValues = _c[1];
    var _d = useState(new Map(children)), newChildren = _d[0], setNewChildren = _d[1];
    // const [tagsNode, setTagsNode]: any = useState()
    // const [notFitNode, setNotFitNode]: any = useState()
    var handleDisplayValue = function (value) {
        var _a;
        if (Array.isArray(value)) {
            var newValue_1 = [];
            for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                var val = value_1[_i];
                displayValues.has(val) && newValue_1.push(displayValues.get(val));
            }
            if (onDisplayValue) {
                return onDisplayValue(newValue_1);
            }
            return (React.createElement(Fit.TryTagless, { cover: "parent", maxWidth: "100%", clip: true },
                React.createElement(Align, { row: true, stretch: true, gapHor: "4px" }, (_a = newValue_1 === null || newValue_1 === void 0 ? void 0 : newValue_1.map) === null || _a === void 0 ? void 0 : _a.call(newValue_1, function (val, i) { return val &&
                    React.createElement(Tag, { nowrap: true, key: i, label: val, fill: "base", weight: "600" }); }))));
        }
        var newValue = displayValues.get(value);
        return (onDisplayValue === null || onDisplayValue === void 0 ? void 0 : onDisplayValue(newValue)) || newValue;
    };
    useEffect(function () {
        var newValues = new Map();
        var newChildren = new Map();
        React.Children.map(children, function (child) {
            var _a = child.props, value = _a.value, children = _a.children, displayValue = _a.displayValue, label = _a.label;
            newValues.set(value, displayValue || label || children || value);
            newChildren.set(child, displayValue || label || value);
        });
        if (!displayValues.size) {
            setDisplayValues(newValues);
        }
        setNewChildren(newChildren);
    }, [children]);
    // Вставляем счетчик, если что-то не поместилось
    // useEffect(() => {
    //   if (!tagsNode || !notFitNode) { return }
    //   const tags = tagsNode.children
    //   const parentWidth = tagsNode.offsetWidth
    //   let tagsWidth = 0
    //   const fit = []
    //   let notFit = 0
    //   for (let i = 0; i < tags.length; i++) {
    //     tagsWidth += tags[i].offsetWidth
    //     if (tagsWidth + 60 > parentWidth) {
    //       notFit++
    //     } else {
    //       fit.push(tags[i])
    //     }
    //   }
    //   if (notFit) {
    //     tagsNode.innerHtml = ''
    //     console.log(fit.length);
    //     for (let i; i < fit.length; i++) {
    //       tagsNode.appendChild(fit[i])
    //     }
    //     notFitNode.innerText = `+${notFit}`
    //     tagsNode.appendChild(notFitNode)
    //   } else {
    //     notFitNode.innerText = ''
    //   }
    // })
    // function handleFocus() {
    //   hotkey.setScope('select')
    //   hotkey('space', (e) => {
    //     e.preventDefault()
    //   })
    // }
    // function handleBlur() {
    //   console.log('BLUR');
    //   hotkey.deleteScope('select')
    // }
    return (React.createElement(SelectContext.Provider, { value: {
            onCompare: onCompare,
            multi: multi,
        } },
        React.createElement(TextInput, __assign({}, rest, { disabled: disabled, onDisplayValue: handleDisplayValue, insertRight: !disabled && React.createElement(SelectIcon, null), options: newChildren, type: "select" }))));
};
function SelectIcon() {
    var opened = Dropdown.use().opened;
    var hoverOrFocus = useReaction().hoverOrFocus;
    var value = useTextInput().value;
    if (isDefined(value) && hoverOrFocus) {
        return null;
    }
    return (React.createElement(Gap, { size: "12px", rotate: opened && '180deg' },
        React.createElement(Icon, { name: "chevron-down" })));
}
