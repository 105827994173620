import { Item } from './item';
import { Dropdown as MakeDropdown } from './dropdown';
import { DropdownContext, useDropdown } from './context';
export var Dropdown = MakeDropdown;
Dropdown.Item = Item;
Dropdown.Context = DropdownContext;
Dropdown.use = useDropdown;
Dropdown.displayName = 'Dropdown';
Dropdown.description = 'Convert anything into a dropdown';
Dropdown.demoProps = {
    Props: {
        opened: ['boolean', undefined],
        withSearch: ['boolean', undefined],
        disabled: ['boolean', undefined],
    },
    'Item Props': {
        _extends: ['MakeButton'],
        _indent: '  ',
        label: ['string', 'Item One'],
        disabled: ['boolean', undefined],
        active: ['boolean', undefined],
    },
};
Dropdown.usage = "<Dropdown{Props}\n  element={<Button primary label=\"Demo dropdown, click me!\" />}\n>\n  <Dropdown.Item{Item Props}/>\n  <Dropdown.Item label=\"Item Two\" />\n  <Dropdown.Item label=\"Item Three\" />\n</Dropdown>\n";
