var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import { Wrapper } from './wrapper';
import { DropdownContext } from './context';
export var Dropdown = function (_a) {
    var initialOpened = _a.opened, element = _a.element, withSearch = _a.withSearch, onClick = _a.onClick, children = _a.children, disabled = _a.disabled, rest = __rest(_a, ["opened", "element", "withSearch", "onClick", "children", "disabled"]);
    var _b = useState(), dropdownNode = _b[0], setDropdownNode = _b[1];
    var _c = useState(initialOpened), opened = _c[0], setOpened = _c[1];
    var _d = useState(), search = _d[0], setSearch = _d[1];
    function trackOutsideClick(event) {
        if (!(dropdownNode === null || dropdownNode === void 0 ? void 0 : dropdownNode.contains(event.target))) {
            setOpened(false);
        }
    }
    useEffect(function () {
        setOpened(initialOpened);
    }, [initialOpened]);
    useEffect(function () {
        if (dropdownNode) {
            window.addEventListener('click', trackOutsideClick);
            return function () { return window.removeEventListener('click', trackOutsideClick); };
        }
    }, [dropdownNode]);
    function handleClick(event) {
        event.stopPropagation();
        onClick === null || onClick === void 0 ? void 0 : onClick(opened);
        setOpened(!opened);
    }
    if (React.Children.count(element) !== 1) {
        console.error("'MakeDropdown' component from 'opium-ui' can have only one target");
        return null;
    }
    var Target = element.type;
    var props = element.props;
    return (React.createElement(DropdownContext.Provider, { value: {
            opened: opened,
            setOpened: setOpened,
            dropdownNode: dropdownNode,
            search: search,
            setSearch: setSearch,
            withSearch: withSearch,
        } },
        React.createElement(Target, __assign({}, props, { onClick: handleClick })),
        !disabled && children && opened && (React.createElement(Wrapper, __assign({}, rest, { forwardRef: function (n) { return n && setDropdownNode(n); } }), children))));
};
