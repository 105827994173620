import { setIsPending } from './default-actions';
import { actions } from '../collector';
export function createAction(action) {
    var options = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        options[_i - 1] = arguments[_i];
    }
    var setIsPendingTo;
    var canRepeatIn;
    options === null || options === void 0 ? void 0 : options.forEach(function normalizeOptions(option) {
        if (option instanceof Object && !Array.isArray(option)) {
            canRepeatIn = option.canRepeatIn;
            setIsPendingTo = Array.isArray(option.setIsPendingTo) ? option.setIsPendingTo : [option.setIsPendingTo];
        }
        else {
            typeof option === 'number' && (canRepeatIn = option);
            typeof option === 'string' && (setIsPendingTo = [option]);
            Array.isArray(option) && (setIsPendingTo = option);
        }
    });
    actions[action] = { setIsPendingTo: setIsPendingTo, canRepeatIn: canRepeatIn, timestamp: undefined };
    return function __masterHookAction__() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var now = new Date().getTime();
        var canExecute = true;
        var timestamp = actions[action].timestamp;
        if (typeof canRepeatIn === 'number' && timestamp && (canRepeatIn === 0 || timestamp + canRepeatIn > now)) {
            canExecute = false;
        }
        return execute(action, args, setIsPendingTo)(canExecute);
    };
}
export function force(calledAction) {
    if (calledAction.type === '__MASTERHOOK_STOP_EXECUTION__') {
        var action = calledAction.action, args = calledAction.args, setIsPendingTo = calledAction.setIsPendingTo;
        return execute(action, args, setIsPendingTo)(true);
    }
    else {
        return calledAction;
    }
}
function execute(action, args, setIsPendingTo) {
    return function (canExecute) {
        if (!canExecute) {
            return {
                type: '__MASTERHOOK_STOP_EXECUTION__',
                action: action,
                args: args,
                setIsPendingTo: setIsPendingTo,
            };
        }
        var actionResult = action.apply(void 0, args);
        if (actionResult instanceof Promise && setIsPendingTo) {
            setIsPending(true, setIsPendingTo);
            return actionResult.then(function (result) {
                actions[action].timestamp = new Date().getTime();
                return result;
            }).finally(function () {
                setIsPending(false, setIsPendingTo);
            });
        }
        else {
            actions[action].timestamp = new Date().getTime();
            return actionResult;
        }
    };
}
