var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Gap, Box, Align, Fit, useReaction } from 'themeor';
import { Dropdown } from '../dropdown';
import { useTextInput } from './context';
import { Tooltip } from '../tooltip';
import { AutoComplete } from './auto-complete';
import { isDefined } from '../../utils';
import { ActionButton } from '../action-button';
export var Wrapper = function (_a) {
    var children = _a.children;
    var _b = useTextInput(), disabled = _b.disabled, options = _b.options, isSelect = _b.isSelect, height = _b.height, error = _b.error, insertLeft = _b.insertLeft, insertRight = _b.insertRight, tooltip = _b.tooltip, value = _b.value, type = _b.type, onChange = _b.onChange, parentChildren = _b.children;
    var _c = useReaction(), passProps = _c.passProps, focus = _c.focus, hover = _c.hover, hoverOrFocus = _c.hoverOrFocus;
    var context = useTextInput();
    var reaction = useReaction();
    var textarea = type === 'textarea';
    return (React.createElement(Dropdown, { disabled: disabled, element: (React.createElement(Fit.TryTagless, { height: height },
            React.createElement(Box.TryTagless, __assign({ fill: (disabled && "base") || (focus && "base") || (hover && "faint") || "faintDown", radius: "sm", borderFill: (disabled && "faint") || (focus && "base") || (error && 'critic') || "none", style: { transition: "all 0.25s ease" }, tabIndex: disabled ? -1 : 0 }, passProps),
                React.createElement(Align, { row: true, vert: "stretch" },
                    insertLeft && (React.createElement(Align, { row: true, vert: "center" }, insertLeft)),
                    React.createElement(Fit, { clip: true, stretch: true },
                        children,
                        !textarea && !disabled && isDefined(value) && hoverOrFocus && (React.createElement(Fit.TryTagless, { absolute: true, right: "0", height: "100%" },
                            React.createElement(Align.TryTagless, { vert: "center" },
                                React.createElement(Gap, { hor: "16px" },
                                    React.createElement(ActionButton, { icon: "cross", fill: "faintDown", onClick: function () { return onChange(''); } })))))),
                    React.createElement(Align, { row: true, vert: "center" },
                        tooltip && (React.createElement(React.Fragment, null,
                            React.createElement(ActionButton, { cursor: "help", fill: "faintDown", icon: "question-circle" }),
                            React.createElement(Tooltip, { delay: 100 }, tooltip),
                            React.createElement(Gap, { size: "16px" }))),
                        insertRight,
                        parentChildren && parentChildren instanceof Function ? (parentChildren(context, reaction)) : parentChildren))))), withSearch: isSelect }, isSelect ? options : React.createElement(AutoComplete, null)));
};
