import React from 'react';
import { Gap, Align, Font, Fit, useReaction } from 'themeor';
import { useTextInput } from './context';
import { isDefined } from '../../utils';
export var Label = function () {
    var _a = useTextInput(), value = _a.value, label = _a.label, error = _a.error;
    var focus = useReaction().focus;
    var uptop = isDefined(value) || focus;
    return (React.createElement(Fit.TryTagless, { transition: "100ms", absolute: true, top: "0", left: "0", height: uptop ? "30px" : "50px" },
        React.createElement(Align.TryTagless, { vert: "center" },
            React.createElement(Font.TryTagless, { fill: (error && 'critic') || "faintDown", size: uptop ? "x2s" : "xs", align: "left" },
                React.createElement(Gap.TryTagless, { hor: "md" }, label)))));
};
