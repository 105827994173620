var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useStorage } from './storage/use-storage';
import { createStorage } from './storage/create-storage';
import { useAction } from './actions/use-action';
import { useSelector } from './selectors';
export function constructor(_a) {
    var storage = _a.storage, initialState = _a.initialState, actions = _a.actions, selectors = _a.selectors, cache = _a.cache;
    Array.isArray(storage)
        ? storage.forEach(function (name) { return createStorage(name, initialState, cache); })
        : typeof storage === 'object'
            ? Object.keys(storage).forEach(function (name) { return createStorage(name, initialState, cache, storage[name]); })
            : createStorage(storage, initialState, cache);
    var useStorages = function (subscribe) {
        var result = {};
        if (Array.isArray(storage)) {
            storage.forEach(function (name) {
                result[name] = useStorage(name, subscribe);
            });
        }
        else if (typeof storage === 'object') {
            Object.keys(storage).forEach(function (name) {
                result[name] = useStorage(name, subscribe);
            });
        }
        else {
            result = useStorage(storage, subscribe);
        }
        return result;
    };
    var useActions = function () {
        var result = {};
        actions && Object.keys(actions).forEach(function (key) {
            result[key] = useAction(actions[key]);
        });
        return result;
    };
    var useSelectors = function (subscribe) {
        var result = {};
        selectors && Object.keys(selectors).forEach(function (key) {
            if (subscribe === true || (Array.isArray(subscribe) && subscribe.includes(key))) {
                result[key] = useSelector(selectors[key]);
            }
            else {
                result[key] = selectors[key]();
            }
        });
        return result;
    };
    return function (subscribe) {
        if (subscribe === void 0) { subscribe = true; }
        return (__assign(__assign(__assign({}, useStorages(subscribe)), useActions()), useSelectors(subscribe)));
    };
}
