var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTheme } from '../context';
import cn from '../utils/class-names';
import { FONT_NAME } from './types';
import { getConfig } from '../utils/get-config';
import { Common } from '../Common';
import { withTagless } from '../with-tagless';
import { useCss } from './styles';
import { useBox } from '../Box';
var Font = function (_a, ref) {
    var className = _a.className, _b = _a.fill, fill = _b === void 0 ? 'default' : _b, inverse = _a.inverse, fancy = _a.fancy, inline = _a.inline, weight = _a.weight, size = _a.size, uppercase = _a.uppercase, underline = _a.underline, italic = _a.italic, family = _a.family, nowrap = _a.nowrap, wrap = _a.wrap, align = _a.align, _c = _a.style, style = _c === void 0 ? {} : _c, noselect = _a.noselect, lineHeight = _a.lineHeight, letterSpacing = _a.letterSpacing, children = _a.children, restProps = __rest(_a, ["className", "fill", "inverse", "fancy", "inline", "weight", "size", "uppercase", "underline", "italic", "family", "nowrap", "wrap", "align", "style", "noselect", "lineHeight", "letterSpacing", "children"]);
    var context = useBox();
    var fontConfig = getConfig(useTheme().normalizedConfig).fontConfig;
    var css = useCss();
    var newStyle = __assign({}, style);
    if (fill && !fontConfig({ fill: fill })) {
        newStyle.color = fill;
    }
    if (size && !fontConfig({ size: size })) {
        newStyle.fontSize = size;
    }
    if (weight && !fontConfig({ weight: weight })) {
        newStyle.fontWeight = weight;
    }
    if (letterSpacing && !fontConfig({ letterSpacing: letterSpacing })) {
        newStyle.letterSpacing = letterSpacing;
    }
    if (lineHeight && !fontConfig({ lineHeight: lineHeight })) {
        newStyle.lineHeight = lineHeight;
    }
    if (family && !fontConfig({ family: family })) {
        newStyle.fontFamily = family;
    }
    var forceInverse = (inverse !== false) && (inverse || context.TRY_TO_INVERSE);
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { className: cn(css['font'], (underline && css['underline']) || (underline === false && css['nounderline']), inline && css['inline'], inline === false && css['block'], fontConfig({ fill: fill }) && css["fill-" + fill], forceInverse && fontConfig({ fillInverse: fill }) && css["fill-inverse-" + fill], fancy && fontConfig({ fillFancy: fill }) && css["fill-fancy-" + fill], (uppercase && css['uppercase']) || ((uppercase === false) && css['nouppercase']), (italic && css['italic']) || ((italic === false) && css['noitalic']), noselect && css['noselect'], fontConfig({ letterSpacing: letterSpacing }) && css["letter-spacing-" + letterSpacing], fontConfig({ lineHeight: lineHeight }) && css["line-height-" + lineHeight], fontConfig({ size: size }) && css["size-" + size], fontConfig({ weight: weight }) && css["weight-" + weight], fontConfig({ family: family }) && css["family-" + family], align && css["align-" + align], nowrap && css['nowrap'], wrap && css['wrap'], className), style: newStyle, children: children });
    return Common(componentProps, FONT_NAME);
};
Font.displayName = FONT_NAME;
export default withTagless(React.forwardRef(Font));
