// Joining params into string of CSS classes
export default function className() {
    var nameList = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        nameList[_i] = arguments[_i];
    }
    var result = [];
    for (var _a = 0, nameList_1 = nameList; _a < nameList_1.length; _a++) {
        var name_1 = nameList_1[_a];
        if (name_1 && typeof name_1 === 'string') {
            result.push(name_1);
        }
    }
    return result.join(' ');
}
