var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Reaction } from 'themeor';
import { withForm } from '../form';
import { Label } from './label';
import { Placeholder } from './placeholder';
import { Value } from './value';
import { Caption } from './caption';
import { Wrapper } from './wrapper';
import { TextInputContext } from './context';
export var TextInput = withForm(function (_a) {
    var label = _a.label, _b = _a.type, type = _b === void 0 ? "text" : _b, _c = _a.height, height = _c === void 0 ? label ? '50px' : '40px' : _c, _d = _a.autoComplete, autoComplete = _d === void 0 ? ['email'].includes(type) : _d, forwardRef = _a.forwardRef, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, initialValue = _a.initialValue, disabled = _a.disabled, insertRight = _a.insertRight, insertLeft = _a.insertLeft, hint = _a.hint, error = _a.error, onDisplayValue = _a.onDisplayValue, tooltip = _a.tooltip, props = __rest(_a, ["label", "type", "height", "autoComplete", "forwardRef", "onChange", "onFocus", "onBlur", "initialValue", "disabled", "insertRight", "insertLeft", "hint", "error", "onDisplayValue", "tooltip"]);
    var inputNode;
    var isSelect = type === 'select';
    function handleChange(event) {
        var _a;
        var value;
        if (isSelect || !(event instanceof Object)) {
            value = event;
        }
        else {
            value = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value;
        }
        (type === 'number') && value && (value = parseInt(value));
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }
    function handleFocus(event) {
        var _a;
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
        (_a = inputNode === null || inputNode === void 0 ? void 0 : inputNode.focus) === null || _a === void 0 ? void 0 : _a.call(inputNode);
    }
    function handleBlur() {
        var _a;
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(props.value);
        if (!inputNode) {
            return;
        }
        (_a = inputNode === null || inputNode === void 0 ? void 0 : inputNode.blur) === null || _a === void 0 ? void 0 : _a.call(inputNode);
    }
    function handleRef(fRef) {
        if (!fRef) {
            return;
        }
        typeof forwardRef === 'function' && forwardRef(fRef);
        inputNode = fRef;
    }
    var context = __assign(__assign({}, props), { label: label, type: type, height: height, autoComplete: autoComplete, onChange: handleChange, handleRef: handleRef, onFocus: handleFocus, onBlur: handleBlur, inputNode: inputNode, isSelect: isSelect, initialValue: initialValue, disabled: disabled, insertRight: insertRight, insertLeft: insertLeft, hint: hint, onDisplayValue: onDisplayValue, error: error, tooltip: tooltip });
    return (React.createElement(Reaction, __assign({}, props, { track: !disabled && ['focus', 'hover'], cursor: (disabled && 'default') || (isSelect && 'pointer') || 'text', onFocus: !disabled && handleFocus, onBlur: !disabled && handleBlur }),
        React.createElement(TextInputContext.Provider, { value: context },
            React.createElement(Wrapper, null,
                React.createElement(Label, null),
                React.createElement(Value, null),
                React.createElement(Placeholder, null)),
            React.createElement(Caption, null))));
});
