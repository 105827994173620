import { fetch, Responce, Headers } from './fetch'
import { login as originalLogin } from './auth'


export type Post = {
  id: string,
  message: string,
  censored_words?: string[],
  rating?: boolean,
  created_time: Date,
  permalink_url: string,
  comments_count: number,
  reactions_count: number,
}


export const login = ():
  Responce<{ access_token: string, refresh_token: string }> =>
  originalLogin({ provider: 'facebook' })


export const check_status = ():
  Responce<{ status: string, created_at: number, data: Post }> =>
  fetch('/facebook/check_status/')


export const posts = ():
  Responce<Post[]> =>
  fetch('/facebook/posts/')


export const start_checking = ():
  Responce<{ ststus: string }> =>
  fetch('/facebook/start_checking/', 'POST')