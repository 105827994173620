import { getStore } from '../store/get-store';
import { setCachedIfAllowed } from '../local-storage/cached';
import { useSelector } from 'react-redux';
export function useSubscribedMediator(mediator, storageName, subscribe) {
    var hook = useMediator(mediator, storageName);
    var get = mediator.get;
    Object.keys(get).forEach(function (key) {
        var hasInSubscription = subscribe === true || (Array.isArray(subscribe) && subscribe.includes(key));
        if (hasInSubscription) {
            hook[key] = useSelector(function (state) { var _a; return (_a = get[key]) === null || _a === void 0 ? void 0 : _a.call(get, state); });
        }
    });
    return hook;
}
export function useMediator(_a, storageName) {
    var get = _a.get, set = _a.set, actions = _a.actions, defaultActions = _a.defaultActions;
    var store = getStore();
    var dispatch = store === null || store === void 0 ? void 0 : store.dispatch;
    var getState = store === null || store === void 0 ? void 0 : store.getState;
    if (!store) {
        console.error("MasterHook. Store does not exist yet");
        return undefined;
    }
    var handlers = {};
    get && Object.keys(get).forEach(function (key) {
        handlers[key] = get[key](getState());
    });
    set && Object.keys(set).forEach(function (key) {
        var name = "set" + (key.charAt(0).toUpperCase() + key.slice(1));
        handlers[name] = function (value) {
            setCachedIfAllowed(storageName, key, value);
            return dispatch(set[key](value));
        };
    });
    actions && Object.keys(actions).forEach(function (key) {
        handlers[key] = dispatch(function () { return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return actions[key].apply(actions, args);
        }; });
    });
    defaultActions && Object.keys(defaultActions).forEach(function (key) {
        handlers[key] = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return dispatch(defaultActions[key].apply(defaultActions, args));
        };
    });
    return handlers;
}
