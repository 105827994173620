import { MasterHook, useStorage } from 'master-hook'
import * as facebook from './facebook'
import * as app from './app'



export const useFacebook = MasterHook(facebook) as MasterHook<
  typeof facebook.initialState,
  typeof facebook.actions,
  typeof facebook.selectors
>


export const useApp = MasterHook(app) as MasterHook<
  typeof app.initialState,
  typeof app.actions,
  typeof app.selectors
>