import { useEffect } from 'react'
import { Path, nav } from 'opium-nav'
import Intro from './screens/intro'
import Facebook from './screens/facebook'
import Settings from './screens/settings'
import Premium from './screens/premium'
import App from './blocks/app'
import { urls } from './config'
import { useApp } from './store'



export default function () {
    const { loggedIn } = useApp(['loggedIn'])

    useEffect(() => {
        if (loggedIn) {
            nav.replace(urls.facebook)
        } else {
            nav.replace(urls.intro)
        }
    })

    return (
        <App>
            <Path name={urls.intro} component={Intro} />
            <Path name={urls.facebook} component={Facebook} />
            <Path name={urls.settings} component={Settings} />
            <Path name={urls.premium} component={Premium} />
        </App>
    )
}