var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { Box, Fit } from 'themeor';
import { LazyScroller } from '../lazy-scroller';
export var Wrapper = function (_a) {
    var title = _a.title, img = _a.img, link = _a.link, children = _a.children, header = _a.header, rest = __rest(_a, ["title", "img", "link", "children", "header"]);
    var _b = useState(), node = _b[0], setNode = _b[1];
    return (React.createElement(Fit.TryTagless, __assign({ FORCE_TAGLESS: true, scroll: true }, rest),
        React.createElement(Box, { fill: "base", radius: "md" },
            React.createElement(LazyScroller, { Tag: "table", style: { width: '100%' } },
                React.createElement(Fit.TryTagless, { sticky: true, top: "0" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null),
                            React.createElement("th", null),
                            header,
                            React.createElement("th", null)))),
                children))));
};
