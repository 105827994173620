import { createStyleSheet, getClasses } from '../utils/styles';
import { LINE_NAME } from './types';
export var useCss = function () { return getClasses(LINE_NAME); };
export default function (normalizedConfig) {
    var _a = normalizedConfig.line, weight = _a.weight, fill = _a.fill, fillFancy = _a.fillFancy, fillInverse = _a.fillInverse;
    var styles = {};
    styles["line"] = {
        borderStyle: 'solid',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    };
    for (var key in weight) {
        styles["weight-" + key] = { borderWidth: weight[key] };
        styles["weight-top-" + key] = { borderTopWidth: weight[key] };
        styles["weight-right-" + key] = { borderRightWidth: weight[key] };
        styles["weight-bottom-" + key] = { borderBottomWidth: weight[key] };
        styles["weight-left-" + key] = { borderLeftWidth: weight[key] };
        styles["separator-hor-" + key] = {
            borderWidth: 0,
            borderTopWidth: weight[key]
        };
        styles["separator-vert-" + key] = {
            borderWidth: 0,
            borderRightWidth: weight[key]
        };
    }
    // Fills
    for (var key in fill) {
        styles["fill-" + key] = { borderColor: fill[key] };
    }
    for (var key in fillFancy) {
        styles["fill-fancy-" + key] = { borderImage: fillFancy[key] };
    }
    for (var key in fillInverse) {
        styles["fill-inverse-" + key] = { borderColor: fillInverse[key] };
    }
    return createStyleSheet(LINE_NAME, styles);
}
