var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mediators } from "../collector";
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { getReducer } from './get-reducer';
import { withDevTools, externalMiddleware } from './settings';
export var store = undefined;
export function getStore() {
    var _a;
    if (store && Object.keys(mediators).length) {
        return store;
    }
    var reducer = getReducer();
    var devTools = withDevTools && ((_a = window) === null || _a === void 0 ? void 0 : _a.__REDUX_DEVTOOLS_EXTENSION__);
    var middleware = [
        applyMiddleware.apply(void 0, __spreadArray([thunk], externalMiddleware)),
        devTools ? devTools === null || devTools === void 0 ? void 0 : devTools() : function (f) { return f; },
    ];
    store = createStore(reducer, compose.apply(void 0, middleware));
    return store;
}
