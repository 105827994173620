import { createStyleSheet, getClasses } from '../utils/styles';
import { BOX_NAME } from './types';
export var useCss = function () { return getClasses(BOX_NAME); };
export default function (normalizedConfig) {
    var _a = normalizedConfig.box, fill = _a.fill, fillFancy = _a.fillFancy, fillInverse = _a.fillInverse, radius = _a.radius, shadow = _a.shadow, glow = _a.glow, shadowInner = _a.shadowInner, blur = _a.blur;
    var styles = {};
    styles["box"] = {
        borderRadius: 0,
        outline: 'none',
        border: 'none',
    };
    styles["img"] = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
    // Fills
    for (var key in fill) {
        styles["fill-" + key] = { backgroundColor: fill[key] };
    }
    for (var key in fillFancy) {
        styles["fill-fancy-" + key] = { backgroundImage: fillFancy[key] };
    }
    for (var key in fillInverse) {
        styles["fill-inverse-" + key] = { backgroundColor: fillInverse[key] };
    }
    // Radius
    for (var key in radius) {
        styles["radius-" + key] = { borderRadius: radius[key] };
    }
    for (var key in radius) {
        styles["radius-top-" + key] = {
            borderTopLeftRadius: radius[key],
            borderTopRightRadius: radius[key],
        };
        styles["radius-bottom-" + key] = {
            borderBottomLeftRadius: radius[key],
            borderBottomRightRadius: radius[key],
        };
        styles["radius-right-" + key] = {
            borderTopRightRadius: radius[key],
            borderBottomRightRadius: radius[key],
        };
        styles["radius-left-" + key] = {
            borderTopLeftRadius: radius[key],
            borderBottomLeftRadius: radius[key],
        };
    }
    for (var key in radius) {
        styles["radius-tl-" + key] = {
            borderTopLeftRadius: radius[key],
        };
        styles["radius-tr-" + key] = {
            borderTopRightRadius: radius[key],
        };
        styles["radius-br-" + key] = {
            borderBottomRightRadius: radius[key],
        };
        styles["radius-bl-" + key] = {
            borderBottomLeftRadius: radius[key],
        };
    }
    // Shadows
    for (var key in shadow) {
        styles["shadow-" + key] = { boxShadow: shadow[key] };
    }
    // Inner Shadows
    for (var key in shadowInner) {
        styles["shadow-inner-" + key] = { boxShadow: "inset " + shadowInner[key] };
    }
    // Glows
    for (var key in glow) {
        styles["glow-" + key] = { boxShadow: glow[key] };
    }
    // Blurs
    for (var key in blur) {
        styles["blur-" + key] = { backdropFilter: "blur(" + blur[key] + ")" };
    }
    return createStyleSheet(BOX_NAME, styles);
}
