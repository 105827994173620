var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Font, Icon, Gap } from 'themeor';
import { MakeButton } from '../make-button';
import { icons } from '../../theme/iconList';
export var ActionButton = function (_a) {
    var label = _a.label, icon = _a.icon, disabled = _a.disabled, critic = _a.critic, children = _a.children, fill = _a.fill, _b = _a.size, size = _b === void 0 ? "md" : _b, _c = _a.weight, weight = _c === void 0 ? "500" : _c, col = _a.col, rest = __rest(_a, ["label", "icon", "disabled", "critic", "children", "fill", "size", "weight", "col"]);
    fill = fill || (critic ? 'critic' : 'baseDown');
    return (React.createElement(MakeButton, __assign({ radius: label ? undefined : "max", inline: true }, rest),
        React.createElement(Align, { row: !col, vert: "center", hor: "center" },
            icon && React.createElement(Icon, { fill: fill, name: icon }),
            icon && label && React.createElement(Gap, { size: "xs" }),
            label && React.createElement(Font, { size: size, weight: weight, nowrap: true, fill: fill },
                label,
                children))));
};
ActionButton.displayName = 'ActionButton';
ActionButton.description = 'Кнопка для совершения действия';
ActionButton.demoProps = {
    Props: {
        _extends: ['MakeButton'],
        label: ['string', 'Click me'],
        icon: ['select', 'cross', icons],
        fill: ['string', undefined],
        col: ['boolean', false],
        critic: ['boolean', false],
    }
};
ActionButton.usage = "<ActionButton {Props}/>";
