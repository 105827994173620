var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from 'react';
import { ScreenFitContext } from './context';
import { SCREEN_FIT } from './constants';
export function ScreenFit(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var _b = useState(), currentScreen = _b[0], setCurrentScreen = _b[1];
    var node = useRef();
    useEffect(function () {
        resize();
        window.addEventListener('resize', resize);
        return function () { return window.removeEventListener('resize', resize); };
    }, []);
    function resize() {
        var _a;
        var result;
        var width = (_a = node === null || node === void 0 ? void 0 : node.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
        for (var _i = 0, SCREEN_FIT_1 = SCREEN_FIT; _i < SCREEN_FIT_1.length; _i++) {
            var screen_1 = SCREEN_FIT_1[_i];
            if (width >= screen_1.start) {
                result = screen_1;
            }
            if (width > window.innerWidth) {
                setCurrentScreen(__assign({}, screen_1));
            }
        }
        result.currentWidth = width;
        document.getElementsByTagName('html')[0].style.fontSize = result.oneRem;
        setCurrentScreen(__assign({}, result));
    }
    return (React.createElement(ScreenFitContext.Provider, { value: __assign({}, currentScreen) },
        React.createElement("div", __assign({ ref: function (n) { return node.current = n; }, style: { maxWidth: '100%' } }, rest), children)));
}
