var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from './tooltip';
export var withTooltip = function (Component, props) {
    return function (_a) {
        var tooltip = _a.tooltip, tooltipDelay = _a.tooltipDelay, tooltipPlace = _a.tooltipPlace, rest = __rest(_a, ["tooltip", "tooltipDelay", "tooltipPlace"]);
        return (React.createElement(React.Fragment, null,
            React.createElement(Component, __assign({}, rest)),
            tooltip && (React.createElement(Tooltip, __assign({ delay: tooltipDelay, place: tooltipPlace }, props), tooltip))));
    };
};
withTooltip.displayName = 'withTooltip()';
withTooltip.demoProps = {
    Props: {
        tooltip: ['string', "I'm a tooltip"],
        tooltipDelay: ['number', 200],
        tooltipPlace: ['select', undefined, ['top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right', 'right', 'right-top', 'right-bottom', 'left', 'left-top', 'left-bottom']],
    }
};
withTooltip.usage = "\n{/* Wrap your component first\nButton = withTooltip(Button)\n\nSome components from the library are already wrapped*/}\n\n<Button{Props}\n  // Props for styling\n  primary\n  width=\"250px\"\n  height=\"200px\"\n  label=\"Hover me, don't be shy\"\n/>";
