export function fitNode(targetNode, parentNode) {
    var parentRect = parentNode === null || parentNode === void 0 ? void 0 : parentNode.getBoundingClientRect();
    var targetRect = targetNode === null || targetNode === void 0 ? void 0 : targetNode.getBoundingClientRect();
    if (!targetRect || !parentRect) {
        return;
    }
    var freeSpace = {
        top: parentRect.top,
        left: parentRect.left,
        right: window.innerWidth - parentRect.left - parentRect.width,
        bottom: window.innerHeight - parentRect.top - parentRect.height,
    };
    var canBePlaced = {
        top: freeSpace.top > targetRect.height,
        left: freeSpace.left > targetRect.width,
        right: freeSpace.right > targetRect.width,
        bottom: freeSpace.bottom > targetRect.height,
    };
    return [canBePlaced, freeSpace, targetRect, parentRect];
}
