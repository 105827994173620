var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mediators } from '../collector';
import { combineReducers } from 'redux';
import { externalReducers } from './settings';
export function getReducer() {
    var reducers = __assign({}, externalReducers);
    Object.keys(mediators).forEach(function (key) {
        var _a;
        reducers[key] = (_a = mediators[key]) === null || _a === void 0 ? void 0 : _a.reducer;
    });
    return combineReducers(reducers);
}
