var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { allFills, baseFills } from './opium-fill';
export function normalizeConfig(config) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
    var newConfig = __assign({}, config);
    newConfig.fill = __assign({ none: 'transparent' }, makeFlat(config.fill));
    newConfig.fillFancy = makeFlat(__assign({}, config.fillFancy));
    newConfig.fillInverse = makeFlat(__assign({}, config.fillInverse));
    newConfig.box = {
        radius: __assign({ default: '0', none: '0', max: '1000px' }, makeFlat((_a = config.box) === null || _a === void 0 ? void 0 : _a.radius)),
        shadow: __assign({ default: 'none', none: 'none' }, makeFlat((_b = config.box) === null || _b === void 0 ? void 0 : _b.shadow)),
        shadowInner: __assign({ default: 'none', none: 'none' }, makeFlat((_c = config.box) === null || _c === void 0 ? void 0 : _c.shadowInner)),
        glow: __assign({ default: 'none', none: 'none' }, makeFlat((_d = config.box) === null || _d === void 0 ? void 0 : _d.glow)),
        blur: __assign({ default: 'none', none: 'none' }, makeFlat((_e = config.box) === null || _e === void 0 ? void 0 : _e.blur)),
        fill: __assign(__assign({ default: 'transparent' }, newConfig.fill), makeFlat((_f = config.box) === null || _f === void 0 ? void 0 : _f.fill)),
        fillInverse: __assign(__assign({ default: 'transparent' }, newConfig.fillInverse), makeFlat((_g = config.box) === null || _g === void 0 ? void 0 : _g.fillInverse)),
        fillFancy: __assign(__assign({ default: 'transparent' }, newConfig.fillFancy), makeFlat((_h = config.box) === null || _h === void 0 ? void 0 : _h.fillFancy)),
    };
    newConfig.font = {
        size: __assign({}, makeFlat((_j = config.font) === null || _j === void 0 ? void 0 : _j.size)),
        weight: __assign({ '100': '100', '200': '200', '300': '300', '400': '400', '500': '500', '600': '600', '700': '700', '800': '800', '900': '900' }, makeFlat((_k = config.font) === null || _k === void 0 ? void 0 : _k.weight)),
        align: {
            center: 'center',
            left: 'left',
            right: 'right',
        },
        family: __assign({}, makeFlat((_l = config.font) === null || _l === void 0 ? void 0 : _l.family)),
        fill: __assign(__assign({ default: 'inherit' }, newConfig.fill), makeFlat((_m = config.font) === null || _m === void 0 ? void 0 : _m.fill)),
        fillInverse: __assign(__assign({ default: 'inherit' }, newConfig.fillInverse), makeFlat((_o = config.font) === null || _o === void 0 ? void 0 : _o.fillInverse)),
        fillFancy: __assign(__assign({ default: 'inherit' }, newConfig.fillFancy), makeFlat((_p = config.font) === null || _p === void 0 ? void 0 : _p.fillFancy)),
        lineHeight: __assign({}, makeFlat((_q = config.font) === null || _q === void 0 ? void 0 : _q['lineHeight'])),
        letterSpacing: __assign({}, makeFlat((_r = config.font) === null || _r === void 0 ? void 0 : _r['letterSpacing'])),
    };
    newConfig.line = {
        fill: __assign(__assign({ default: '#000' }, newConfig.fill), makeFlat((_s = config.line) === null || _s === void 0 ? void 0 : _s.fill)),
        fillInverse: __assign(__assign({ default: '#fff' }, newConfig.fillInverse), makeFlat((_t = config.line) === null || _t === void 0 ? void 0 : _t.fillInverse)),
        fillFancy: __assign(__assign({ default: '#000' }, newConfig.fillFancy), makeFlat((_u = config.line) === null || _u === void 0 ? void 0 : _u.fillFancy)),
        weight: __assign({ default: '1px', none: '0' }, makeFlat((_v = config.line) === null || _v === void 0 ? void 0 : _v.weight)),
    };
    newConfig.gap = {
        size: __assign({ default: '16px', none: '0' }, makeFlat(((_w = config.gap) === null || _w === void 0 ? void 0 : _w.size) || config.gap)),
    };
    newConfig.icon = {
        fill: __assign(__assign({ default: '#000' }, newConfig.fill), makeFlat((_x = config.icon) === null || _x === void 0 ? void 0 : _x.fill)),
        fillInverse: __assign(__assign({ default: '#fff' }, newConfig.fillInverse), makeFlat((_y = config.icon) === null || _y === void 0 ? void 0 : _y.fillInverse)),
        fillFancy: __assign(__assign({ default: '#000' }, newConfig.fillFancy), makeFlat((_z = config.icon) === null || _z === void 0 ? void 0 : _z.fillFancy)),
        size: __assign({ default: '24px' }, makeFlat((_0 = config.icon) === null || _0 === void 0 ? void 0 : _0.size)),
    };
    newConfig.reaction = {
        duration: __assign({ default: '250ms', none: '0ms' }, makeFlat((_1 = config.reaction) === null || _1 === void 0 ? void 0 : _1.duration)),
        cursor: {
            pointer: 'pointer',
            text: 'text',
            help: 'help',
            wait: 'wait',
            crosshair: 'crosshair',
            'not-allowed': 'not-allowed',
            'zoom-in': 'zoom-in',
            'zoom-out': 'zoom-out',
            grab: 'grab',
            default: 'default',
            none: 'none',
            auto: 'auto',
            cell: 'cell',
            alias: 'alias',
            copy: 'copy',
            move: 'move',
            grabbing: 'grabbing',
            'col-resize': 'col-resize',
            'row-resize': 'row-resize',
        },
        timingFunction: {
            default: 'ease',
            ease: 'ease',
            linear: 'linear',
        },
        property: __assign({ default: 'color, background, fill, font-size, font-weight, width, height, top, left, right, bottom, opacity' }, makeFlat((_2 = config.reaction) === null || _2 === void 0 ? void 0 : _2.property)),
    };
    newConfig.fit = {
        zIndex: __assign({}, makeFlat((_3 = config.fit) === null || _3 === void 0 ? void 0 : _3.zIndex)),
        offset: __assign(__assign({ none: '0' }, newConfig.gap.size), makeFlat((_4 = config.fit) === null || _4 === void 0 ? void 0 : _4.offset)),
    };
    // If use opium fill, then correct values to old config
    var useOpiumFill = (_6 = (_5 = config.fill) === null || _5 === void 0 ? void 0 : _5.base) === null || _6 === void 0 ? void 0 : _6.strong;
    if (useOpiumFill) {
        for (var _i = 0, baseFills_1 = baseFills; _i < baseFills_1.length; _i++) {
            var fill = baseFills_1[_i];
            if ((_7 = newConfig.shallInverseOn) === null || _7 === void 0 ? void 0 : _7.includes(fill)) {
                newConfig.shallInverseOn.push(fill + "-up");
                newConfig.shallInverseOn.push(fill + "-down");
            }
        }
        mutateToOpiumFill(newConfig);
    }
    return newConfig;
}
function makeFlat(value, prefix, original) {
    if (typeof value !== 'object' && original && prefix) {
        original["" + prefix] = value;
        return;
    }
    if (!prefix) {
        var flatJson = {};
        for (var key in value) {
            makeFlat(value[key], key, flatJson);
        }
        return flatJson;
    }
    for (var key in value) {
        makeFlat(value[key], prefix + "-" + key, original);
    }
}
function mutateToOpiumFill(newConfig) {
    var boxFill = {};
    var fontFill = {};
    var lineFill = {};
    var iconFill = {};
    var boxFillInv = {};
    var fontFillInv = {};
    var lineFillInv = {};
    var iconFillInv = {};
    var boxFillFancy = {};
    var fontFillFancy = {};
    var lineFillFancy = {};
    var iconFillFancy = {};
    var boxFillStrogn = {};
    for (var _i = 0, allFills_1 = allFills; _i < allFills_1.length; _i++) {
        var opiumFill = allFills_1[_i];
        var splitFill = opiumFill.split('-');
        boxFill[opiumFill] = newConfig.fill[splitFill[0] + "-weak" + (splitFill[1] ? "-" + splitFill[1] : '')];
        fontFill[opiumFill] = newConfig.font.fill[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        lineFill[opiumFill] = newConfig.line.fill[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        iconFill[opiumFill] = newConfig.icon.fill[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        boxFillInv[opiumFill] = newConfig.box.fill[splitFill[0] + "-weak" + (splitFill[1] ? "-" + splitFill[1] : '')];
        fontFillInv[opiumFill] = newConfig.font.fill[splitFill[0] + "-weak" + (splitFill[1] ? "-" + splitFill[1] : '')];
        lineFillInv[opiumFill] = newConfig.line.fill[splitFill[0] + "-weak" + (splitFill[1] ? "-" + splitFill[1] : '')];
        iconFillInv[opiumFill] = newConfig.icon.fill[splitFill[0] + "-weak" + (splitFill[1] ? "-" + splitFill[1] : '')];
        boxFillFancy[opiumFill] = newConfig.box.fillFancy[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        fontFillFancy[opiumFill] = newConfig.font.fillFancy[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        lineFillFancy[opiumFill] = newConfig.line.fillFancy[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        iconFillFancy[opiumFill] = newConfig.icon.fillFancy[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
        boxFillStrogn[opiumFill] = newConfig.box.fill[splitFill[0] + "-strong" + (splitFill[1] ? "-" + splitFill[1] : '')];
    }
    boxFill.none = fontFill.none = lineFill.none = iconFill.none = 'transparent';
    boxFill.default = boxFill.none;
    fontFill.default = "inherit";
    lineFill.default = lineFill["base"];
    iconFill.default = iconFill["base"];
    boxFillFancy.default = boxFillFancy.none;
    fontFillFancy.default = "inherit";
    lineFillFancy.default = lineFillFancy["base"];
    iconFillFancy.default = iconFillFancy["base"];
    boxFillInv.default = boxFillInv.none;
    fontFillInv.default = fontFillInv["base"];
    lineFillInv.default = lineFillInv["base"];
    iconFillInv.default = iconFillInv["base"];
    boxFillStrogn.default = boxFillStrogn.none;
    var customFills = newConfig.customFills;
    newConfig.box.fill = __assign(__assign({}, customFills), boxFill);
    newConfig.font.fill = __assign(__assign({}, customFills), fontFill);
    newConfig.line.fill = __assign(__assign({}, customFills), lineFill);
    newConfig.icon.fill = __assign(__assign({}, customFills), iconFill);
    newConfig.box.fillInverse = boxFillInv;
    newConfig.font.fillInverse = fontFillInv;
    newConfig.line.fillInverse = lineFillInv;
    newConfig.icon.fillInverse = iconFillInv;
    newConfig.box.fillFancy = boxFillFancy;
    newConfig.font.fillFancy = fontFillFancy;
    newConfig.line.fillFancy = lineFillFancy;
    newConfig.icon.fillFancy = iconFillFancy;
    newConfig.box.fillStrong = boxFillStrogn;
}
