import { getPathFromUrl } from './utils';
export var config = {
    isBrowser: !!((window === null || window === void 0 ? void 0 : window.location) && (window === null || window === void 0 ? void 0 : window.history)),
    defaultPath: '/',
    stackSeparator: '/',
    localStorage: window === null || window === void 0 ? void 0 : window.localStorage,
    onSwitch: function (path, history, backHistory) { return undefined; },
};
if (config.isBrowser) {
    config.defaultPath = getPathFromUrl();
}
