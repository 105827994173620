var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from '../utils/class-names';
import { ALIGN_NAME } from './types';
import { withTagless } from '../with-tagless';
import { getConfig } from '../utils/get-config';
import { useTheme } from '../context';
import { Common } from '../Common';
import { useCss } from './styles';
var Align = function (_a, ref) {
    var row = _a.row, _b = _a.vert, vert = _b === void 0 ? "top" : _b, _c = _a.hor, hor = _c === void 0 ? "stretch" : _c, className = _a.className, pattern = _a.pattern, gapVert = _a.gapVert, gapHor = _a.gapHor, _d = _a.style, style = _d === void 0 ? {} : _d, stack = _a.stack, children = _a.children, reverse = _a.reverse, dense = _a.dense, restProps = __rest(_a, ["row", "vert", "hor", "className", "pattern", "gapVert", "gapHor", "style", "stack", "children", "reverse", "dense"]);
    var gapConfig = getConfig(useTheme().normalizedConfig).gapConfig;
    var css = useCss();
    var newStyle = __assign({}, style);
    if (!!pattern) {
        newStyle.gridTemplateColumns = pattern;
    }
    // if (gapVert && !gapConfig({ size: gapVert }) && !pattern) {
    //   newStyle.marginTop = minus(half(gapVert))
    //   newStyle.marginBottom = minus(half(gapVert))
    // }
    // if (gapHor && !gapConfig({ size: gapHor }) && !pattern) {
    //   newStyle.marginRight = minus(half(gapHor))
    //   newStyle.marginLeft = minus(half(gapHor))
    // }
    if (gapVert && !gapConfig({ size: gapVert })) {
        newStyle.rowGap = gapVert || undefined;
    }
    if (gapHor && !gapConfig({ size: gapHor })) {
        newStyle.columnGap = gapHor || undefined;
    }
    var mode = (!!pattern && 'pattern') || ((row || stack) && 'row') || 'col';
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { className: cn(css["align"], css[mode], reverse && css["reverse"], dense && css["dense"], stack && css["stack"], vert && css[mode + "-vert-" + vert], hor && css[mode + "-hor-" + hor], 
        // !pattern && gapConfig({ size: gapVert }) && css[`vert-gap-${gapVert}`],
        // !pattern && gapConfig({ size: gapHor }) && css[`hor-gap-${gapHor}`],
        gapConfig({ size: gapVert }) && css["pattern-vert-gap-" + gapVert], gapConfig({ size: gapHor }) && css["pattern-hor-gap-" + gapHor], className), style: newStyle, children: children });
    // const hasGap = !pattern && (gapVert || gapHor)
    // function wrapChildren(children: any): React.ReactNode {
    //   const wrapChildClass = cn(
    //     gapConfig({ size: gapVert }) && css[`item-vert-gap-${gapVert}`],
    //     gapConfig({ size: gapHor }) && css[`item-hor-gap-${gapHor}`],
    //   )
    //   const wrapChildStyle = {
    //     paddingRight: (!gapConfig({ size: gapHor }) && half(gapHor)) || undefined,
    //     paddingLeft: (!gapConfig({ size: gapHor }) && half(gapHor)) || undefined,
    //     paddingTop: (!gapConfig({ size: gapVert }) && half(gapVert)) || undefined,
    //     paddingBottom: (!gapConfig({ size: gapVert }) && half(gapVert)) || undefined,
    //     boxSizing: 'border-box' as any,
    //   }
    //   return React.Children.map(children, (child: any) => {
    //     return Common({
    //       className: wrapChildClass,
    //       style: wrapChildStyle,
    //       children: child,
    //     }, 'align-item')
    //   })
    // }
    // if (hasGap) {
    //   componentProps.children = wrapChildren(children)
    // }
    return Common(componentProps, ALIGN_NAME);
};
Align.displayName = ALIGN_NAME;
export default withTagless(React.forwardRef(Align));
