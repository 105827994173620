import { Font, Gap } from 'themeor'


export default function () {
  return (
    <Gap top="40px" bottom="40px">
      <Font fill="faint-down" align="center">
        made by&nbsp;
        <Font.TryTagless underline>
          <a href="http://cloudlab.ru">CloudLab</a>
        </Font.TryTagless>
        &nbsp;&&nbsp;
        <Font.TryTagless underline>
          <a href="http://opium.pro">opium.pro</a>
        </Font.TryTagless>
      </Font>
    </Gap>
  )
}