export const storage = 'app'

export const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
  loggedIn: false,
  errors: undefined as Array<{title: string, text: string, more: string}>,
}

export const cache: {[key in keyof typeof initialState]?: number} = {
  accessToken: 0,
  refreshToken: 0,
  loggedIn: 0,
}