import React from 'react';
import { Font, Box } from 'themeor';
export var MarkMatch = function (_a) {
    var children = _a.children, target = _a.target, Wrapper = _a.Wrapper;
    if (typeof children !== 'string') {
        return null;
    }
    if (!children && !target) {
        return null;
    }
    if (!target) {
        target = '';
    }
    if (!Wrapper) {
        Wrapper = function (_a) {
            var children = _a.children;
            return (React.createElement(Box.TryTagless, { fill: "warning" },
                React.createElement(Font, { inline: true, fill: "base" }, children)));
        };
    }
    if (!Array.isArray(target)) {
        target = [target];
    }
    return replace(children, target, Wrapper);
};
function replace(children, target, Wrapper) {
    var indexMap = new Map;
    for (var _i = 0, target_1 = target; _i < target_1.length; _i++) {
        var text_1 = target_1[_i];
        var index_1 = children.toLowerCase().indexOf(text_1.toLowerCase());
        if (index_1 >= 0) {
            indexMap.set(index_1, text_1);
        }
    }
    var index = Math.min.apply(Math, Array.from(indexMap.keys()));
    var text = indexMap.get(index);
    var firstPart = children.slice(0, index);
    var matchPart = children.slice(index, (text === null || text === void 0 ? void 0 : text.length) + index);
    var lastPart = children.slice((text === null || text === void 0 ? void 0 : text.length) + index);
    if (!indexMap.size) {
        firstPart = children;
        matchPart = undefined;
        lastPart = undefined;
    }
    else if (lastPart.length) {
        lastPart = replace(lastPart, target, Wrapper);
    }
    return (React.createElement(React.Fragment, null,
        firstPart,
        matchPart && React.createElement(Wrapper, null, matchPart),
        lastPart));
}
