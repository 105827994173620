var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Font, Gap } from 'themeor';
import { MakeButton } from '../make-button';
import { withTooltip } from '../tooltip';
export var Item = withTooltip(function (_a) {
    var label = _a.label, nowrap = _a.nowrap, onClick = _a.onClick, value = _a.value, forwardRef = _a.forwardRef, hint = _a.hint, children = _a.children, rest = __rest(_a, ["label", "nowrap", "onClick", "value", "forwardRef", "hint", "children"]);
    return (React.createElement(MakeButton, { forwardRef: forwardRef, offset: "0", radius: "none", disabled: !onClick },
        React.createElement(Gap.TryTagless, { vert: "md", hor: "xl" },
            React.createElement(Align, __assign({}, rest),
                React.createElement(Font, { size: "x2s", transition: true, noselect: true, weight: '500', fill: "base", nowrap: nowrap }, label),
                React.createElement(Font, { size: "xl", transition: true, noselect: true, weight: '700', fill: "base", nowrap: nowrap }, value),
                hint && (React.createElement(React.Fragment, null,
                    React.createElement(Font, { size: "x3s", noselect: true, fill: "faintDown" }, hint))),
                children))));
}, { place: 'top' });
