var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setCachedIfAllowed, clearCache } from '../local-storage/cached';
export function makeDefaultActions(name, initialState, cache) {
    var actions = {};
    actions.reset = function (state, _a) {
        var _b;
        var payload = _a.payload;
        var newState;
        if (typeof payload === 'string') {
            newState = __assign(__assign({}, state), (_b = {}, _b[payload] = initialState[payload], _b));
            clearCache(name, payload);
        }
        else if (payload instanceof Object && !Array.isArray(payload)) {
            newState = payload;
            clearCache(name).then(function () {
                setCachedIfAllowed(name, newState);
            });
        }
        else {
            newState = initialState;
            clearCache(name);
        }
        return newState;
    };
    actions.patch = function (state, _a) {
        var payload = _a.payload;
        var values = Array.isArray(payload) ? payload[0] : payload;
        var refreshCache = Array.isArray(payload) ? payload[1] : true;
        var newState = __assign(__assign({}, state), values);
        refreshCache && setCachedIfAllowed(name, newState);
        return newState;
    };
    return actions;
}
