

export const connections = {
  api: {
    host: 'https://selfpr.dev.wonderu.com/api/v1',
    // host: process.env.NODE_ENV !== 'production' ? '/v1' : 'https://selfpr.dev.wonderu.com/api/v1',
  },
  facebook: {
    src: 'https://connect.facebook.net/en_US/sdk.js',
    tagId: 'facebook-jssdk',
    init: {
      appId: '453320419617846',
      cookie: true,
      xfbml: true,
      version: 'v13.0'
    }
  }
}