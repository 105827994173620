import { useStorage } from 'master-hook'
import axios, { Method, AxiosResponse, AxiosRequestHeaders } from 'axios'
import { setError } from '../store/app/actions'
import { connections } from '../config'
import { refresh } from './auth'
const { host } = connections.api


export type Responce<Body = any> = Promise<Body>
export type { AxiosRequestHeaders as Headers }


export async function fetch(url: string, method: Method = 'GET', data?: any) {
  const { accessToken, refreshToken, setAccessToken, setRefreshToken, loggedIn, logOut } = useStorage('app')
  const { accessToken: FbAccessToken } = useStorage('facebook')

  const headers = (FbAccessToken && !loggedIn) ? {
    'Authorization': `Bearer ${FbAccessToken}`,
  } : {
    'Authorization': `JWT ${accessToken}`,
  }
  const request = {
    url: host + url,
    headers,
    method,
    data,
  }

  try {
    const response = await axios.request(request)
    return response.data
  } catch (e) {
    const data = e.response?.data || {}

    if (data.reason === 'token') {
      const newAccessToken = (await refresh({refresh: refreshToken})).access
      const newRefreshToken = (await refresh({refresh: refreshToken})).refresh
      newAccessToken && await setAccessToken(newAccessToken)
      newRefreshToken && await setRefreshToken(newRefreshToken)
      return fetch(url, method, data)
    }

    setError({
      title: "Technical error",
      text: `${JSON.stringify(e.message)}\n${JSON.stringify(data)}`,
      more: JSON.stringify(e),
    })
    throw 'Stopped by fetch()'
  }
}