var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Align, Fit, Box, Font, Icon } from "themeor";
import { withTooltip } from '../tooltip';
export var SIZE = {
    xs: { circle: "30px", font: "x3s" },
    sm: { circle: "40px", font: "x2s" },
    md: { circle: "80px", font: "lg" },
    lg: { circle: "120px", font: "x2l" },
    xl: { circle: "160px", font: "x3l" },
};
var fills = ['accent', 'base', 'faint', 'complement', 'critic', 'warning', 'success'];
export var Avatar = withTooltip(function (_a) {
    var _b;
    var name = _a.name, _c = _a.size, size = _c === void 0 ? "md" : _c, img = _a.img, fill = _a.fill, icon = _a.icon, forwardRef = _a.forwardRef, rest = __rest(_a, ["name", "size", "img", "fill", "icon", "forwardRef"]);
    var abbr = (_b = name === null || name === void 0 ? void 0 : name.slice(0, 2)) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    var index = 0;
    for (var _i = 0, _d = name || ''; _i < _d.length; _i++) {
        var char = _d[_i];
        index += char.charCodeAt(0);
    }
    index = index % fills.length || 0;
    if (img && !img.includes('://')) {
        img = 'https://' + img;
    }
    if (img || icon) {
        abbr = undefined;
    }
    return (React.createElement(Fit.TryTagless, { width: SIZE[size].circle, height: SIZE[size].circle },
        React.createElement(Align.TryTagless, { vert: "center", hor: "center" },
            React.createElement(Box.TryTagless, __assign({ img: img, fill: fill || fills[index], radius: "max" }, rest, { forwardRef: forwardRef }),
                icon && React.createElement(Icon, { name: icon }),
                abbr && React.createElement(Font, { fill: "base", weight: "600", size: SIZE[size].font }, abbr)))));
});
