var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Fit, Box, Font, Gap, Reaction } from 'themeor';
import { withForm } from '../form';
import { withTooltip } from '../tooltip';
import { call } from '../../utils';
export var Toggle = withForm(withTooltip(function (_a) {
    var name = _a.name, value = _a.value, initialValue = _a.initialValue, radio = _a.radio, label = _a.label, onChange = _a.onChange, hint = _a.hint, disabled = _a.disabled, _b = _a.valueOn, valueOn = _b === void 0 ? initialValue || true : _b, _c = _a.valueOff, valueOff = _c === void 0 ? undefined : _c, props = __rest(_a, ["name", "value", "initialValue", "radio", "label", "onChange", "hint", "disabled", "valueOn", "valueOff"]);
    var checked = value === valueOn;
    function handleChange() {
        if (!radio) {
            value = value === valueOn ? valueOff : valueOn;
        }
        call(onChange)(value);
    }
    return (React.createElement(Reaction, __assign({ track: "hover", disabled: disabled }, props), function (rProps, r) { return (React.createElement(Align, { hor: "left" },
        React.createElement(Align, __assign({ row: true, vert: "center" }, rProps, { onClick: handleChange, tabIndex: 0 }),
            React.createElement(Fit.TryTagless, { height: "20px", width: "34px" },
                React.createElement(Box.TryTagless, { inverse: checked, fill: (disabled && "faint") || (checked && "base") || (r.hoverOrFocus ? "faintUp" : "faint"), radius: "max" },
                    React.createElement(Gap, { size: "2px" },
                        React.createElement(Fit.TryTagless, { width: "16px", height: "16px", left: checked && '14px' },
                            React.createElement(Box, { fill: "base", radius: "max", shadow: "sm" }))))),
            label && (React.createElement(React.Fragment, null,
                React.createElement(Gap, { size: "sm" }),
                React.createElement(Font, { size: "sm", fill: disabled ? "faintDown" : "base", weight: "400", cursor: "default" },
                    label,
                    hint && (React.createElement(React.Fragment, null,
                        React.createElement(Gap, { size: "4px" }),
                        React.createElement(Font, { size: "x2s", fill: "faintDown" }, hint))))))))); }));
}));
