import React from 'react'
import ReactDOM from 'react-dom'
import Navigator from './navigator'
import { Provider } from 'master-hook'
import { Router } from 'opium-nav'
import { connect as connectFb } from './store/facebook/actions'


connectFb()


ReactDOM.render((
  <Provider>
    <Router>
      <Navigator />
    </Router>
  </Provider>
), document.getElementById('root'))
