import { createStyleSheet, getClasses } from '../utils/styles';
import { FONT_NAME } from './types';
export var useCss = function () { return getClasses(FONT_NAME); };
export default function (normalizedConfig) {
    var _a = normalizedConfig.font, fill = _a.fill, fillInverse = _a.fillInverse, fillFancy = _a.fillFancy, size = _a.size, weight = _a.weight, lineHeight = _a.lineHeight, letterSpacing = _a.letterSpacing, family = _a.family, align = _a.align;
    var styles = {};
    styles["font"] = {
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textAlign: 'inherit',
        fontStyle: 'inherit',
        textDecoration: 'inherit',
        letterSpacing: 'inherit',
        whiteSpace: 'inherit',
        userSelect: 'inherit',
    };
    styles["inline"] = { display: 'inline-block' };
    styles["block"] = { display: 'block' };
    styles["underline"] = { textDecoration: 'underline' };
    styles["nounderline"] = { textDecoration: 'none' };
    styles["noselect"] = { userSelect: 'none' };
    styles["nowrap"] = { whiteSpace: 'nowrap' };
    styles["wrap"] = { wordBreak: 'break-word' };
    styles["italic"] = { fontStyle: 'italic' };
    styles["noitalic"] = { fontStyle: 'normal' };
    styles["uppercase"] = { textTransform: 'uppercase' };
    styles["nouppercase"] = { textTransform: 'none' };
    // Fills
    for (var key in fill) {
        styles["fill-" + key] = { color: fill[key] };
    }
    for (var key in fillFancy) {
        styles["fill-fancy-" + key] = {
            backgroundImage: fillFancy[key],
            backgroundClip: 'text',
        };
    }
    for (var key in fillInverse) {
        styles["fill-inverse-" + key] = { color: fillInverse[key] };
    }
    // Size
    for (var key in size) {
        styles["size-" + key] = { fontSize: size[key] };
    }
    // Weight
    for (var key in weight) {
        styles["weight-" + key] = { fontWeight: weight[key] };
    }
    // Line Height
    for (var key in lineHeight) {
        styles["line-height-" + key] = { lineHeight: lineHeight[key] };
    }
    // Letter Spacing
    for (var key in letterSpacing) {
        styles["letter-spacing-" + key] = { letterSpacing: letterSpacing[key] };
    }
    // Align
    for (var key in align) {
        styles["align-" + key] = { textAlign: align[key] };
    }
    // Family
    for (var key in family) {
        styles["family-" + key] = { fontFamily: family[key] };
    }
    return createStyleSheet(FONT_NAME, styles);
}
