import Wrapper from './wrapper';
import { Item } from './item';
import { icons } from '../../theme/iconList';
export var PageMenu = Wrapper;
PageMenu.Item = Item;
PageMenu.Item.displayName = 'PageMenu.Item';
PageMenu.displayName = "PageMenu";
PageMenu.demoProps = {
    'Props': {
        gapHor: ['string', undefined, '32px']
    },
    'Item Props': {
        _extends: ['MakeButton'],
        _indent: '    ',
        label: ['string', 'Demo Label'],
        counter: ['number', 3],
        icon: ['select', undefined, icons],
        active: ['boolean', undefined],
    },
};
PageMenu.usage = "<PageMenu{Props}>\n    <PageMenu.Item{Item Props}/>\n\n    <PageMenu.Item\n      label=\"Active Label\"\n      active\n    />\n\n    <PageMenu.Item\n      label=\"Label with counter\"\n      counter={4}\n    />\n</PageMenu>";
