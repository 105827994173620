import { Align, Font, Gap, Box } from 'themeor'
import { Counter } from 'opium-ui'


export default function ({ title, text = undefined, smile = undefined, img = undefined, counter = undefined }) {
    return (
        <Align>
            {counter && (<>
                <Counter size="lg" attention value={counter} />
                <Gap size="12px" />
            </>)}
            <Box fill="base" radius="20px" shadow="md" stretch>
                <Gap hor="24px" top="16px" bottom="16px">
                    <Align hor="center">
                    {img && (<>
                        <img height="100px" src={img} />
                        <Gap size="8px" />
                    </>)}
                    {smile && (<>
                        <Font align="center" size="100px">{smile}</Font>
                        <Gap size="8px" />
                    </>)}
                    <Font align="center" weight="700" size="lg">{title}</Font>
                    <Gap top="8px" />
                    <Font align="center" fill="faint">{text}</Font>
                    </Align>
                </Gap>
            </Box>
        </Align>
    )
}