import { Align, Font, Gap, Box, Icon, Fit } from 'themeor'
import { LimitWidth, Page, MakeButton, Button, Stats, useScreenFit, ActionButton, Toggle } from 'opium-ui'
import { usePath } from 'opium-nav'
import { networks } from '../config/networks'
import Loader from '../components/loader'
import ListItem from '../components/list-item'
import { useFacebook, useApp } from '../store'
import { useEffect, Fragment, useMemo } from 'react'
import dayjs from 'dayjs'


export default function () {
    const social = networks.facebook
    const { posts, loadPosts, isPending, lastScan } = useFacebook(['posts', 'isPending'])
    const { logOut } = useApp()
    const { isSmall } = useScreenFit()

    useEffect(() => {
        if (!posts?.length) {
            loadPosts()
        }
    }, [])

    const last = lastScan && dayjs(lastScan)

    let topWords: any = new Map()
    for (const post of posts || []) {
        for (const word of post.censored_words) {
            topWords.set(word, (topWords.get(word) || 0) + 1)
        }
    }
    topWords = Array.from(topWords.entries()).sort((a, b) => b[1] - a[1]).slice(0, 3)

    return useMemo(() => (
        <Page title={social.label}>
            <LimitWidth>
                <Gap size="32px" />
                <Align row={!isSmall} vert="center" hor="left">
                    <Font fill="accent" size="48px" weight="800">{social.label}</Font>
                    <Gap size="xl" />
                    <Button
                        primary
                        fillHover={social.hover}
                        icon={!isPending && "refresh"}
                        label={isPending ? "Scanning..." : "Scan again"}
                        onClick={loadPosts}
                        inverse
                        disabled={isPending}
                    />

                    <Gap size="32px" stretch />

                    <Toggle label="Check every new post" tooltip="Coming soon" tooltipDelay={0} disabled />
                    <Gap size="xl" />
                    <Toggle label="Delete automatically" tooltip="Coming soon" tooltipDelay={0} disabled />
                    <Gap size="xl" />

                    <MakeButton onClick={logOut} disabled={isPending}>
                        <Align row gapHor="4px" vert="center">
                            <Icon size="sm" fill="faint-down" name="cross_circled" />
                            <Font size="sm" fill="faint-down">Disconnect</Font>
                        </Align>
                    </MakeButton>
                </Align>

                <Gap size="40px" />

                {last && (
                    <Stats>
                        <Stats.Item nowrap label="Last scan" value={`${last.format('D MMM')}`} hint={`at ${last.format('HH:mm')}`} />
                        <Stats.Item nowrap label="Suspicious posts" value={posts?.length} hint="scanned 300 posts" />
                        {/* <Stats.Item nowrap label="Deleted posts" value="0" /> */}
                        <Gap stretch />
                        {topWords.map(([word, count]) => (
                            <Stats.Item
                                key={word}
                                nowrap
                                label="Wrong word #1"
                                value={word}
                                hint={`used ${count} times`}
                            />
                        ))}
                    </Stats>
                )}
                <Gap size="40px" />
            </LimitWidth>

            <Fit>
                <Box fill="base" shadow="md" minHeight="400px">
                    <LimitWidth>
                        {posts?.length ? posts?.map((post, index) => (
                            <Fragment key={post.id}>
                                <ListItem
                                    note={dayjs(post.created_time).format(`DD MMM YYYY`)}
                                    text={post.message}
                                    isLast={index + 1 === posts.length}
                                    link={post.permalink_url}
                                    match={post.censored_words}
                                />
                                {index + 1 === posts.length && (
                                    <Gap />
                                )}
                            </Fragment>
                        )) : last && (
                            <Align vert="center" hor="center" height="400px">
                                <Font align="center">Nothing strange is forund.<br /> Look who is saint here</Font>
                            </Align>
                        )}
                    </LimitWidth>
                    {isPending && (
                        <Fit.TryTagless absolute width="100%" height="100%" top="0" left="0">
                            <Box fill="base-down" blur="md">
                                <Align height="400px" vert="center" hor="center">
                                    <Loader />
                                </Align>
                            </Box>
                        </Fit.TryTagless>
                    )}
                </Box>
            </Fit>
        </Page>
    ), [posts, isPending])
}