var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Gap, Box, Align, Font, Fit, useReaction } from 'themeor';
import { useTextInput } from './context';
export var Value = function () {
    var _a = useTextInput(), disabled = _a.disabled, type = _a.type, onChange = _a.onChange, value = _a.value, options = _a.options, name = _a.name, autoComplete = _a.autoComplete, onDisplayValue = _a.onDisplayValue, handleRef = _a.handleRef;
    var defaultAutocomplete = autoComplete === true ? 'on' : 'off';
    var reaction = useReaction();
    var fields = {};
    var isDisplayValue = (type !== 'password') && (type === 'select' || !reaction.focus);
    var fieldProps = {
        ref: handleRef,
        type: type,
        onChange: onChange,
        value: value,
        name: name,
        disabled: disabled,
        tabIndex: -1,
        autoComplete: defaultAutocomplete,
    };
    fields.input = (React.createElement("input", __assign({}, fieldProps)));
    fields.textarea = (React.createElement(Fit.TryTagless, { cover: "parent", style: { resize: 'none' } },
        React.createElement("textarea", __assign({}, fieldProps))));
    fields.select = (React.createElement(Fit.TryTagless, { opacity: "0", hidden: true, cursor: reaction.cursor },
        React.createElement("select", __assign({}, fieldProps), React.Children.map(options, function (child) {
            var _a, _b;
            return child && (React.createElement("option", { key: (_a = child.props) === null || _a === void 0 ? void 0 : _a.value, value: (_b = child.props) === null || _b === void 0 ? void 0 : _b.value }));
        }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(StyleWrapper, { opacity: !isDisplayValue ? '1' : '0' }, fields[type] || fields.input),
        isDisplayValue && (React.createElement(StyleWrapper, null, typeof onDisplayValue === 'function' ? onDisplayValue === null || onDisplayValue === void 0 ? void 0 : onDisplayValue(value) : value))));
};
function StyleWrapper(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var _b = useTextInput(), disabled = _b.disabled, valueFont = _b.valueFont, label = _b.label, type = _b.type;
    var isTextarea = type === 'textarea';
    return (React.createElement(Fit.TryTagless, __assign({ absolute: true, left: "0", right: "0", top: label ? '26px' : '11px', offset: "0", maxWidth: "100%" }, rest),
        React.createElement(Box.TryTagless, null,
            React.createElement(Align.TryTagless, { vert: isTextarea ? "top" : "center", row: true },
                React.createElement(Font.TryTagless, __assign({ size: "sm", fill: (disabled && "faint") || "base", weight: "500", align: "left", family: "regular", lineHeight: "md", nowrap: true }, valueFont),
                    React.createElement(Gap.TryTagless, { hor: "md", FORCE_TAGLESS: true }, children))))));
}
