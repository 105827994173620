var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from '../utils/class-names';
import { FIT_NAME } from './types';
import { minus } from '../utils/change-css-value';
import { Common } from '../Common';
import { getConfig } from '../utils/get-config';
import { useTheme } from '../context';
import { withTagless } from '../with-tagless';
import { useCss } from './styles';
var Fit = function (_a, ref) {
    var left = _a.left, top = _a.top, right = _a.right, bottom = _a.bottom, offset = _a.offset, offsetTop = _a.offsetTop, offsetRight = _a.offsetRight, offsetBottom = _a.offsetBottom, offsetLeft = _a.offsetLeft, zIndex = _a.zIndex, stick = _a.stick, isNotParent = _a.isNotParent, isStatic = _a.static, cover = _a.cover, scroll = _a.scroll, _b = _a.style, style = _b === void 0 ? {} : _b, inline = _a.inline, clip = _a.clip, className = _a.className, children = _a.children, sticky = _a.sticky, fixed = _a.fixed, contents = _a.contents, absolute = _a.absolute, restProps = __rest(_a, ["left", "top", "right", "bottom", "offset", "offsetTop", "offsetRight", "offsetBottom", "offsetLeft", "zIndex", "stick", "isNotParent", "static", "cover", "scroll", "style", "inline", "clip", "className", "children", "sticky", "fixed", "contents", "absolute"]);
    var _c = getConfig(useTheme().normalizedConfig), fitConfig = _c.fitConfig, customFitValue = _c.customFitValue;
    var css = useCss();
    var newStyle = __assign({}, style);
    if (customFitValue({ shift: left })) {
        newStyle.left = left || undefined;
    }
    if (customFitValue({ shift: top })) {
        newStyle.top = top || undefined;
    }
    if (customFitValue({ shift: right })) {
        newStyle.right = right || undefined;
    }
    if (customFitValue({ shift: bottom })) {
        newStyle.bottom = bottom || undefined;
    }
    if (customFitValue({ zIndex: zIndex })) {
        newStyle.zIndex = zIndex || undefined;
    }
    if (customFitValue({ offset: offset })) {
        newStyle.margin = minus(offset);
    }
    if (customFitValue({ offset: offsetTop })) {
        newStyle.marginTop = minus(offsetTop);
    }
    if (customFitValue({ offset: offsetBottom })) {
        newStyle.marginBottom = minus(offsetBottom);
    }
    if (customFitValue({ offset: offsetRight })) {
        newStyle.marginRight = minus(offsetRight);
    }
    if (customFitValue({ offset: offsetLeft })) {
        newStyle.marginLeft = minus(offsetLeft);
    }
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { className: cn(css["fit"], clip && css["clip"], scroll && css["scroll"], inline && css["inline"], stick && css["stick-" + stick], cover && css["cover-" + cover], contents && css["contents"], stick && !cover && css["stick-parent"], fitConfig({ zIndex: zIndex }) && css["z-index-" + zIndex], fitConfig({ offset: offset }) && css["offset-" + offset], fitConfig({ offset: offsetTop }) && css["offset-top-" + offsetTop], fitConfig({ offset: offsetBottom }) && css["offset-bottom-" + offsetBottom], fitConfig({ offset: offsetRight }) && css["offset-right-" + offsetRight], fitConfig({ offset: offsetLeft }) && css["offset-left-" + offsetLeft], fitConfig({ shift: top }) && css["shift-top-" + top], fitConfig({ shift: bottom }) && css["shift-bottom-" + bottom], fitConfig({ shift: right }) && css["shift-right-" + right], fitConfig({ shift: left }) && css["shift-left-" + left], (isNotParent || isStatic) && css["static"], sticky && css["sticky"], fixed && css["fixed"], absolute && css["absolute"], inline === false && css["block"], className), style: newStyle, children: children });
    return Common(componentProps, FIT_NAME);
};
Fit.displayName = FIT_NAME;
export default withTagless(React.forwardRef(Fit));
