var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var finalPayloadCreator = function (payloadCreator) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (payloadCreator) {
        return (payloadCreator instanceof Error) ? payloadCreator : payloadCreator.apply(void 0, args);
    }
    if (args.length > 1) {
        return args;
    }
    return args[0];
};
export var actionCreator = function (type, payloadCreator) {
    var actionCreatorHandler = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var payload = finalPayloadCreator.apply(void 0, __spreadArray([payloadCreator], args));
        var action = { type: type };
        if (payload !== undefined) {
            action.payload = payload;
        }
        return action;
    };
    actionCreatorHandler.toString = function () { return type; };
    return actionCreatorHandler;
};
