import { useEffect, useRef } from 'react'
import { Align, Font, Gap, Box, Animate, AnimateCSS } from 'themeor'


function aminate(sequence: AnimateCSS[], trigger, node) {
  const vars = { timeouts: [], interval: undefined }
  function run() {
    for (let i = 0; i < sequence.length; i++) {
      vars.timeouts.push(setTimeout(() => {
        trigger(sequence[i])
        node.innerText = WORDS[i % WORDS.length]
      }, i * 1100))
    }
  }
  run()
  vars.interval = setInterval(run, sequence.length * 1100)
  return vars
}

const WORDS = ['F**k!', 'C**t!', 'D**k!', 'C**p!',]
const ANIMATIONS: AnimateCSS[] = ['rotateIn', 'wobble', 'tada', 'hinge', 'rubberBand', 'jello', 'swing', 'jackInTheBox']


export default function () {
  let trigger
  let node

  useEffect(() => {
    if (typeof trigger !== 'function') { return }
    const vars = aminate(ANIMATIONS, trigger, node)
    return () => {
      clearInterval(vars.interval)
      for (const timeout of vars.timeouts) {
        clearTimeout(timeout)
      }
    }
  }, [])

  return (
    <div>
      <Gap />
      <Animate getTrigger={t => trigger = t} duration={1000}>
        <Font align="center" weight="900" size="x2l" forwardRef={n => node = n} fill="accent">
          {WORDS[0]}
        </Font>
      </Animate>
      <Gap />
      <Font fill="faint-down" align="center">
        Loading ...<br />
        If you have many posts,<br />
        it may take some time. <br />
        Please be patient
      </Font>
    </div>
  )
}