var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Align, Fit, Box, Font, Gap } from 'themeor';
import { MakeButton } from '../make-button';
import { withForm } from '../form';
import { call } from '../../utils';
export var Checkbox = withForm(function (_a) {
    var name = _a.name, value = _a.value, radio = _a.radio, initialValue = _a.initialValue, indeterminate = _a.indeterminate, label = _a.label, onChange = _a.onChange, forwardRef = _a.forwardRef, onClick = _a.onClick, onFocus = _a.onFocus, onBlur = _a.onBlur, hint = _a.hint, disabled = _a.disabled, _b = _a.valueOn, valueOn = _b === void 0 ? initialValue || true : _b, _c = _a.valueOff, valueOff = _c === void 0 ? undefined : _c, props = __rest(_a, ["name", "value", "radio", "initialValue", "indeterminate", "label", "onChange", "forwardRef", "onClick", "onFocus", "onBlur", "hint", "disabled", "valueOn", "valueOff"]);
    var checked = value === valueOn;
    var fieldRef;
    function handleChange(event) {
        var value = event.target.value;
        value = value === 'on' ? valueOn : valueOff;
        if (!radio) {
            value = value === valueOn ? valueOff : valueOn;
        }
        call(onChange)(value);
    }
    function handleClick(e) {
        fieldRef && fieldRef.click();
        call(onClick)(e);
    }
    function handleBlur(e) {
        fieldRef && fieldRef.blur();
        call(onBlur)(e);
    }
    function handleFocus(e) {
        fieldRef && fieldRef.focus();
        call(onFocus)(e);
    }
    return (React.createElement(Fit.TryTagless, { inline: true, forwardRef: forwardRef, tabIndex: 0, onClick: handleClick, onBlur: handleBlur, onFocus: handleFocus },
        React.createElement(Align, { row: true, vert: "center" },
            React.createElement(MakeButton, __assign({ disabled: disabled, radius: "max", offset: "14px", track: ["hover", "active"] }, props),
                React.createElement(Fit.TryTagless, { hidden: true },
                    React.createElement("input", { ref: function (n) { return fieldRef = n; }, value: radio ? initialValue : value ? 'on' : 'off', type: "checkbox", onChange: handleChange, tabIndex: 0, disabled: disabled })),
                React.createElement(Fit.TryTagless, { width: "20px", height: "20px" },
                    React.createElement(Box.TryTagless, { radius: "max", borderFill: disabled ? "faint" : checked ? "base" : "faintUp", fill: "none", inverse: checked },
                        React.createElement(Align, { vert: "center", hor: "center", style: { transition: "all 0.2s ease" } }, checked && (React.createElement(Fit.TryTagless, { width: "8px", height: "8px" },
                            React.createElement(Box, { radius: "max", fill: disabled ? "faintDown" : "base", inverse: true }))))))),
            label && (React.createElement(React.Fragment, null,
                React.createElement(Gap, { size: "sm" }),
                React.createElement(Font, { size: "sm", fill: disabled ? "faintDown" : "base", weight: "400", cursor: "default" },
                    label,
                    hint && (React.createElement(React.Fragment, null,
                        React.createElement(Gap, { size: "4px" }),
                        React.createElement(Font, { size: "x2s", fill: "faintDown" }, hint)))))))));
});
