import { urls } from './urls'

export const networks = {
    facebook: {
        label: 'Facebook',
        icon: 'facebook',
        fill: '#4267B2',
        hover: '#4267E2',
        path: urls.facebook,
    },
    twitter: {
        label: 'Twitter',
        icon: 'twitter',
        fill: '#1DA1F2',
        hover: '#2DB1FF',
        path: urls.twitter,
    },
    instagram: {
        label: 'Instagram',
        icon: 'instagram',
        fill: '#E1306C',
        hover: '#FD1D1D',
        path: urls.instagram,
    },
    linkedin: {
        label: 'LinkedIn',
        icon: 'linkedin',
        fill: '#0077b5',
        hover: '#2087d5',
        path: urls.linkedin,
    },
}

export type Networks = keyof typeof networks