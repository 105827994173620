var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { ANIMATE_NAME } from './types';
import cn from '../utils/class-names';
import css from './animate.module.css';
import cssVar from '../utils/css-variable';
import newId from '../utils/new-id';
import { Common } from '../Common';
import { withTagless } from '../with-tagless';
/*
  This is beta-component. Works only on web. Based on animate.css
*/
var Animate = function (props, ref) {
    var onMount = props.onMount, onUnmount = props.onUnmount, onHover = props.onHover, onClick = props.onClick, _a = props.duration, duration = _a === void 0 ? 300 : _a, _b = props.delay, delay = _b === void 0 ? 0 : _b, _c = props.repeat, repeat = _c === void 0 ? 1 : _c, children = props.children, getTrigger = props.getTrigger, _d = props.mounted, initialMounted = _d === void 0 ? true : _d, restProps = __rest(props, ["onMount", "onUnmount", "onHover", "onClick", "duration", "delay", "repeat", "children", "getTrigger", "mounted"]);
    var _e = useState(), animationName = _e[0], setAnimationName = _e[1];
    var _f = useState(), isRemoving = _f[0], setIsRemoving = _f[1];
    var thisId = useState(newId())[0];
    var _g = useState(initialMounted), mounted = _g[0], setMounted = _g[1];
    var timeTillUnmount = duration * repeat + delay;
    function trigger(name) {
        setAnimationName(name);
    }
    useEffect(function () {
        getTrigger === null || getTrigger === void 0 ? void 0 : getTrigger(trigger);
        cssVar.set({
            id: styleId,
            prefix: 't',
            selector: '#' + thisId,
            json: {
                'animate-duration': duration + 'ms',
                'animate-delay': delay + 'ms',
                'animate-repeat': repeat,
            }
        });
    }, []);
    var styleId = 'style-' + thisId;
    var componentProps = __assign(__assign({ forwardRef: ref }, restProps), { id: thisId, children: children, onMouseEnter: (function () { return onHover && setAnimationName(onHover); }), onClick: (function () { return onClick && setAnimationName(onClick); }), className: cn(css['animated'], css[animationName]) });
    useEffect(function () {
        if (initialMounted) {
            setMounted(true);
        }
        else if (mounted) {
            if (onUnmount) {
                setAnimationName(onUnmount);
                setTimeout(function () {
                    setMounted(false);
                }, timeTillUnmount);
            }
            else {
                setMounted(false);
            }
        }
    }, [initialMounted]);
    // Remove animation after setting
    useEffect(function () {
        if (isRemoving || !animationName) {
            return;
        }
        setIsRemoving(true);
        setTimeout(function () {
            setAnimationName();
            setIsRemoving(false);
        }, timeTillUnmount);
    }, [animationName]);
    useEffect(function () {
        mounted && onMount && setAnimationName(onMount);
    }, [mounted]);
    if (!mounted) {
        return null;
    }
    return Common(componentProps, ANIMATE_NAME);
};
Animate.displayName = ANIMATE_NAME;
export default withTagless(React.forwardRef(Animate));
