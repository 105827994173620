import React from 'react';
import { Dropdown } from '../dropdown';
import { useTextInput } from './context';
import filter from 'opium-filter';
export function AutoComplete() {
    var _a = useTextInput(), autoComplete = _a.autoComplete, onChange = _a.onChange, value = _a.value, isSelect = _a.isSelect;
    if (!autoComplete || isSelect || !Array.isArray(autoComplete)) {
        return null;
    }
    var itemsToAutocomplete = filter(autoComplete, value)
        .filter(function (option) { return value !== option; })
        .slice(0, 100);
    return (React.createElement(React.Fragment, null, itemsToAutocomplete.map(function (option, index) { return (React.createElement(Dropdown.Item, { key: index, onClick: function () { return onChange(option); } }, option)); })));
}
