var identity = function (v) { return v; };
export var createReducer = function (actionsHandlerMap, defaultState) {
    var handlerKeys = Object.keys(actionsHandlerMap);
    var handlers = handlerKeys.reduce(function (acc, key) {
        var _a;
        var action = actionsHandlerMap[key];
        var isFunction = (typeof action === 'function');
        var handler = {
            next: isFunction ? action : (action.next || identity)
        };
        return Object.assign(acc, (_a = {}, _a[key] = handler, _a));
    }, {});
    return function (state, action) {
        if (state === void 0) { state = defaultState; }
        var actionType = action.type;
        var handler = handlers[actionType];
        if (handler) {
            return (handler.next)(state, action);
        }
        return state;
    };
};
